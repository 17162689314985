import React, { useState, useEffect } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Skeleton, Empty, Tooltip, Button, Modal, Space, Typography } from "antd";
// import styles from "./cadastro.module.css";
import { axiosClient } from "../../apiClient";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mascaraCnpj, mascaraCpf } from "../../functions/validation";
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const { Link } = Typography;

export default function SellerListCart() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(null);

  useEffect(() => {
    axiosClient
      .post(
        "/cart/opened",
        { page: page, perPage: perPage },
        { withCredentials: true }
      )
      .then((response) => {
        setTotalPages(response.data.total_pages);
        const values = response.data.cart_data.map((v) => ({
          ...v,
          key: v.id,
        }));
        console.log(response.data.cart_data);
        setData(values);
        setLoading(false);
      });
  }, [page, perPage]);

  const openPDF = (data) => {
    // const a = document.createElement("a");
    // a.setAttribute('href', URL.createObjectURL(new Blob([data], { type: "application/pdf" })));
    // a.setAttribute('target', '_blank');
    // a.click();
    //window.open(URL.createObjectURL(new Blob([response.data], { type: "application/pdf" })))
    setPdfUrl(
      URL.createObjectURL(new Blob([data], { type: "application/pdf" }))
    );
    setVisible(true);
  };

  const downloadPDF = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_self";
    link.download = "pedido.pdf";
    document.body.append(link);
    link.click();
    link.remove();
  };

  const columns = [
    {
      title: "Última Modificação",
      dataIndex: "last_modification",
      ellipsis: true,
      responsive: ["sm"],
    },
    {
      title: "Cliente",
      render: (text, record) => (
        <>
          {record.buyer.tipo_pessoa == "pf" ? (
            <Link>{record.buyer.nome}</Link>
          ) : (
            <Link>{record.buyer.razao_social}</Link>
          )}
        </>
      ),
      ellipsis: true,
      responsive: ["sm"],
    },
    {
      title: "CPF/CNPJ",
      render: (text, record) => (
        <>
          {record.buyer.cnpj
            ? mascaraCnpj(record.buyer.cnpj)
            : mascaraCpf(record.buyer.cpf)}
        </>
      ),
      responsive: ["sm"],
    },
    {
      title: "Total",
      dataIndex: "total",
      render: (text, record) => (
        <>{"R$ " + parseFloat(record.total).toFixed(2)}</>
      ),
      responsive: ["sm"],
    },
    {
      title: "Resumo",
      align: "center",
      width: "10%",
      render: (record) => (
        <Tooltip title="Abrir pdf">
          <Button
            shape="round"
            type="circle"
            onClick={() => {
              console.log(record);
              setLoadingPDF(record.cart_id);
              axiosClient
                .post(
                  "/cart/resumo/pdf",
                  { id: record.cart_id },
                  {
                    withCredentials: true,
                    responseType: "arraybuffer",
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  openPDF(response.data);
                  setLoadingPDF(false);
                });
            }}
          >
            {record.cart_id == loadingPDF ? (
              <LoadingOutlined />
            ) : (
              <FilePdfOutlined />
            )}
          </Button>
        </Tooltip>
      ),
      ellipsis: true,
      responsive: ["sm"],
    },
    {
      title: "Carrinhos em Aberto",
      render: (record) => (
        <>
          {record.buyer.tipo_pessoa == "pf" ? (
            <Link>{record.buyer.nome}</Link>
          ) : (
            <Link>{record.buyer.nome_fantasia}</Link>
          )}
          <br />
          {record.buyer.cnpj
            ? mascaraCnpj(record.buyer.cnpj)
            : mascaraCpf(record.buyer.cpf)}
          <br />
          <>{"R$ " + parseFloat(record.total).toFixed(2)}</>
          <br />
          <>{record.last_modification}</>
          <br />
          <Space direction="horizontal" size="small" align="center">
            <Tooltip title="Abrir pdf">
              <Button
                shape="round"
                type="circle"
                onClick={() => {
                  console.log("record", record)
                  setLoadingPDF(record.cart_id);
                  axiosClient
                    .post(
                      "/cart/resumo/pdf",
                      { id: record.cart_id },
                      {
                        withCredentials: true,
                        responseType: "arraybuffer",
                      }
                    )
                    .then((response) => {
                      openPDF(response.data);
                    })
                    .then(() => setLoadingPDF(false));
                }}
              >
                {record.cart_id == loadingPDF ? (
                  <LoadingOutlined />
                ) : (
                  <FilePdfOutlined />
                )}
              </Button>
            </Tooltip>
          </Space>
        </>
      ),
      responsive: ["xs"],
      ellipsis: true,
    },
  ];

  return (<>
    <PageHeader title="Carrinhos em Aberto" onBack={() => window.history.back()} />
    <Table
      columns={columns}
      dataSource={loading ? [] : data}
      locale={{
        emptyText: loading ? <Skeleton active={true} /> : <Empty />,
      }}
      style={{ cursor: !loading ? "pointer" : "auto" }}
      pagination={{
        current: page,
        total: totalPages * perPage,
        pageSize: perPage,
      }}
      onChange={(val) => {
        setLoading(true);
        window.scrollTo(0, 0);
        setPerPage(val.pageSize);
        setPage(val.current);
      }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            if (
              event.target.tagName != "BUTTON" &&
              event.target.tagName != "path" &&
              event.target.tagName != "svg" &&
              event.target.tagName != "SPAN"
            ) {
              navigate(`/admin/pedidos/em-aberto/${record.cart_id}`)
              // dispatch({ type: "CART_EDIT", cartId: record.cart_id });
              // dispatch({ type: "LIST_CART_DETAILS" });
            }
          },
        };
      }}
    />
    {pdfUrl && (
      <Modal
        centered
        closable={true}
        bodyStyle={{ height: "75vh" }}
        width={"90%"}
        title="Pedido"
        footer={
          <Button onClick={() => downloadPDF()} type="primary">
            Download <DownloadOutlined />
          </Button>
        }
        open={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        cancelText={"Fechar"}
        okButtonProps={{ style: { display: "none" } }}
      >
        <iframe
          style={{ width: "100%", height: "70vh" }}
          src={pdfUrl}
          type="application/pdf"
        />
      </Modal>
    )}
  </>);
}
