import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Space,
  Menu,
  Dropdown,
  Skeleton,
  Col,
  Switch,
  Divider,
  Tabs,
} from "antd";
// import styles from "./cadastro.module.css";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../../apiClient";

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

export default function SellerCreatePayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(false);
  const [cardBrand, setCardbrand] = useState(null)
  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const editItems = useSelector((state) => state.editItems);
  const [loading, setLoading] = useState(true);
  const [applyDiscount, setApplyDiscount] = useState(false);
  const [valueType, setValueType] = useState({});

  useEffect(() => {
    axiosClient
      .post(
        "/payment/available",
        { paymentId: false },
        { withCredentials: true }
      )
      .then((response) => {
        setPaymentOptions(response.data.metodos);
        setLoading(false);
        console.log(response.data.metodos)
      });
  }, []);

  useEffect(() => {
    if (editItems.paymentId) {
      axiosClient
        .post(
          "/payment/get",
          { paymentId: editItems.paymentId },
          { withCredentials: true }
        )
        .then((response) => {
          form.setFieldsValue({
            ...response.data.payments[0],
            applyDiscount: response.data.payments[0].discount_value
              ? true
              : false,
          });
          setSelectedOption(response.data.payments[0].metodo);
          setApplyDiscount(
            response.data.payments[0].discount_value ? true : false
          );
          form.setFieldsValue({ ...response.data.payments[0].aditional_features })
        });
    }
  }, []);

  function deleteMethod() {
    setDeleting(true);
    axiosClient
      .post(
        "/payment/delete",
        { paymentId: editItems.paymentId },
        { withCredentials: true }
      )
      .then((response) => {
        setDeleting(false);
        navigate("/admin/pagamento/lista");
        // dispatch({ type: "LIST_PAYMENT" });
      });
  }

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 24,
    },
  };

  function handleChange(value) {
    setSelectedOption(value);
  }

  const onFinish = (values) => {
    setSending(true);
    let data = editItems.paymentId
      ? { ...values, paymentId: editItems.paymentId }
      : values;
    console.log(data)
    axiosClient
      .post("/payment/create", data, { withCredentials: true })
      .then((response) => navigate("/admin/pagamento/lista"));//dispatch({ type: "LIST_PAYMENT" }));
  };

  function updatePriceField(field, val) {
    form.setFields([
      {
        name: field,
        value: isNaN(val)
          ? "R$ 0,00"
          : val.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
      },
    ]);
  }


  const menu = (
    <Menu onClick={() => deleteMethod()}>
      <Menu.Item key="1">Excluir Método</Menu.Item>
    </Menu>
  );

  return (<>
    <PageHeader
      title={editItems.paymentId ? "Editar Método" : "Novo Método"}
      onBack={() => window.history.back()}
      extra={
        editItems.paymentId && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="primary" loading={deleting}>
              Ações <DownOutlined />
            </Button>
          </Dropdown>
        )
      }
    ></PageHeader>
    {loading ? (
      <Skeleton active />
    ) : (
      <Tabs defaultActiveKey="1" onChange={() => { }}>
        <TabPane tab="Dados Gerais" key="1">
          <Form
            form={form}
            scrollToFirstError={true}
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
          >
            <Form.Item
              name={["metodo"]}
              label="Método"
              rules={[
                {
                  type: "string",
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Select
                // style={{ width: 120 }}
                onChange={handleChange}
              >
                {paymentOptions.map(function (item, i) {
                  return (
                    <Option value={item.codigo} key={item.codigo}>
                      {item.nome}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            {selectedOption === "personalizado" && (
              <>
                <Form.Item
                  name={["descricao"]}
                  label="Descrição"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Space size={10}>
                  <Form.Item name="applyDiscount" noStyle>
                    <Switch
                      checked={applyDiscount}
                      onChange={(value) => {
                        setApplyDiscount(value);
                      }}
                      size="small"
                    />
                  </Form.Item>
                  <span> Aplicar desconto</span>
                </Space>
                <div style={{ marginTop: "20px" }}>
                  {applyDiscount && (
                    <>
                      <Form.Item
                        name="discount_type"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder="Tipo do desconto"
                          onChange={(value) => {
                            form.setFields([
                              {
                                name: "value",
                                value: "",
                              },
                            ]);
                            setValueType(value);
                          }}
                        >
                          <Option value="percent">Porcentagem %</Option>
                          <Option value="absolut">Valor fixo R$</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="discount_value"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        onChange={(e) => {
                          let val = e.target.value.replace(/\D/g, "");
                          if (valueType == "percent") {
                            let val = parseInt(e.target.value.replace(/\D/g, ""));
                            form.setFields([
                              {
                                name: "value",
                                value: isNaN(val) ? 0 : val,
                              },
                            ]);
                          }
                          if (valueType == "absolut") {
                            let val =
                              parseInt(e.target.value.replace(/\D/g, "")) / 100;
                            form.setFields([
                              {
                                name: "value",
                                value: isNaN(val)
                                  ? "R$ 0,00"
                                  : val.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }),
                              },
                            ]);
                          }
                        }}
                      >
                        <Input
                          placeholder="Valor do desconto"
                          suffix={valueType == "percent" && "%"}
                        />
                      </Form.Item>
                    </>
                  )}
                </div>
              </>
            )}

            {(selectedOption === "pagseguro_credit_card") && (
              <>
                <Divider orientation="left">Configurações</Divider>
                <Row gutter={16}>
                  <Col xs={24} sm={24}>
                    <Form.Item
                      name="max_installments"
                      label="Quantidade de parcelas permitidas"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        type='number'
                        onChange={(e) => {
                          if (e.target.value < 1)
                            form.setFieldsValue({
                              max_installments: 1
                            });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Form.Item
                      name="statement_descriptor"
                      label="Descrição na fatura do cartão"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Form.Item
                      name="tax_free_installments"
                      label="Quantidade de parcelas sem juros"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        type='number'
                        onChange={(e) => {
                          if (e.target.value < 0)
                            form.setFieldsValue({
                              tax_free_installments: 0
                            });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Form.Item
                      name="tax_per_installments"
                      label="Juros por parcela"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        type='number'
                        onChange={(e) => {
                          if (e.target.value < 0)
                            form.setFieldsValue({
                              tax_per_installments: 0
                            });
                        }}
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Form.Item
                      name="fix_tax"
                      label="Tarifa fixa (será somada ao valor da compra)"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          let val =
                            parseInt(e.target.value.replace(/\D/g, "")) / 100;
                          updatePriceField("fix_tax", val)
                        }}
                      // prefix={"R$"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            {(selectedOption === "mercadopago_credit_card") &&
              <>
                <Divider orientation="left">Configurações</Divider>
                <Row gutter={16}>
                  <Col xs={24} sm={24}>
                    <Form.Item
                      name="statement_descriptor"
                      label="Descrição na fatura do cartão"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24}>
                    <Form.Item
                      name="max_installments"
                      label="Quantidade de parcelas permitidas"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        type='number'
                        onChange={(e) => {
                          if (e.target.value < 1)
                            form.setFieldsValue({
                              max_installments: 1
                            });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col xs={24} sm={24}>
                    <Form.Item
                      name="tax_per_installments"
                      label="Juros por parcela"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        type='number'
                        onChange={(e) => {
                          if (e.target.value < 0)
                            form.setFieldsValue({
                              tax_per_installments: 0
                            });
                        }}
                        suffix={"%"}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} sm={24}>
                    <Form.Item
                      name="fix_tax"
                      label="Tarifa fixa (será somada ao valor da compra)"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          let val =
                            parseInt(e.target.value.replace(/\D/g, "")) / 100;
                          updatePriceField("fix_tax", val)
                        }}
                      // prefix={"R$"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }

            <Divider orientation="left">Instruções</Divider>
            <Form.Item name="instructions">
              <TextArea rows={4} maxLength={1000} />
            </Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    onClick={() => {
                      navigate("/admin/pagamento/lista")
                      // dispatch({ type: "LIST_PAYMENT" });
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={sending}
                  >
                    {editItems.paymentId ? "Atualizar" : "Cadastrar"}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </TabPane>
      </Tabs>
    )
    }
  </>);
}
