import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Skeleton, Empty, Space, Typography, notification } from "antd";
// import styles from "./cadastro.module.css";
import { useDispatch } from "react-redux";
import { axiosClient } from "../../apiClient";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Link, Title } = Typography;

export default function SellerListShippingRule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);

  var postData = {
    search: "",
    page: "",
    perPage: -1,
  };

  useEffect(() => {
    axiosClient
      .post("/rules/shipping/list", postData, { withCredentials: true })
      .then((out) => {
        setRules(out.data.rules);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: "Nome",
      ellipsis: true,
      responsive: ["sm"],
      render: (record) => <Link> {record.name} </Link>,
    },
    {
      title: "A quem se aplica",
      ellipsis: true,
      responsive: ["sm"],
      render: (record) => (
        <Space direction="vertical">
          {record.all_buyers && "Todos Clientes"}
          {record.buyers_tags.length > 0 && !record.all_buyers && "Por Tags"}
          {record.buyers.length > 0 &&
            !record.all_buyers &&
            "Clientes Específicos"}
          {(record.ceps_data && (record.ceps_data.ceps_full.length > 0 ||
            record.ceps_data.ceps_range.length > 0 ||
            record.ceps_data.ceps_start.length > 0) &&
            !record.all_buyers) &&
            "Por CEP"}
        </Space>
      ),
    },
    {
      title: "Métodos Permitidos",
      responsive: ["sm"],
      render: (record) =>
        record.shippings.length > 0 ? record.shippings.length : "Nenhum",
    },
    {
      title: "Regras",
      render: (record) => (
        <>
          <Link> {record.name} </Link>
          <br />
          <b>Clientes:</b>{" "}
          <Space split="e">
            {record.all_buyers && "Todos Clientes"}
            {record.buyers_tags.length > 0 && !record.all_buyers && "Por Tags"}
            {record.buyers.length > 0 &&
              !record.all_buyers &&
              "Clientes Específicos"}
            {(record.ceps_data.ceps_full.length > 0 ||
              record.ceps_data.ceps_range.length > 0 ||
              record.ceps_data.ceps_start.length > 0) &&
              !record.all_buyers &&
              "Por CEP"}
          </Space>
          <br />
          <b>Métodos Permitidos:</b>{" "}
          {record.shippings.length > 0 ? record.shippings.length : "Nenhum"}
        </>
      ),
      responsive: ["xs"],
      ellipsis: true,
    },
  ];

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title={
          <>
            Regras de Entrega{" "}
            <Link
              onClick={() =>
                notification.open({
                  duration: 20,
                  placement: "bottomRight",
                  message: <Title level={4}>Ajuda</Title>,
                  description:
                    "Com as regras de entrega você pode configurar quais métodos de entrega cada cliente terá disponível durante a compra.",
                })
              }
            >
              <QuestionCircleOutlined />
            </Link>
          </>
        }
        extra={
          <Button
            onClick={() => {
              navigate("/admin/entrega/cadastro/regra");
              // dispatch({ type: "SHIPPING_RULE_EDIT", shippingRuleId: false });
              // dispatch({ type: "CREATE_SHIPPING_RULE" });
            }}
            type="primary"
          >
            Criar Regra de Entrega
          </Button>
        }
      />
      <Table
        style={{ cursor: !loading ? "pointer" : "auto" }}
        columns={columns}
        dataSource={loading ? [] : rules}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/admin/entrega/editar/regra/${record.id}`);
              // dispatch({ type: "CREATE_SHIPPING_RULE" });
              // dispatch({ type: "SHIPPING_RULE_EDIT", shippingRuleId: record.id });
            }, // click row
          };
        }}
      />
    </>
  );
}
