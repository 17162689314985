function initialState() {
  return({shippingMethod: null, paymentMethod: null})
}

export function order(state = initialState(), action) {
  switch (action.type) {
    case 'ADD_SHIPPING':
      return { ...state, shippingMethod: action.shippingMethod}
    case 'ADD_PAYMENT':
      return { ...state, paymentMethod: action.paymentMethod}
    default:
      return state
  }
}