import { BrowserRouter as Router, Route, Routes, useParams, useLocation } from "react-router-dom";
import { useDispatch, } from "react-redux";
import { useEffect } from "react"

export default function AppRoutes() {
    const dispatch = useDispatch();

    const SellerHome = () => {

        useEffect(() => {
            dispatch({ type: "HOME" });
        }, [dispatch]);

        return null;
    }

    const SellerConfiguration = () => {
        dispatch({ type: "SELLER_CONFIGURATION" });
        return (null)
    }

    // Customer:
    const SellerCustomerRoutes = () => {
        let params = useParams();
        const { state } = useLocation();
        if (params.subItem !== undefined) {
            if (params.subItem.includes("lista"))
                dispatch({ type: "LIST_CUSTOMER" });
            else if (params.subItem.includes("solicitacoes"))
                dispatch({ type: "LIST_CUSTOMER_ACCESS" });
            else if (params.subItem.includes("tags"))
                dispatch({ type: "LIST_CUSTOMER_TAG" });
            else if (params.subItem.includes("cadastro")) {
                if (params.subItem2 !== undefined) {
                    if (params.subItem2.includes("solicitacao")) {
                        dispatch({ type: "CUSTOMER_ACCESS_REQUEST_EDIT", customerId: 'request', customerData: state });
                        dispatch({ type: "CREATE_CUSTOMER" });
                    }
                } else {
                    dispatch({ type: "CUSTOMER_EDIT", customerId: false });
                    dispatch({ type: "CREATE_CUSTOMER" });
                }
            } else if (params.subItem.includes("editar")) {
                let user_id = params.subItem2
                dispatch({ type: "CUSTOMER_EDIT", customerId: user_id });
                dispatch({ type: "CREATE_CUSTOMER" });
            } else
                dispatch({ type: "LIST_CUSTOMER" });
        }
        else
            dispatch({ type: "LIST_CUSTOMER" });
        return (null)
    }

    // Product:
    const SellerProductRoutes = () => {
        let params = useParams();
        if (params.subItem !== undefined) {
            if (params.subItem.includes("lista"))
                dispatch({ type: "LIST_PRODUCT" });
            else if (params.subItem.includes("categorias"))
                dispatch({ type: "LIST_CATEGORY" });
            else if (params.subItem.includes("tags"))
                dispatch({ type: "LIST_PRODUCT_TAG" });
            else if (params.subItem.includes("cadastro")) {
                dispatch({ type: "PRODUCT_EDIT", productId: false });
                dispatch({ type: "CREATE_PRODUCT" });
            } else if (params.subItem.includes("editar")) {
                let prod_id = params.subItem2
                dispatch({ type: "PRODUCT_EDIT", productId: prod_id });
                dispatch({ type: "CREATE_PRODUCT" });
            } else
                dispatch({ type: "LIST_PRODUCT" });

        }
        else
            dispatch({ type: "LIST_PRODUCT" });
        return (null)
    }

    // Orders:
    const SellerOrderRoutes = () => {
        let params = useParams();
        if (params.subItem !== undefined) {
            if (params.subItem.includes("finalizados"))
                if (params.subItem2 !== undefined) {
                    let pedido_id = params.subItem2
                    dispatch({ type: "ORDER_EDIT", orderId: pedido_id });
                    dispatch({ type: "LIST_ORDER_DETAILS" });
                } else
                    dispatch({ type: "LIST_ORDER" });
            else if (params.subItem.includes("em-aberto"))
                if (params.subItem2 !== undefined) {
                    let pedido_id = params.subItem2
                    dispatch({ type: "CART_EDIT", cartId: pedido_id });
                    dispatch({ type: "LIST_CART_DETAILS" });
                } else
                    dispatch({ type: "LIST_CART" });
            else
                dispatch({ type: "LIST_ORDER" });
        }
        else
            dispatch({ type: "LIST_ORDER" });
        return (null)
    }

    // Payment:
    const SellerPaymentRoutes = () => {
        let params = useParams();
        if (params.subItem !== undefined) {
            if (params.subItem.includes("lista"))
                dispatch({ type: "LIST_PAYMENT" });
            else if (params.subItem.includes("regras"))
                dispatch({ type: "LIST_PAYMENT_RULE" });

            else if (params.subItem.includes("cadastro")) {
                if (params.subItem2 !== undefined) {
                    if (params.subItem2.includes("metodo")) {
                        dispatch({ type: "PAYMENT_EDIT", paymentId: false });
                        dispatch({ type: "CREATE_PAYMENT" });
                    } else if (params.subItem2.includes("regra")) {
                        dispatch({ type: "PAYMENT_RULE_EDIT", paymentRuleId: false });
                        dispatch({ type: "CREATE_PAYMENT_RULE" });
                    } else
                        dispatch({ type: "LIST_PAYMENT" });
                } else
                    dispatch({ type: "LIST_PAYMENT" });
            } else if (params.subItem.includes("editar")) {
                if (params.subItem2 !== undefined) {
                    if (params.subItem2.includes("metodo")) {
                        let payment_id = params.subItem3
                        dispatch({ type: "PAYMENT_EDIT", paymentId: payment_id });
                        dispatch({ type: "CREATE_PAYMENT" });
                    } else if (params.subItem2.includes("regra")) {
                        let payment_rule_id = params.subItem3
                        dispatch({ type: "PAYMENT_RULE_EDIT", paymentRuleId: payment_rule_id });
                        dispatch({ type: "CREATE_PAYMENT_RULE" });
                    } else
                        dispatch({ type: "LIST_PAYMENT" });
                } else
                    dispatch({ type: "LIST_PAYMENT" });
            } else
                dispatch({ type: "LIST_PAYMENT" });
        }
        else
            dispatch({ type: "LIST_PAYMENT" });
        return (null)
    }

    // Shipping:
    const SellerShippingRoutes = () => {
        let params = useParams();
        if (params.subItem !== undefined) {
            if (params.subItem.includes("lista"))
                dispatch({ type: "LIST_SHIPPING" });
            else if (params.subItem.includes("regras"))
                dispatch({ type: "LIST_SHIPPING_RULE" });
            else if (params.subItem.includes("cadastro")) {
                if (params.subItem2 !== undefined) {
                    if (params.subItem2.includes("metodo")) {
                        dispatch({ type: "SHIPPING_EDIT", shippingId: false });
                        dispatch({ type: "CREATE_SHIPPING" });
                    } else if (params.subItem2.includes("regra")) {
                        dispatch({ type: "SHIPPING_RULE_EDIT", paymentRuleId: false });
                        dispatch({ type: "CREATE_SHIPPING_RULE" });
                    } else
                        dispatch({ type: "LIST_SHIPPING" });
                } else
                    dispatch({ type: "LIST_SHIPPING" });
            } else if (params.subItem.includes("editar")) {
                if (params.subItem2 !== undefined) {
                    if (params.subItem2.includes("metodo")) {
                        let shipping_id = params.subItem3
                        dispatch({ type: "SHIPPING_EDIT", shippingId: shipping_id });
                        dispatch({ type: "CREATE_SHIPPING" });
                    } else if (params.subItem2.includes("regra")) {
                        let shipping_rule_id = params.subItem3
                        dispatch({ type: "SHIPPING_RULE_EDIT", shippingRuleId: shipping_rule_id });
                        dispatch({ type: "CREATE_SHIPPING_RULE" });
                    } else
                        dispatch({ type: "LIST_SHIPPING" });
                } else
                    dispatch({ type: "LIST_SHIPPING" });
            } else
                dispatch({ type: "LIST_SHIPPING" });
        }
        else
            dispatch({ type: "LIST_SHIPPING" });
        return (null)
    }

    // Cart:
    const SellerCartRoutes = () => {
        let params = useParams();
        if (params.subItem !== undefined) {
            if (params.subItem.includes("regras-produto"))
                dispatch({ type: "LIST_PRODUCT_RULE" });
            else if (params.subItem.includes("regras-carrinho"))
                dispatch({ type: "LIST_CART_RULE" });
            else if (params.subItem.includes("cadastro")) {
                if (params.subItem2 !== undefined) {
                    if (params.subItem2.includes("regras-produto")) {
                        dispatch({ type: "PRODUCT_RULE_EDIT", productRuleId: false });
                        dispatch({ type: "CREATE_PRODUCT_RULE" });
                    } else if (params.subItem2.includes("regras-carrinho")) {
                        dispatch({ type: "CART_RULE_EDIT", cartRuleId: false });
                        dispatch({ type: "CREATE_CART_RULE" });
                    } else
                        dispatch({ type: "LIST_PRODUCT_RULE" });
                } else
                    dispatch({ type: "LIST_PRODUCT_RULE" });
            } else if (params.subItem.includes("editar")) {
                if (params.subItem2 !== undefined) {
                    if (params.subItem2.includes("regras-produto")) {
                        let product_rule_id = params.subItem3
                        dispatch({ type: "PRODUCT_RULE_EDIT", productRuleId: product_rule_id });
                        dispatch({ type: "CREATE_PRODUCT_RULE" });;
                    } else if (params.subItem2.includes("regras-carrinho")) {
                        let cart_rule_id = params.subItem3
                        dispatch({ type: "CART_RULE_EDIT", cartRuleId: cart_rule_id });
                        dispatch({ type: "CREATE_CART_RULE" });
                    } else
                        dispatch({ type: "LIST_PRODUCT_RULE" });
                } else
                    dispatch({ type: "LIST_PRODUCT_RULE" });
            } else
                dispatch({ type: "LIST_PRODUCT_RULE" });
        }
        else
            dispatch({ type: "LIST_PRODUCT_RULE" });
        return (null)
    }


    return (
        <Routes>
            <Route index path="/admin" element={<SellerHome />}></Route>
            <Route path="/admin/configuracoes" element={<SellerConfiguration />}></Route>

            {/* Customer Routes */}
            <Route path="/admin/clientes" element={<SellerCustomerRoutes />}></Route>
            <Route path="/admin/clientes/:subItem" element={<SellerCustomerRoutes />}></Route>
            <Route path="/admin/clientes/:subItem/:subItem2" element={<SellerCustomerRoutes />}></Route>

            {/* Product Routes */}
            <Route path="/admin/produtos" element={<SellerProductRoutes />}></Route>
            <Route path="/admin/produtos/:subItem" element={<SellerProductRoutes />}></Route>
            <Route path="/admin/produtos/:subItem/:subItem2" element={<SellerProductRoutes />}></Route>

            {/* Order Routes */}
            <Route path="/admin/pedidos" element={<SellerOrderRoutes />}></Route>
            <Route path="/admin/pedidos/:subItem" element={<SellerOrderRoutes />}></Route>
            <Route path="/admin/pedidos/:subItem/:subItem2" element={<SellerOrderRoutes />}></Route>

            {/* Payment Routes */}
            <Route path="/admin/pagamento" element={<SellerPaymentRoutes />}></Route>
            <Route path="/admin/pagamento/:subItem" element={<SellerPaymentRoutes />}></Route>
            <Route path="/admin/pagamento/:subItem/:subItem2" element={<SellerPaymentRoutes />}></Route>
            <Route path="/admin/pagamento/:subItem/:subItem2/:subItem3" element={<SellerPaymentRoutes />}></Route>

            {/* Shipping Routes */}
            <Route path="/admin/entrega" element={<SellerShippingRoutes />}></Route>
            <Route path="/admin/entrega/:subItem" element={<SellerShippingRoutes />}></Route>
            <Route path="/admin/entrega/:subItem/:subItem2" element={<SellerShippingRoutes />}></Route>
            <Route path="/admin/entrega/:subItem/:subItem2/:subItem3" element={<SellerShippingRoutes />}></Route>

            {/* Cart Routes */}
            <Route path="/admin/catalogo" element={<SellerCartRoutes />}></Route>
            <Route path="/admin/catalogo/:subItem" element={<SellerCartRoutes />}></Route>
            <Route path="/admin/catalogo/:subItem/:subItem2" element={<SellerCartRoutes />}></Route>
            <Route path="/admin/catalogo/:subItem/:subItem2/:subItem3" element={<SellerCartRoutes />}></Route>
        </Routes>
    )
}