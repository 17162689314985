import React, { useEffect, useState } from "react";
import {
  Table,
  Skeleton,
  Empty,
  Descriptions,
  Typography,
} from "antd";
import {
  mascaraCnpj,
  mascaraCpf,
} from "../../functions/validation";

const { Link } = Typography;

export default function SellerListOrderDetails(props) {
  const [loading, setLoading] = useState(true);
  const [prodData, setProdData] = useState([]);
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    let prodData = props.orderInfo.order_info.products.map((obj, index) => ({
      ...obj,
      key: index,
      sellPrice: props.orderInfo.order_info.prices[index],
      quantity: props.orderInfo.order_info.quantities[index],
    }));
    setProdData(prodData);
    setOrderData(props.orderInfo);
    setLoading(false);
  }, [props]);

  const columns = [
    {
      title: "Produto",
      dataIndex: "product_name",
      key: "name",
      render: (text, record) => (
        <>
          <Link> {text} </Link>
        </>
      ),
      ellipsis: true,
      responsive: ["sm"],
      width: "40%",
    },
    {
      title: "Código",
      dataIndex: "product_sku",
      key: "preco",
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Preço",
      dataIndex: "sellPrice",
      key: "sellPrice",
      render: (text) => <>{"R$ " + parseFloat(text).toFixed(2)}</>,
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Total",
      // dataIndex: "sellTotal",
      key: "sellTotal",
      render: (record) => <>{"R$ " + parseFloat(parseFloat(record.sellPrice) * parseInt(record.quantity)).toFixed(2)}</>,
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Produtos",
      render: (record) => (
        <>
          <Link> {record.product_name} </Link>
          <br />
          <>{"Preço: R$ " + parseFloat(record.sellPrice).toFixed(2)}</>
          <br />
          <>{"Quantidade: " + parseInt(record.quantity)}</>
          <br />
          <>{"Total: R$ " + parseFloat(parseFloat(record.sellPrice) * parseInt(record.quantity)).toFixed(2)}</>
        </>
      ),
      responsive: ["xs"],
    },
  ];

  return (
    <>
      {orderData && (
        <>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Cliente">
              <Link>
                {orderData.buyer.tipo_pessoa === "pf"
                  ? orderData.buyer.nome
                  : orderData.buyer.razao_social}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item
              label={orderData.buyer.tipo_pessoa === "pf" ? "CPF" : "CNPJ"}
            >
              {orderData.buyer.tipo_pessoa === "pf"
                ? mascaraCpf(orderData.buyer.cpf)
                : mascaraCnpj(orderData.buyer.cnpj)}
            </Descriptions.Item>
            <Descriptions.Item label="Método de Entrega">
              {orderData.shipping_info.nome}
            </Descriptions.Item>
            <Descriptions.Item label="Método de Pagamento">
              {orderData.payment_info.descricao}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}

      <Table
        columns={columns}
        expandIconColumnIndex={6}
        style={{ cursor: "pointer" }}
        // rowSelection={"checkbox"}
        expandable={{
          expandedRowRender: (record) =>
            <>
              {
                record.gtin ?
                  "GTIN: " + record.gtin :
                  "GTIN: Não informado"
              }
            </>,
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={loading ? [] : prodData}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
      />
      {orderData && (
        <>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Total de Produtos">
              {parseFloat(orderData.total_before).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </Descriptions.Item>
            <Descriptions.Item label="Taxa de entrega">
              {parseFloat(orderData.shipping_info.valor).toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </Descriptions.Item>
            {orderData.discount !== 0 && (
              <Descriptions.Item label="Descontos">
                {parseFloat(orderData.discount).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Total">
              {parseFloat(orderData.total).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </Descriptions.Item>
          </Descriptions>
          {orderData.observations && (
            <Descriptions
              layout="vertical"
              bordered
              style={{ marginTop: "15px" }}
            >
              <Descriptions.Item label="Observações">
                {orderData.observations}
              </Descriptions.Item>
            </Descriptions>
          )}
        </>
      )}
    </>
  );
}
