import { ConfigProvider } from "antd";
import pt_BR from "antd/lib/locale/pt_BR";

import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch, Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { debounce } from "lodash";
import { Spin, message } from "antd";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { baseURL, axiosClient } from "./apiClient";
import store from "./store";
import checkToken from "./actions/login";
import { addToCart } from "./actions/cart";

import SellerLogin from "./components/SellerLogin";
import BuyerLogin from "./components/BuyerLogin";
import Catalogo from "./pages/Catalogo";
import Checkout from "./pages/Checkout";
import Admin from "./pages/Admin";
import axios from 'axios';

import { openDB } from 'idb';

import './App.css';

import iosPWASplash from 'ios-pwa-splash';
iosPWASplash("logo512.png")

message.config({
  maxCount: 2,
});

function App() {
  const dispatch = useDispatch();
  const logged = useSelector((state) => state.authentication.logged);
  const stateMessage = useSelector((state) => state.message);
  const sellerData = useSelector((state) => state.sellerData);
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const cart = useSelector((state) => state.cart);
  const paymentMethod = useSelector((state) => state.order.paymentMethod);
  const shippingMethod = useSelector((state) => state.order.shippingMethod);
  const syncData = useSelector((state) => state.sync);
  const dataBase = useSelector((state) => state.indexedDB.dataBase);

  const [checkSeller, setCheckSeller] = useState(false);
  const [loading, setLoading] = useState(true);
  const [firstCartAdded, setFirstCartAdded] = useState(true);
  const [pageTitle, setPageTitle] = useState("Nupedido");

  let domainName = window.location.hostname;
  let pathName = window.location.pathname.replace(/\//g, "");

  useEffect(() => {
    openDB('nupedido', 1).then((db) => {
      db.get('config', 1).then((value) => dispatch({ type: "SET_SYNC", syncStatus: value.synced ? "synced" : value.status === "enabled" ? "checking" : "disabled" })
      )
      dispatch({ type: "SET_DATABASE", dataBase: db })
    })
  }, [])

  function getProductPrice(product) {
    return product.price_modified ? product.price_modified : product.product_price_sale ? product.product_price_sale : product.product_price
  }

  function amount(product) {
    return parseFloat(getProductPrice(product) * product.quantity).toFixed(2)
  }

  function sum(prev, next) {
    return parseFloat(prev) + parseFloat(next);
  }

  function getFormattedDate() {
    const now = new Date();
    const padZero = (num) => (num < 10 ? "0" : "") + num;
    const formattedDate =
      `${padZero(now.getDate())}/${padZero(now.getMonth() + 1)}/${now.getFullYear()} ` +
      `${padZero(now.getHours())}:${padZero(now.getMinutes())}:${padZero(now.getSeconds())}`;

    return formattedDate;
  }

  function findDiscrepancies(dataBackend, dataFrontend) {
    let idsNotInFrontend = Object.keys(dataBackend).filter(id => !dataFrontend.some(item => item.id === parseInt(id))).map(id => parseInt(id));
    let idsOutdated = dataFrontend
      .filter(item => dataBackend[item.id])
      .filter(item => new Date(item.last_modification.replace(/(\d{2})\/(\d{2})\/(\d{2})/, '$2/$1/$3')) < new Date(dataBackend[item.id].replace(/(\d{2})\/(\d{2})\/(\d{2})/, '$2/$1/$3')))
      .map(item => parseInt(item.id));

    let idsNotInBackend = dataFrontend.filter(item => !dataBackend[item.id]).map(item => parseInt(item.id));
    let idsNeedSubmit = dataFrontend.filter(item => item.needSubmit).map(item => parseInt(item.id));

    idsOutdated = idsOutdated.filter(id => !idsNeedSubmit.includes(id));
    idsNotInBackend = idsNotInBackend.filter(id => !idsNeedSubmit.includes(id));

    return {
      idsNotInFrontend,
      idsOutdated,
      idsNotInBackend,
      idsNeedSubmit
    };
  }

  async function getItemsByIds(dBStoreName, idsArray) {

    const tx = await dataBase.transaction(dBStoreName, 'readonly');
    let cursor = await tx.store.openCursor();

    let result = [];

    while (cursor) {
      const { key, value } = cursor;

      if (idsArray.includes(key)) {
        result.push(value);
      }

      cursor = await cursor.continue();
    }

    return result;
  }

  function isReachable(url) {
    fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then(function (resp) {
        if (resp && (resp.ok || resp.type === 'opaque')) {
          if (syncData.onlineStatus === false) {
            console.log("O aplicativo está Online")
            dispatch({ type: "SET_SYNC", onlineStatus: true })
          }

        }

      })
      .catch(function (err) {
        if (syncData.onlineStatus === true) {
          dispatch({ type: "SET_SYNC", onlineStatus: false })
          console.log("O aplicativo está Offline")
        }
      });
  }

  useEffect(() => {
    isReachable(baseURL + "/check")
    const interval = setInterval(() => isReachable(baseURL + "/check"), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [syncData.onlineStatus]);


  function checkProductSync() {
    console.log("Checking Products Sync");
    axiosClient.get("/products/check-products-sync", {
      withCredentials: true
    }).then((response) => {
      dispatch({ type: "SET_SYNC", productLastMod: response.data.last_modifications })
    })
  }

  function checkBuyerSync() {
    console.log("Checking Buyers Sync");
    axiosClient.get("/buyer/check-buyers-sync", {
      withCredentials: true
    }).then((response) => {
      dispatch({ type: "SET_SYNC", buyerLastMod: response.data.last_modifications })
    })
  }

  function checkCartSync() {
    console.log("Checking Carts Sync");
    axiosClient.get("/cart/check-carts-sync", {
      withCredentials: true
    }).then((response) => {
      dispatch({ type: "SET_SYNC", cartLastMod: response.data.last_modifications })
    })
  }

  function checkOrderSync() {
    console.log("Checking Order Sync");
    getAllData("orders").then((orders) => {
      dispatch({
        type: "SET_SYNC", orderDiscrepancies: {
          ...syncData.orderDiscrepancies, idsNeedSubmit: orders.map((order) => order.id),
          idsNotInFrontend: [],
          idsOutdated: []
        }
      })
    })

  }

  function checkConfigSync() {
    console.log("Checking Config Sync");
    axiosClient.get("/shipping/get", {
      withCredentials: true
    }).then((response) => {
      console.log("/shipping/get", response.data)
      updateData({ id: 3, ...response.data }, "config")
    })
    axiosClient.get("/payment/get", {
      withCredentials: true
    }).then((response) => {
      console.log("/payment/get", response.data)
      updateData({ id: 4, ...response.data }, "config")
    })
  }

  useEffect(() => {
    if (syncData.onlineStatus && syncData.syncStatus === "synced") {
      dispatch({ type: "SET_SYNC", syncStatus: "checking" })
      buyerView && axiosClient.post(
        "/cart/set-buyer-view",
        {
          buyerViewId: buyerView.id,
        },
        {
          withCredentials: true,
        }
      )
    }
  }, [syncData.onlineStatus]);

  useEffect(() => {
    if ((logged === "seller" || logged === "sellerUser") &&
      dataBase &&
      (syncData.syncStatus === "checking" || syncData.syncStatus === "synced") &&
      syncData.onlineStatus) {
      dataBase.put('config', { id: 1, status: 'enabled', synced: false });
      checkOrderSync();
      checkProductSync();
      checkBuyerSync();
      checkCartSync();
      checkConfigSync();
    }

    if (dataBase && syncData.syncStatus === "disabled") {
      dataBase.put('config', { id: 1, status: 'disabled', synced: false });
    }

  }, [syncData.onlineStatus, syncData.syncStatus, dataBase, logged])



  useEffect(() => {
    if (dataBase && syncData.onlineStatus && syncData.productLastMod) {
      getAllData("products").then((productsInFrontend) => {
        dispatch({ type: "SET_SYNC", productDiscrepancies: findDiscrepancies(syncData.productLastMod, productsInFrontend) })
      })
    }

  }, [syncData.productLastMod, dataBase])

  useEffect(() => {
    if (dataBase && syncData.onlineStatus && syncData.buyerLastMod) {
      getAllData("buyers").then((buyersInFrontend) => {
        dispatch({ type: "SET_SYNC", buyerDiscrepancies: findDiscrepancies(syncData.buyerLastMod, buyersInFrontend) })
      })
    }

  }, [syncData.buyerLastMod, dataBase])

  useEffect(() => {
    if (dataBase && syncData.onlineStatus && syncData.cartLastMod) {
      getAllData("carts").then((cartsInFrontend) => {
        dispatch({ type: "SET_SYNC", cartDiscrepancies: findDiscrepancies(syncData.cartLastMod, cartsInFrontend) })
      })
    }

  }, [syncData.cartLastMod, dataBase])


  useEffect(() => {
    if (
      Object.values(syncData.productDiscrepancies).every(value => Array.isArray(value)) &&
      Object.values(syncData.buyerDiscrepancies).every(value => Array.isArray(value)) &&
      Object.values(syncData.cartDiscrepancies).every(value => Array.isArray(value)) &&
      Object.values(syncData.orderDiscrepancies).every(value => Array.isArray(value))
    ) {



      const productSynced = Object.values(syncData.productDiscrepancies).every(value => {
        return value.length === 0;
      });

      const buyerSynced = Object.values(syncData.buyerDiscrepancies).every(value => {
        return value.length === 0;
      });

      const cartSynced = Object.values(syncData.cartDiscrepancies).every(value => {
        return value.length === 0;
      });


      if (productSynced !== syncData.productSynced || buyerSynced !== syncData.buyerSynced || cartSynced !== syncData.cartSynced) {
        dispatch({ type: "SET_SYNC", productSynced: productSynced, buyerSynced: buyerSynced, cartSynced: cartSynced })
      }

      if (syncData.syncStore === null) {
        if (syncData.orderDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "orders", syncStep: "idsNeedSubmit", syncIterationStep: 0 })
        }

        else if (syncData.productDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "products", syncStep: "idsNotInFrontend", syncIterationStep: 0 })
        } else if (syncData.productDiscrepancies.idsOutdated.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "products", syncStep: "idsOutdated", syncIterationStep: 0 })
        } else if (syncData.productDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "products", syncStep: "idsNeedSubmit", syncIterationStep: 0 })
        } else if (syncData.productDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "products", syncStep: "idsNotInBackend", syncIterationStep: 0 })
        }

        else if (syncData.buyerDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "buyers", syncStep: "idsNotInFrontend", syncIterationStep: 0 })
        } else if (syncData.buyerDiscrepancies.idsOutdated.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "buyers", syncStep: "idsOutdated", syncIterationStep: 0 })
        } else if (syncData.buyerDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "buyers", syncStep: "idsNeedSubmit", syncIterationStep: 0 })
        } else if (syncData.buyerDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "buyers", syncStep: "idsNotInBackend", syncIterationStep: 0 })
        }

        else if (syncData.cartDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "carts", syncStep: "idsNotInFrontend", syncIterationStep: 0 })
        } else if (syncData.cartDiscrepancies.idsOutdated.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "carts", syncStep: "idsOutdated", syncIterationStep: 0 })
        } else if (syncData.cartDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "carts", syncStep: "idsNeedSubmit", syncIterationStep: 0 })
        } else if (syncData.cartDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({ type: "SET_SYNC", syncStore: "carts", syncStep: "idsNotInBackend", syncIterationStep: 0 })
        }
        else {
          dispatch({ type: "SET_SYNC", productSynced: true, buyerSynced: true, cartSynced: true })
        }
      } else {

        if (syncData.syncStore === "products") {
          if (syncData.productDiscrepancies.idsNotInFrontend.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "products", syncStep: "idsNotInFrontend", syncIterationStep: 0 })
          } else if (syncData.productDiscrepancies.idsOutdated.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "products", syncStep: "idsOutdated", syncIterationStep: 0 })
          } else if (syncData.productDiscrepancies.idsNeedSubmit.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "products", syncStep: "idsNeedSubmit", syncIterationStep: 0 })
          } else if (syncData.productDiscrepancies.idsNotInBackend.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "products", syncStep: "idsNotInBackend", syncIterationStep: 0 })
          } else {
            dispatch({ type: "SET_SYNC", productSynced: true, syncStore: null, syncStep: null, syncIterationStep: 0 })
          }

        } else if (syncData.syncStore === "buyers") {
          if (syncData.buyerDiscrepancies.idsNotInFrontend.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "buyers", syncStep: "idsNotInFrontend", syncIterationStep: 0 })
          } else if (syncData.buyerDiscrepancies.idsOutdated.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "buyers", syncStep: "idsOutdated", syncIterationStep: 0 })
          } else if (syncData.buyerDiscrepancies.idsNeedSubmit.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "buyers", syncStep: "idsNeedSubmit", syncIterationStep: 0 })
          } else if (syncData.buyerDiscrepancies.idsNotInBackend.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "buyers", syncStep: "idsNotInBackend", syncIterationStep: 0 })
          } else {
            dispatch({ type: "SET_SYNC", buyerSynced: true, syncStore: null, syncStep: null, syncIterationStep: 0 })
          }

        } else if (syncData.syncStore === "carts") {
          if (syncData.cartDiscrepancies.idsNotInFrontend.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "carts", syncStep: "idsNotInFrontend", syncIterationStep: 0 })
          } else if (syncData.cartDiscrepancies.idsOutdated.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "carts", syncStep: "idsOutdated", syncIterationStep: 0 })
          } else if (syncData.cartDiscrepancies.idsNeedSubmit.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "carts", syncStep: "idsNeedSubmit", syncIterationStep: 0 })
          } else if (syncData.cartDiscrepancies.idsNotInBackend.length > 0) {
            dispatch({ type: "SET_SYNC", syncStore: "carts", syncStep: "idsNotInBackend", syncIterationStep: 0 })
          } else {
            dispatch({ type: "SET_SYNC", cartSynced: true, syncStore: null, syncStep: null, syncIterationStep: 0 })
          }
        }

      }
    }
  }, [syncData.syncStore, syncData.productDiscrepancies, syncData.buyerDiscrepancies, syncData.cartDiscrepancies])


  useEffect(() => {
    if (syncData.syncStore === "orders") {

      syncData.syncStatus !== "syncing" && dispatch({ type: "SET_SYNC", syncStatus: "syncing" })

      if (syncData.syncStep === "idsNeedSubmit") {
        getItemsByIds("orders", syncData.orderDiscrepancies.idsNeedSubmit).then((orders) => {
          orders.forEach((orderData) => {
            axiosClient
              .post("/cart/order", orderData, { withCredentials: true })
              .then((response) => {
                if (response.data.status === "Success") {
                  dataBase.delete('orders', orderData.id)
                }
              })
          })

        }).then(
          dispatch({ type: "SET_SYNC", orderDiscrepancies: { ...syncData.orderDiscrepancies, idsNeedSubmit: [] } })
        )
      }


    }
    else if (syncData.syncStore === "products") {

      syncData.syncStatus !== "syncing" && dispatch({ type: "SET_SYNC", syncStatus: "syncing" })

      if (syncData.syncStep === "idsNotInBackend") {
        syncData.productDiscrepancies.idsNotInBackend.forEach((itemId) => dataBase.delete('products', itemId))
        dispatch({ type: "SET_SYNC", productDiscrepancies: { ...syncData.productDiscrepancies, idsNotInBackend: [] } })
      }

      if (syncData.syncStep === "idsNotInFrontend" || syncData.syncStep === "idsOutdated") {
        let funcData = syncData.syncStep === "idsNotInFrontend" ? addData : syncData.syncStep === "idsOutdated" && updateData
        let searchIds = syncData.syncStep === "idsNotInFrontend" ? syncData.productDiscrepancies.idsNotInFrontend : syncData.syncStep === "idsOutdated" && syncData.productDiscrepancies.idsOutdated

        let searchUrl = "/products/search"
        axiosClient.post(searchUrl, {
          page: syncData.syncIterationStep,
          perPage: 10,
          retrieveImages: true,
          searchIds: searchIds
        }, { withCredentials: true }).then((response) => {
          response.data.products.forEach((item, i) => funcData(item, "products", response.data.images[i]))
          if (response.data.actual_page === response.data.total_pages) {
            syncData.syncStep === "idsNotInFrontend" && dispatch({ type: "SET_SYNC", productDiscrepancies: { ...syncData.productDiscrepancies, idsNotInFrontend: [] } })
            syncData.syncStep === "idsOutdated" && dispatch({ type: "SET_SYNC", productDiscrepancies: { ...syncData.productDiscrepancies, idsOutdated: [] } })
          } else {
            syncData.syncDrawer && dispatch({ type: "SET_SYNC", syncIterationStep: response.data.actual_page + 1, syncIterationTotal: response.data.total_pages })
          }
        })
      } else if (syncData.syncStep === "idsNeedSubmit") {
        // remover do indexDb
      }


    } else if (syncData.syncStore === "buyers") {

      syncData.syncStatus !== "syncing" && dispatch({ type: "SET_SYNC", syncStatus: "syncing" })

      if (syncData.syncStep === "idsNotInBackend") {
        syncData.buyerDiscrepancies.idsNotInBackend.forEach((itemId) => dataBase.delete('buyers', itemId))
        dispatch({ type: "SET_SYNC", buyerDiscrepancies: { ...syncData.buyerDiscrepancies, idsNotInBackend: [] } })
      }

      if (syncData.syncStep === "idsNotInFrontend" || syncData.syncStep === "idsOutdated") {
        let funcData = syncData.syncStep === "idsNotInFrontend" ? addData : syncData.syncStep === "idsOutdated" && updateData
        let searchIds = syncData.syncStep === "idsNotInFrontend" ? syncData.buyerDiscrepancies.idsNotInFrontend : syncData.syncStep === "idsOutdated" && syncData.buyerDiscrepancies.idsOutdated
        let searchUrl = "/auth/search/buyer"
        axiosClient.post(searchUrl, {
          page: syncData.syncIterationStep,
          perPage: 10,
          searchIds: searchIds
        }, { withCredentials: true }).then((response) => {
          response.data.buyers.forEach(item => funcData(item, "buyers"))
          if (response.data.actual_page === response.data.total_pages) {
            syncData.syncStep === "idsNotInFrontend" && dispatch({ type: "SET_SYNC", buyerDiscrepancies: { ...syncData.buyerDiscrepancies, idsNotInFrontend: [] } })
            syncData.syncStep === "idsOutdated" && dispatch({ type: "SET_SYNC", buyerDiscrepancies: { ...syncData.buyerDiscrepancies, idsOutdated: [] } })
          } else {
            syncData.syncDrawer && dispatch({ type: "SET_SYNC", syncIterationStep: response.data.actual_page + 1, syncIterationTotal: response.data.total_pages })
          }
        })
      } else if (syncData.syncStep === "idsNeedSubmit") {
        // remover do indexDb
      }


    } else if (syncData.syncStore === "carts") {

      syncData.syncStatus !== "syncing" && dispatch({ type: "SET_SYNC", syncStatus: "syncing" })

      if (syncData.syncStep === "idsNotInBackend") {
        syncData.cartDiscrepancies.idsNotInBackend.forEach((itemId) => dataBase.delete('carts', itemId))
        dispatch({ type: "SET_SYNC", cartDiscrepancies: { ...syncData.cartDiscrepancies, idsNotInBackend: [] } })
      }

      if (syncData.syncStep === "idsNotInFrontend" || syncData.syncStep === "idsOutdated") {
        let funcData = syncData.syncStep === "idsNotInFrontend" ? addData : syncData.syncStep === "idsOutdated" && updateData
        let searchIds = syncData.syncStep === "idsNotInFrontend" ? syncData.cartDiscrepancies.idsNotInFrontend : syncData.syncStep === "idsOutdated" && syncData.cartDiscrepancies.idsOutdated
        let searchUrl = "/cart/search"
        axiosClient.post(searchUrl, {
          page: syncData.syncIterationStep,
          perPage: 10,
          searchIds: searchIds
        }, { withCredentials: true }).then((response) => {
          response.data.carts.forEach(item => funcData(item, "carts"))
          if (response.data.actual_page === response.data.total_pages) {
            syncData.syncStep === "idsNotInFrontend" && dispatch({ type: "SET_SYNC", cartDiscrepancies: { ...syncData.cartDiscrepancies, idsNotInFrontend: [] } })
            syncData.syncStep === "idsOutdated" && dispatch({ type: "SET_SYNC", cartDiscrepancies: { ...syncData.cartDiscrepancies, idsOutdated: [] } })
          } else {
            syncData.syncDrawer && dispatch({ type: "SET_SYNC", syncIterationStep: response.data.actual_page + 1, syncIterationTotal: response.data.total_pages })
          }
        })
      } else if (syncData.syncStep === "idsNeedSubmit") {
        getItemsByIds("carts", syncData.cartDiscrepancies.idsNeedSubmit).then((carts) => {

          carts.forEach((cart) => {
            getItemsByIds("products", cart.products_ids).then((products) => {

              const productsWithInfos = products.sort((a, b) => {
                return cart.products_ids.indexOf(a.id) - cart.products_ids.indexOf(b.id);
              }).map((product, index) => {
                return { ...product, quantity: cart.quantities[index] };
              })

              getSingleData("buyers", cart.buyer_id).then((buyer) => sendCart(productsWithInfos, buyer, syncData).then((response) => {
                dataBase.delete('carts', cart.id);

              })).then(
                dispatch({ type: "SET_SYNC", cartDiscrepancies: { ...syncData.cartDiscrepancies, idsNeedSubmit: [] } })
              )
            })
          })

        })
      }


    }

  }, [syncData.syncIterationStep, syncData.syncStore, syncData.syncStep, syncData.syncDrawer])

  async function getAllData(dBStoreName) {

    let tx = dataBase.transaction(dBStoreName, 'readonly')
    let store = tx.objectStore(dBStoreName)

    return await store.getAll()
  }

  async function getDataFromCursor(dBStoreName) {
    const tx = await dataBase.transaction(dBStoreName, 'readonly');
    let cursor = await tx.store.openCursor();
    return cursor.value
  }

  async function getSingleData(dBStoreName, idx) {
    const value = await dataBase.get(dBStoreName, idx);
    return value
  }

  async function addData(dbData, dBStoreName, fileData = null) {
    try {
      const tx = await dataBase.transaction(dBStoreName, 'readwrite');
      const store = tx.objectStore(dBStoreName);
      store.add(dbData);
      await tx.done;
      fileData && localStorage.setItem(dBStoreName + "_" + dbData.id, fileData)
    } catch (error) {
      console.error("Erro: ", error);
    }
  }

  async function updateData(data, dBStoreName, fileData = null) {
    try {
      dataBase.put(dBStoreName, data);
    } catch (error) {
      console.error("Erro: ", error);
    }
  }

  function extractProductInfo(products) {
    let result = {
      products_ids: [],
      prices: [],
      prices_modified: [],
      quantities: []
    };

    for (let product of products) {
      // Verifica se o objeto possui os campos necessários
      let productId = product.id ? product.id : null;
      let price = product.product_price ? product.product_price : null;
      let priceModified = product.price_modified ? product.price_modified : null;
      let quantity = product.quantity ? product.quantity : null;

      // Adiciona os valores ao resultado
      result.products_ids.push(productId);
      result.prices.push(price);
      result.prices_modified.push(priceModified);
      result.quantities.push(quantity);
    }

    return result;
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("state") && params.has("code")) {
      var state = atob(params.get("state")).split(";");
      var url = `${state[1]}/admin?code=${params.get("code")}&integration=${state[0]
        }`;
      window.location.replace(url);
    }
  }, []);


  useEffect(() => {
    if (dataBase && syncData.productSynced && syncData.buyerSynced && syncData.cartSynced && syncData.syncStatus !== "disabled") {
      dispatch({ type: "SET_SYNC", syncStatus: "synced" })
      dataBase.put('config', { id: 1, status: 'enabled', synced: true });
    }
  }, [dataBase, syncData.syncStatus, syncData.productSynced, syncData.buyerSynced, syncData.cartSynced]);


  useEffect(() => {
    if (cart.products.length == 0) {
      dispatch({
        type: "SET_CART", productsTotal: 0,
        shippingValue: 0,
        discountValue: 0,
        cartTotal: 0
      })
    } else {
      let productsTotal = cart.products.map(amount).reduce(sum)
      let shippingValue = 0
      let discountValue = 0
      let cartTotal = 0

      if (shippingMethod && shippingMethod.valor) {
        shippingValue = shippingMethod.valor
      } else {
        shippingValue = 0
      }

      if (paymentMethod) {
        if (paymentMethod.discount_value) {
          discountValue =
            paymentMethod.discount_type === "percent"
              ? (parseFloat(paymentMethod.discount_value) / 100) * productsTotal
              : paymentMethod.discount_value;

        } else if (paymentMethod.metodo === "pagseguro_credit_card" || paymentMethod.metodo === "mercadopago_credit_card") {
          discountValue = -parseFloat(paymentMethod.aditional_features.fix_tax)
        }
      }

      if (paymentMethod) {
        if (paymentMethod.metodo === "pagseguro_credit_card") {
          if (paymentMethod.total_value_installment && paymentMethod.installments_selected) {
            cartTotal = parseFloat(parseFloat(paymentMethod.total_value_installment) * parseFloat(paymentMethod.installments_selected)).toFixed(2)
          }
        } else if (paymentMethod.metodo === "mercadopago_credit_card") {
          cartTotal = parseFloat(parseFloat(productsTotal) - parseFloat(discountValue) + parseFloat(shippingValue)).toFixed(2)
        }
        else {
          cartTotal = parseFloat(parseFloat(productsTotal) - parseFloat(discountValue) + parseFloat(shippingValue)).toFixed(2)
        }

      }
      else {
        cartTotal = parseFloat(parseFloat(productsTotal) - parseFloat(discountValue) + parseFloat(shippingValue)).toFixed(2)
      }

      dispatch({
        type: "SET_CART", productsTotal: productsTotal,
        shippingValue: shippingValue,
        discountValue: discountValue,
        cartTotal: cartTotal
      })
    }

  }, [cart.products, paymentMethod, shippingMethod]);


  async function sendCart(products, buyer, syncData, cancelToken) {
    dispatch({ type: "CART_UPDATED", updated: false });
    if (syncData.onlineStatus) {
      axiosClient
        .post(
          "/cart/add",
          JSON.stringify({
            products: products,
            buyerId: buyer ? buyer.id : null,
          }),
          {
            withCredentials: true,
            cancelToken: cancelToken.token
          }
        )
        .then((response) => {
          if (syncData.syncStatus === "synced") {
            const cartData = products.reduce((acc, curr) => {
              acc.products_ids.push(curr.id);
              acc.prices_modified.push(curr.price_modified || null);
              acc.quantities.push(curr.quantity || null);
              return acc;
            }, { products_ids: [], prices_modified: [], quantities: [] });
            const currentDate = getFormattedDate();
            updateData({ id: buyer.cart_id, buyer_id: buyer.id, last_modification: currentDate, ...cartData }, "carts");
          }
          dispatch({ type: "CART_RETURNED", returned: true });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Previous request canceled', error.message);
          }
        });
    } else {
      if (syncData.syncStatus === "synced") {
        const currentDate = getFormattedDate();
        let cartData = { id: buyer.cart_id, buyer_id: buyer.id, seller_id: buyer.seller_id, last_modification: currentDate, needSubmit: true, ...extractProductInfo(products) };
        dataBase.put('carts', cartData);
        dispatch({ type: "CART_RETURNED", returned: true });
      }
    }
  }

  const sendCartHandler = useCallback(debounce((products, buyer, syncData, cancelToken) => sendCart(products, buyer, syncData, cancelToken), 600), []);




  const cancelTokenRef = useRef(null);

  useEffect(() => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Operation canceled due to new request.');
    }
    cancelTokenRef.current = axios.CancelToken.source();

    if (cart.retrieved && cart.updated && buyerView) {
      sendCartHandler(cart.products, buyerView, syncData, cancelTokenRef.current);
    }

    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel('Component unmounted.');
      }
    };
  }, [cart.products, buyerView, syncData]);

  useEffect(() => {
    cart.updated === false &&
      cart.returned &&
      cart.goToCheckout &&
      window.location.replace("/checkout")
  },
    [cart.goToCheckout, cart.returned, cart.updated]
  );

  useEffect(() => {
    if ((logged === "buyer" || logged === "seller" || logged === "sellerUser") &&
      (pathName === sellerData.pathName || pathName === "checkout")
    ) {
      if (buyerView === null) {
        if (syncData.onlineStatus) {
          axiosClient
            .get(`/cart/get-buyer-view`, { withCredentials: true })
            .then((response) => {
              dispatch({
                type: "SET_BUYER_VIEW",
                buyerView: response.data.buyer_view,
              });
              dispatch({ type: "ACTIVE_CEP", value: response.data.buyer_view.cep, uf: response.data.buyer_view.uf });

              dataBase && dataBase.put('config', { id: 2, buyerView: response.data.buyer_view });
            });
        } else if (dataBase) {
          getSingleData("config", 2).then((value) => {
            if (value.buyerView) {
              dispatch({
                type: "SET_BUYER_VIEW",
                buyerView: value.buyerView,
              });
            } else {
              if (syncData.syncStatus === "synced") {
                getDataFromCursor("buyers").then((buyerViewValue) => {
                  dispatch({
                    type: "SET_BUYER_VIEW",
                    buyerView: buyerViewValue,
                  });
                  dataBase.put('config', { id: 2, buyerView: buyerViewValue });
                })
              }
            }
          })
        }
      }
    }
  }, [dataBase, logged, sellerData, syncData.onlineStatus, syncData.syncStatus]);

  useEffect(() => {
    if (cart.updated) {
      stateMessage.messageType === "success" &&
        message.success(stateMessage.messageText, 4);
      stateMessage.messageType === "error" &&
        message.error(stateMessage.messageText, 4);
      stateMessage.messageType === "warning" &&
        message.warning(stateMessage.messageText, 4);
    }
  }, [stateMessage]);

  useEffect(() => {
    if (logged === "buyer") {
      let zip = (cartProdResponse, cartQtyResponse) =>
        cartProdResponse.map((x, i) =>
          dispatch(addToCart(x, 0, 0, cartQtyResponse[i]))
        );
      axiosClient
        .get("/cart/get", { withCredentials: true })
        .then((response) => {
          dispatch({
            type: "SET_MIN_ORDER",
            cartTotal: response.data.cart_total,
            minOrderValue: response.data.min_order_value,
          });
          zip(response.data.products, response.data.quantities);
        })
        .then((response) => {
          dispatch({ type: "CART_RETRIEVED" });
          dispatch({ type: "CART_RETURNED", returned: true });
        });
    }
  }, [logged]);

  useEffect(() => {
    console.log("logged, buyerView", logged, buyerView)
    if (
      (logged === "seller" || logged === "sellerUser") &&
      buyerView
    ) {
      dispatch({ type: "RESET_CART" });
      let zip = (cartProdResponse, cartQtyResponse) =>
        cartProdResponse.map((x, i) =>
          dispatch(addToCart(x, 0, 0, cartQtyResponse[i]))
        );
      if (syncData.onlineStatus) {
        axiosClient
          .post("/cart/get", { buyerId: buyerView.id }, { withCredentials: true })
          .then((response) => {
            dispatch({
              type: "SET_MIN_ORDER",
              cartTotal: response.data.cart_total,
              minOrderValue: response.data.min_order_value,
            });
            zip(response.data.products, response.data.quantities);
          })
          .then((response) => {
            dispatch({ type: "CART_RETRIEVED" });
            dispatch({ type: "CART_RETURNED", returned: true });
          });
      } else {
        if (syncData.syncStatus === "synced") {
          getSingleData("carts", buyerView.cart_id).then((cart) => {
            if (cart) {
              getItemsByIds("products", cart.products_ids).then((products) => {
                zip(products.sort((a, b) => {
                  return cart.products_ids.indexOf(a.id) - cart.products_ids.indexOf(b.id);
                }), cart.quantities);
                dispatch({ type: "CART_RETRIEVED" });
                dispatch({ type: "CART_RETURNED", returned: true })
              })
            } else {
              dispatch({ type: "CART_RETRIEVED" });
              dispatch({ type: "CART_RETURNED", returned: true })
            }
          })
        }
      }
    }
  }, [logged, buyerView?.id]);

  useEffect(() => {
    if (((logged === "seller" || logged === "sellerUser") && dataBase) || (logged !== "seller" || logged !== "sellerUser")) {
      axiosClient.get(
        "check-pathname",
        {
          params: {
            pathName: pathName,
            domainName: domainName
          }, withCredentials: true
        }
      ).then((response) => {
        console.log(response)
        setLoading(false);
        dispatch({
          type: "SET_SELLER",
          ...response.data,
          url: response.data.logo_image ? response.data.logo_image.url : false,
          pathName: pathName,
        });
        (logged === "seller" || logged === "sellerUser") && updateData({ id: 5, ...response.data }, "config")
        setCheckSeller(true);
        response.data.status === "Success" && response.data.nome_fantasia && setPageTitle(response.data.nome_fantasia);
      }).catch((response) => {
        if ((logged === "seller" || logged === "sellerUser") && dataBase) {
          getSingleData("config", 5).then((data) => {
            console.log("dataa: ", data)
            dispatch({
              type: "SET_SELLER",
              ...data,
              url: data.logo_image ? data.logo_image.url : false,
              pathName: pathName,
            });
            setLoading(false);
          })
        }
      })
    }
  }, [logged, dataBase])

  useEffect(() => {
    checkToken();
  }, [logged]);

  return (
    <ConfigProvider locale={pt_BR}>
      <>
        {loading || logged === "notChecked" || !dataBase ? (
          <>
            <div style={{ textAlign: "center", padding: "30px 50px" }}>
              <Spin size="large" />
            </div>
          </>
        ) : (
          <Provider store={store}>
            <Router>
              {checkSeller && (
                <HelmetProvider>
                  <Helmet>
                    <title>{pageTitle + " - Pedido Online"}</title>
                  </Helmet>
                </HelmetProvider>
              )}
              {(logged === "seller" || logged === "sellerUser") &&
                pathName.includes("admin") ? (
                <Admin />
              ) : logged === "buyer" && pathName === "checkout" ? (
                <Checkout />
              ) : (logged === "seller" || logged === "sellerUser") &&
                pathName === "checkout" ? (
                <Checkout />
              ) : logged === "buyer" && pathName !== "checkout" ? (
                <Catalogo />
              ) : (logged === "seller" || logged === "sellerUser") &&
                pathName === sellerData.username ? (
                <Catalogo />
              ) : (logged === "seller" || logged === "sellerUser") &&
                sellerData.username &&
                pathName !== "checkout" &&
                pathName !== sellerData.username ? (
                window.location.replace("admin")
              ) : logged === false && pathName.includes("admin") ? (
                <SellerLogin domainName={domainName} dataBase={dataBase} />
              ) : logged === false && checkSeller ? (
                <BuyerLogin pathName={pathName} domainName={domainName} dataBase={dataBase} />
              ) : (
                <div style={{ textAlign: "center", padding: "30px 50px" }}>
                  <Spin size="large" />
                </div>
              )}
            </Router>
          </Provider>
        )}
      </>
    </ConfigProvider>
  );
}

export default App;
