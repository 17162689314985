import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import styles from "./cadastro.module.css";
import {
  PlusOutlined,
  MinusCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  Space,
  Collapse,
  DatePicker,
  Switch,
  Tooltip,
  Transfer,
  Dropdown,
  Menu,
  Row,
  Skeleton,
  Col,
} from "antd";
import { axiosClient } from "../../apiClient";
import moment from "moment";
import SearchCustomerRules from "../../components/SearchCustomerRules";

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

export default function SellerCreatePaymentRule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [targetKeys, setTargetKeys] = useState([]);
  const [payment, setPayment] = useState([]);
  const [emQuemAplica, setEmQuemAplica] = useState({});
  const [buyerAvailableTags, setBuyerAvailableTags] = useState([]);
  const [action, setAction] = useState({});
  const [buyersFormated, setBuyersFormated] = useState([]);
  const [operador, setOperador] = useState("all");
  const [loading, setLoading] = useState(true);

  const editItems = useSelector((state) => state.editItems);

  useEffect(() => {
    axiosClient
      .get("/payment/get", { withCredentials: true })
      .then((response) => {
        const values = response.data.payments.map((v) => ({
          ...v,
          key: v.id,
        }));
        setPayment(values);
      });
  }, []);

  const onChange = (newTargetKeys, direction, moveKeys) => {
    setTargetKeys(newTargetKeys);
  };

  useEffect(() => {
    if (editItems.paymentRuleId) {
      axiosClient
        .post(
          "/rules/payment/get",
          { ruleId: editItems.paymentRuleId },
          { withCredentials: true }
        )
        .then((response) => {
          setTargetKeys(response.data.payments);

          let buyerConditions = [];

          if (response.data.all_buyers) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "allBuyers" },
            ];
          }

          if (response.data.buyers.length > 0) {
            let buyers_formated = response.data.buyers.map((option) =>
              formatBuyer(option)
            );
            setBuyersFormated(buyers_formated);
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "specificBuyers", buyers: buyers_formated },
            ];
          }

          if (response.data.buyers_tags.length > 0) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "buyersTag", tags: response.data.buyers_tags },
            ];
          }

          form.setFieldsValue({
            emQuemAplica: buyerConditions,
          });

          let updateFields = {};
          buyerConditions.map((value, index) => {
            updateFields[index] = value.emQuemAplica;
          });
          setEmQuemAplica(updateFields);

          response.data.start_date &&
            response.data.end_date &&
            form.setFieldsValue({
              date: [
                moment(response.data.start_date),
                moment(response.data.end_date),
              ],
            });

          form.setFieldsValue(response.data);
          setAction(response.data.action);
          setOperador(response.data.buyer_operator);

          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    axiosClient
      .get("/customer/tag/list", { withCredentials: true })
      .then((out) => {
        setBuyerAvailableTags(out.data.tags);
      });
  }, []);

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = (values) => {
    let data = editItems.paymentRuleId
      ? { ...values, ruleId: editItems.paymentRuleId }
      : values;
    data = values.date
      ? {
        ...data,
        date: [
          values.date[0].format("YYYY-MM-DD"),
          values.date[1].format("YYYY-MM-DD"),
        ],
      }
      : data;
    data = { ...data, buyerConditionType: operador };
    axiosClient
      .post("/rules/payment/create", data, { withCredentials: true })
      .then((response) => navigate("/admin/pagamento/regras"));//dispatch({ type: "LIST_PAYMENT_RULE" }));
  };

  const formatBuyer = (option) => {
    let formated = "";
    if (option.tipo_pessoa === "pf") {
      formated =
        option.id + "-" + option.email + " " + option.nome + " " + option.cpf;
    } else {
      formated =
        option.id +
        " - " +
        option.email +
        " - " +
        option.razao_social +
        " " +
        option.nome_fantasia +
        " " +
        option.cnpj;
    }
    return formated;
  };

  const handleSelectChange = (values) => {
    setBuyersFormated(values);
    form.setFieldsValue({
      emQuemAplica: [{ emQuemAplica: "specificBuyers", buyers: values }],
    });
  };

  const onOperadorChange = (value) => {
    setOperador(value);
  };

  const onEmQuemAplicaChange = (value, Formkey) => {
    var obj = { ...emQuemAplica };
    obj[Formkey] = value;
    setEmQuemAplica(obj);
  };

  function deleteRule() {
    axiosClient
      .post(
        "/rules/payment/delete",
        { ruleId: editItems.paymentRuleId },
        { withCredentials: true }
      )
      .then((response) => navigate("/admin/pagamento/regras"));//dispatch({ type: "LIST_PAYMENT_RULE" }));
  }

  const menu = (
    <Menu onClick={() => deleteRule()}>
      <Menu.Item key="1">Excluir Regra</Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        title={editItems.paymentRuleId ? "Editar Regra" : "Nova Regra"}
        onBack={() => window.history.back()}
        extra={
          editItems.paymentRuleId && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="primary">
                Ações <DownOutlined />
              </Button>
            </Dropdown>
          )
        }
      ></PageHeader>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          scrollToFirstError={true}
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
        >
          <Divider orientation="left">Dados gerais</Divider>
          <Form.Item
            name={["name"]}
            label="Nome da regra"
            rules={[
              {
                type: "string",
                required: true,
                max: 100,
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
          valuePropName="checked"
          initialValue={true}
          name={["is_active"]}
          label="Status"
        >
          <Switch />
        </Form.Item>

        <Tooltip
          placement="topLeft"
          title="Deixe em branco caso deseje que a regra seja permanente"
        >
          <Form.Item name={["date"]} label="Validade da regra">
            <RangePicker format={"DD/MM/YYYY"} />
          </Form.Item>
        </Tooltip> */}

          <Divider orientation="left">
            <Tooltip
              placement="topLeft"
              title="Escolha para quais clientes a regra será aplicada"
            >
              A quem se aplica
            </Tooltip>
          </Divider>

          {/* <Space>
          Aplicar regra para os clientes em que
          <Select
            value={operador}
            onChange={onOperadorChange}
            style={{ width: "120px" }}
          >
            <Option value="all">todas</Option>
            <Option value="any">qualquer</Option>
          </Select>
          {operador === "all"
            ? "condições forem verdadeiras"
            : "condição for verdadeira"}
        </Space>
        <br />
        <br /> */}

          <Form.List name="emQuemAplica">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Collapse key={key}>
                    <Panel header={"Condição " + parseInt(key + 1)} key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, "emQuemAplica"]}
                        fieldKey={[fieldKey, "emQuemAplica"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione a condição"
                          onChange={(value) => onEmQuemAplicaChange(value, key)}
                          allowClear
                        >
                          <Option value="allBuyers">Todos clientes</Option>
                          <Option value="specificBuyers">
                            Clientes específicos
                          </Option>
                          <Option value="buyersTag">
                            Clientes que contém tag
                          </Option>
                          {/* <Option value="clientesEstados">
                        Clientes dos estados
                      </Option> */}
                        </Select>
                      </Form.Item>
                      {emQuemAplica[key] === "buyersTag" && (
                        <Form.Item
                          {...restField}
                          name={[name, "tags"]}
                          fieldKey={[fieldKey, "tags"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione as tags"
                            mode="multiple"
                            allowClear
                          >
                            {buyerAvailableTags.map(function (group, i) {
                              return (
                                <OptGroup
                                  key={"group_" + group.id}
                                  label={group.title}
                                >
                                  {group.tags.map(function (tag, i) {
                                    return (
                                      <Option
                                        key={"tag_" + tag.id}
                                        value={group.id + "_" + tag.id}
                                      >
                                        {tag.title}
                                      </Option>
                                    );
                                  })}
                                </OptGroup>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                      {emQuemAplica[key] === "specificBuyers" && (
                        <Form.Item
                          {...restField}
                          name={[name, "buyers"]}
                          fieldKey={[fieldKey, "buyers"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <SearchCustomerRules
                            handleSelectChange={handleSelectChange}
                            buyersFormated={buyersFormated}
                          />
                        </Form.Item>
                      )}
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Panel>
                  </Collapse>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Condição
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Divider orientation="left">Métodos de Pagamento</Divider>
          <Form.Item name={["payments"]}>
            <Transfer
              showSelectAll={false}
              listStyle={{ width: "45%" }}
              titles={["Não Pemitidos", "Permitidos"]}
              dataSource={payment}
              targetKeys={targetKeys}
              onChange={onChange}
              render={(item) => item.descricao}
              oneWay={true}
            />
          </Form.Item>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    onClick={() => {
                      // dispatch({ type: "LIST_PAYMENT_RULE" });
                      navigate("/admin/pagamento/regras")
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col xs={18} sm={8}>
                  <Button block type="primary" htmlType="submit">
                    {editItems.paymentRuleId ? "Atualizar" : "Cadastrar"}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
