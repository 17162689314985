import React, { useEffect, useState } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Dropdown, Skeleton, Empty, Menu, Descriptions, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RollbackOutlined } from "@ant-design/icons";
// import styles from "./listproduct.module.css";
import { axiosClient } from "../../apiClient";
import { DownOutlined } from "@ant-design/icons";
import { mascaraCnpj, mascaraCpf } from "../../functions/validation";

const { Link } = Typography;

export default function SellerListOrderDetails() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const editItems = useSelector((state) => state.editItems);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [prodData, setProdData] = useState([]);
  const [orderData, setOrderData] = useState(false);

  useEffect(() => {
    axiosClient
      .post(
        "/cart/get_order",
        { orderId: editItems.orderId },
        { withCredentials: true }
      )
      .then((response) => {
        let order = response.data.order;
        let products = order.order_info.products;
        let quantities = order.order_info.quantities;
        let prices = order.order_info.prices;

        let prodData = products.map((obj, index) => ({
          ...obj,
          key: index,
          sellPrice: prices[index],
          quantity: quantities[index],
        }));
        setProdData(prodData);
        setOrderData(order);
        setLoading(false);
      });
  }, []);

  function deleteOrder() {
    setSending(true);
    axiosClient
      .post(
        "/cart/delete-order",
        { orderId: editItems.orderId },
        { withCredentials: true }
      )
      .then((response) => {
        setSending(false);
        navigate("/admin/pedidos/finalizados")
        // dispatch({ type: "LIST_ORDER" });
      });
  }

  const columns = [
    {
      title: "Produto",
      dataIndex: "product_name",
      key: "name",
      render: (text, record) => (
        <>
          <Link> {text} </Link>
        </>
      ),
      ellipsis: true,
      responsive: ["sm"],
      width: "40%",
    },
    {
      title: "Código",
      dataIndex: "product_sku",
      key: "preco",
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Preço",
      dataIndex: "sellPrice",
      key: "sellPrice",
      render: (text) => <>{"R$ " + parseFloat(text).toFixed(2)}</>,
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Total",
      // dataIndex: "sellTotal",
      key: "sellTotal",
      render: (record) => <>{"R$ " + parseFloat(parseFloat(record.sellPrice) * parseInt(record.quantity)).toFixed(2)}</>,
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Produtos",
      render: (record) => (
        <>
          <Link> {record.product_name} </Link>
          <br />
          <>{"Preço: R$ " + parseFloat(record.sellPrice).toFixed(2)}</>
          <br />
          <>{"Quantidade: " + parseInt(record.quantity)}</>
          <br />
          <>{"Total: R$ " + parseFloat(parseFloat(record.sellPrice) * parseInt(record.quantity)).toFixed(2)}</>
        </>
      ),
      responsive: ["xs"],
    },
  ];

  const menu = (
    <Menu onClick={() => deleteOrder()}>
      <Menu.Item key="1">Excluir Pedido</Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        title={"Pedido Nº " + orderData.number}
        onBack={() => window.history.back()}
        extra={
          editItems.orderId && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="primary" loading={sending}>
                Ações <DownOutlined />
              </Button>
            </Dropdown>
          )
        }
      ></PageHeader>
      {orderData && (
        <>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Cliente">
              <Link>
                {orderData.buyer.tipo_pessoa === "pf"
                  ? orderData.buyer.nome
                  : orderData.buyer.razao_social}
              </Link>
            </Descriptions.Item>
            <Descriptions.Item
              label={orderData.buyer.tipo_pessoa === "pf" ? "CPF" : "CNPJ"}
            >
              {orderData.buyer.tipo_pessoa === "pf"
                ? mascaraCpf(orderData.buyer.cpf)
                : mascaraCnpj(orderData.buyer.cnpj)}
            </Descriptions.Item>
            <Descriptions.Item label="Método de Entrega">
              {orderData.shipping_info.nome}
            </Descriptions.Item>
            <Descriptions.Item label="Método de Pagamento">
              {orderData.payment_info.descricao}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
      <Table
        columns={columns}
        expandIconColumnIndex={6}
        style={{ cursor: "pointer" }}
        // rowSelection={"checkbox"}
        expandable={{
          expandedRowRender: (record) =>
            <>
              {
                record.gtin ?
                  "GTIN: " + record.gtin :
                  "GTIN: Não informado"
              }
            </>,
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={loading ? [] : prodData}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
      />
      {orderData && (
        <>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Total de Produtos">
              {parseFloat(orderData.total_before).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </Descriptions.Item>
            <Descriptions.Item label="Taxa de entrega">
              {parseFloat(orderData.shipping_info.valor).toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )}
            </Descriptions.Item>
            {orderData.discount !== 0 && (
              <Descriptions.Item label="Descontos">
                {parseFloat(orderData.discount).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Total">
              {parseFloat(orderData.total).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </Descriptions.Item>
          </Descriptions>
          {orderData.observations && (
            <Descriptions
              layout="vertical"
              bordered
              style={{ marginTop: "15px" }}
            >
              <Descriptions.Item label="Observações">
                {orderData.observations}
              </Descriptions.Item>
            </Descriptions>
          )}
        </>
      )}
      <Button
        style={{ marginTop: "15px" }}
        onClick={() => {
          navigate("/admin/pedidos/finalizados")
          // dispatch({ type: "LIST_ORDER" });
        }}
      >
        <RollbackOutlined /> Voltar
      </Button>
    </>
  );
}
