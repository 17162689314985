import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./qtychanger.module.css";
import { Button } from 'antd';
import {
  PlusOutlined,
  MinusOutlined
} from "@ant-design/icons";


export default function QtyChangerCart(props) {
  const cartProducts = useSelector((state) => state.cart.products);
  const quantity = useSelector((state) => state.pageQty.data);

  let filteredCart = cartProducts.filter((obj) => {
    return obj.product_sku === props.product.product_sku;
  });

  let filteredQty = quantity.filter((obj) => {
    return obj.product_sku === props.product.product_sku;
  });

  let qtyInPage = filteredQty[0] ? filteredQty[0].quantity : 0;
  let qtyInCart = filteredCart[0] ? filteredCart[0].quantity : 0;

  const dispatch = useDispatch();
  return (
    <div className={styles["pqt-geral"]}>
      <Button size="small" onClick={() => {
        dispatch(props.func(props.product, qtyInPage, qtyInCart, -1));
        dispatch({ type: "CART_UPDATED", updated: true });
        dispatch({ type: "CART_RETURNED", returned: false });
      }} icon={<MinusOutlined />}></Button>

      <span className={styles["pqt"]}>{qtyInCart}</span>
      <Button size="small" onClick={() => {
        dispatch(props.func(props.product, qtyInPage, qtyInCart, 1));
        dispatch({ type: "CART_UPDATED", updated: true });
        dispatch({ type: "CART_RETURNED", returned: false });
      }} icon={<PlusOutlined />}></Button>
    </div>
  );
}

