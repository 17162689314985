function initialState() {
  return { logged: "notChecked", wrongPass: false, wrongUser: false, userId: false };
}

export function authentication(state = initialState(), action) {
  switch (action.type) {
    case "BUYER_LOGIN":
      return { ...state, logged: "buyer", userId: action.userId };
    case "SELLER_LOGIN":
      return { ...state, logged: "seller", userId: action.userId };
    case "SELLER_USER_LOGIN":
      return { ...state, logged: "sellerUser", userId: action.userId };
    case "USER_LOGOUT":
      return { ...state, logged: false };
    case "WRONG_PASSWORD":
      return { ...state, wrongPass: true };
    case "WRONG_USER":
      return { ...state, wrongUser: true };
    case "RESET_WRONG":
      return { ...state, wrongPass: false, wrongUser: false };

    default:
      return state;
  }
}
