import styles from "./index.module.css";
import { PageHeader } from '@ant-design/pro-layout';
import {
  Card,
  Col,
  Row,
  Form,
  Button,
  Divider,
  Radio,
  Badge,
  Skeleton,
  Descriptions,
  Alert,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { axiosClient } from "../../apiClient";

export default function SellerSubscription() {
  const [form] = Form.useForm();

  const [loadingInfo, setLoadingInfo] = useState(true);


  // Current Plan states
  const [planViewer, setPlanViewer] = useState([]);
  const [planInfo, setPlanInfo] = useState(null);

  // Current Plan Alerts states
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertType, setAlertType] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleClose = () => {
    setAlertVisible(false);
  };

  useEffect(() => {
    axiosClient
      .get("/subs/info", { withCredentials: true })
      .then((response) => {
        var r = response.data;
        if (r.customer_data.subscription_id === null) {
          setPlanInfo(null);
        } else {
          console.log(r.customer_data)
          setPlanInfo({
            items: r.customer_data.items.map((item, i) => {
              return ({
                title: item.price_id_info.name,
                interval: item.interval,
                quantity: item.quantity,
                amount: item.unit_amount,
              })
            }),
            periodo_label:
              r.customer_data.status == "trialing"
                ? `Teste até`
                : r.customer_data.cancel_at_period_end == true
                  ? `Cancelamento agendado`
                  : r.customer_data.cancel_at_period_end == false && `Vencimento`,
            ends:
              r.customer_data.status == "trialing"
                ? r.customer_data.trial_ends
                : r.customer_data.current_period_end,
            payment: r.customer_data.payment,
            renova:
              r.customer_data.cancel_at_period_end == false
                ? "Ativada"
                : "Desativado",
            status:
              r.customer_data.status == "trialing"
                ? "Período de teste"
                : r.customer_data.status == "active"
                  ? "Ativo"
                  : r.customer_data.status == "past_due"
                    ? "Vencido"
                    : r.customer_data.status,
            badge:
              r.customer_data.status == "trialing"
                ? "warning"
                : r.customer_data.status == "active"
                  ? "success"
                  : "error",
            price_id: r.customer_data.price_id,
            subscription_id: r.customer_data.subscription_id,
          });
        }
      })
      .then(() => setLoadingInfo(false));
  }, []);


  useEffect(() => {
    if (planInfo == null) {
      var info = (
        <>
          <div style={{ marginTop: 30 }}>
            <Alert
              type="info"
              message={
                <>
                  Não foi encontrado nenhum plano ativo no seu registro. Nessa situação você e seus clientes não perderão o acesso às informações registradas no sistema.
                  <br />
                  <br />
                  Entre em contato com a administração através do email <strong>contato@nupedido.com.br</strong> para regularizar a situação.
                </>
              }
            />
          </div>
        </>
      )
    } else {
      if (planInfo.status == "trialing") {
        var info = (
          <>
            <Descriptions
              bordered
              size={"small"}
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              <Descriptions.Item label="Cartão de crédito">
                <Badge status="error" text="Não cadastrado" />
              </Descriptions.Item>
            </Descriptions>
            <div style={{ marginTop: 30 }}>
              <Alert
                type="info"
                message={
                  <>
                    Você e seus clientes perderão acesso a todas funcionalidades
                    do catálogo ao final do período de testes. Cadastre um
                    cartão válido para que isso não aconteça.
                    <br />
                    Não se preocupe, você será cobrado apenas quando seu período
                    de testes acabar, dia{" "}
                    {/* <strong>{planInfo.ends.split(" - ")[0]}</strong>. */}
                  </>
                }
              />
            </div>
          </>
        )
      } else {
        console.log(planInfo)
        var info = (
          <>
            <h3>Status</h3>
            <Descriptions
              bordered
              size={"small"}
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              <Descriptions.Item label={"Situação"}>
                <Badge status={planInfo.badge} text={planInfo.status} />
              </Descriptions.Item>
              <Descriptions.Item label="Recorrência">
                {planInfo.items[0].interval.charAt(0).toUpperCase() + planInfo.items[0].interval.slice(1)}
              </Descriptions.Item>
              <Descriptions.Item label="Renovação automática">
                {planInfo.renova}
              </Descriptions.Item>
              {planInfo.payment === null ?
                <Descriptions.Item label="Cartão de crédito">
                  <Badge status="error" text="Não cadastrado" />
                </Descriptions.Item>
                :
                <Descriptions.Item label="Cartão de crédito">
                  {`${capitalize(planInfo.payment.card.brand)} (${planInfo.payment.card.last4
                    }), Validade: ${planInfo.payment.card.exp_month}/${planInfo.payment.card.exp_year
                    }`}
                </Descriptions.Item>
              }
              <Descriptions.Item label={planInfo.periodo_label}>
                {planInfo.ends}
              </Descriptions.Item>
              <Descriptions.Item label="Valor Total">
                {`R$ ${planInfo.items.map((item, i) => { return item.quantity * item.amount }).reduce((partialSum, a) => partialSum + a, 0)}`}
              </Descriptions.Item>
            </Descriptions>
            <h3>Produtos</h3>
            <Descriptions
              bordered
              size={"small"}
              style={{ marginBottom: 8, marginTop: 8 }}
            >
              {
                planInfo.items.map((item, i) => {
                  return (
                    <>
                      <Descriptions.Item>
                        {item.title}
                      </Descriptions.Item>
                      <Descriptions.Item label="Quantidade">
                        {item.quantity}
                      </Descriptions.Item>
                      <Descriptions.Item label="Valor">
                        {`R$ ${item.amount}`}
                      </Descriptions.Item>
                    </>
                  );
                })
              }
            </Descriptions>
          </>
        )
      }
    }

    setPlanViewer(
      <>
        <Divider orientation="left">Resumo do plano</Divider>
        {alertVisible ? (
          <Alert
            message={alertMessage}
            type={alertType}
            closable
            afterClose={handleClose}
          />
        ) : null}
        {info}
      </>
    );
  }, [planInfo])

  const routes = [
    {
      path: "index",
      breadcrumbName: "Início",
    },
    {
      path: "first",
      breadcrumbName: "Configurações",
    },
    {
      path: "second",
      breadcrumbName: "Assinatura",
    },
  ];

  return (
    <>
      {loadingInfo && planInfo === null ?
        <Skeleton active /> :
        planViewer
      }
    </>
  );
}
