import React, { useRef, useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Select, Divider, message } from "antd";
import styles from "../BuyerLogin/login.module.css";
import {
  mascaraPhone,
  mascaraCnpj,
  mascaraCpf,
  validaCpfCnpj,
} from "../../functions/validation";
import { axiosClient } from "../../apiClient";

const { Option, OptGroup } = Select;

let states = [
  { nome: "Acre", sigla: "AC" },
  { nome: "Alagoas", sigla: "AL" },
  { nome: "Amapá", sigla: "AP" },
  { nome: "Amazonas", sigla: "AM" },
  { nome: "Bahia", sigla: "BA" },
  { nome: "Ceará", sigla: "CE" },
  { nome: "Distrito Federal", sigla: "DF" },
  { nome: "Espírito Santo", sigla: "ES" },
  { nome: "Goiás", sigla: "GO" },
  { nome: "Maranhão", sigla: "MA" },
  { nome: "Mato Grosso", sigla: "MT" },
  { nome: "Mato Grosso do Sul", sigla: "MS" },
  { nome: "Minas Gerais", sigla: "MG" },
  { nome: "Pará", sigla: "PA" },
  { nome: "Paraíba", sigla: "PB" },
  { nome: "Paraná", sigla: "PR" },
  { nome: "Pernambuco", sigla: "PE" },
  { nome: "Piauí", sigla: "PI" },
  { nome: "Rio de Janeiro", sigla: "RJ" },
  { nome: "Rio Grande do Norte", sigla: "RN" },
  { nome: "Rio Grande do Sul", sigla: "RS" },
  { nome: "Rondônia", sigla: "RO" },
  { nome: "Roraima", sigla: "RR" },
  { nome: "Santa Catarina", sigla: "SC" },
  { nome: "São Paulo", sigla: "SP" },
  { nome: "Sergipe", sigla: "SE" },
  { nome: "Tocantins", sigla: "TO" },
];

export default function BuyerRequestAccess({ parentScreenSetter, sellerData }) {
  const [form] = Form.useForm();
  const [tipoPessoa, setTipoPessoa] = useState("pj");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validarCPF = () => {
    if (validaCpfCnpj(form.getFieldValue("cpf")) == false) {
      form.setFields([
        {
          name: "cpf",
          errors: ["CPF Inválido"],
        },
      ]);
    }
  };

  const validarCNPJ = () => {
    if (validaCpfCnpj(form.getFieldValue("cnpj")) == false) {
      form.setFields([
        {
          name: "cnpj",
          errors: ["CNPJ Inválido"],
        },
      ]);
    }
  };

  const onFinish = () => {
    setLoading(true);
    let values = form.getFieldsValue();
    var is_valid = false;
    if (values.tipo_pessoa == "pf") {
      is_valid = validaCpfCnpj(values.cpf);
    } else {
      is_valid = validaCpfCnpj(values.cnpj);
    }
    if (is_valid == true) {
      values = { ...values, username: sellerData.username };
      axiosClient
        .post("/auth/login/buyer/request/access", values, {
          withCredentials: true,
        })
        .then((response) => {
          if (
            response.data.status == "Error" &&
            response.data.msg == "Access already requested"
          ) {
            message.info(
              "Esses dados já foram enviados para o administrador. Aguarde seu retorno.",
              5
            );
            parentScreenSetter("login");
          } else if (response.data.status == "Error" && response.data.in_use) {
            response.data.in_use.cnpj == true &&
              form.setFields([
                {
                  name: "cnpj",
                  errors: ["CNPJ já cadastrado"],
                },
              ]);
            response.data.in_use.cpf == true &&
              form.setFields([
                {
                  name: "cpf",
                  errors: ["CPF já cadastrado"],
                },
              ]);
            // (response.data.in_use.email == true) &&
            //   form.setFields([
            //     {
            //       name: "email",
            //       errors: ["Email já cadastrado"],
            //     }
            //   ]);
          } else if (response.data.status == "Success") {
            message.success(
              "Requisição de acesso enviada com sucesso. Aguarde retorno do administrador.",
              5
            );
            parentScreenSetter("login");
          }
          setLoading(false);
        });
    } else {
      values.tipo_pessoa == "pf" ? validarCPF() : validarCNPJ();
      message.error("Erro ao enviar os dados. Eles estão corretos?", 5);
      setLoading(false);
    }
  };

  function getAddress() {
    axiosClient
      .post(
        "/shipping/address-from-cep",
        { func: "consultarCep", data: form.getFieldValue("cep") },
        { withCredentials: true }
      )
      .then((response) => {
        let data = response.data.response;
        console.log("data", data);

        data["municipio"] = data["localidade"];
        data["endereco"] = data["logradouro"];
        data["cep"] = data.cep.replace(/\D/g, "");
        delete data.complemento;
        form.setFieldsValue(data);
        onFinish();
      })
      .catch((error) => {
        // console.log(error);
        let data = {};
        data["municipio"] = "";
        data["endereco"] = "";
        data["cep"] = "";
        delete data.complemento;
        form.setFieldsValue(data);
        // message.warning("CEP Inválido");
        form.setFields([
          {
            name: "cep",
            errors: ["CEP Inválido"],
          },
        ]);
      });
  }

  return (
    <Form
      form={form}
      scrollToFirstError={true}
      {...layout}
      name="nest-messages"
      onFinish={getAddress}
      initialValues={{
        ["tipo_pessoa"]: "pj",
      }}
    >
      <Form.Item
        name="tipo_pessoa"
        label="Tipo de Pessoa"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Selecione o tipo de pessoa"
          onChange={(value) => setTipoPessoa(value)}
        >
          <Option value="pj">Pessoa Jurídica</Option>
          <Option value="pf">Pessoa Física</Option>
        </Select>
      </Form.Item>
      {tipoPessoa === "pf" && (
        <>
          <Form.Item
            name={["nome"]}
            label="Nome"
            rules={[
              {
                type: "string",
                required: true,
                max: 100,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["cpf"]}
            label="CPF"
            rules={[
              {
                type: "string",
                max: 14,
                required: true,
              },
            ]}
          >
            <Input
              onBlur={validarCPF}
              maxLength={14}
              onChange={(e) => {
                form.setFieldsValue({
                  cpf: mascaraCpf(e.target.value),
                });
              }}
            />
          </Form.Item>
        </>
      )}

      {tipoPessoa === "pj" && (
        <>
          <Form.Item
            name={["razao_social"]}
            label="Razão Social"
            rules={[
              {
                type: "string",
                max: 100,
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["nome_fantasia"]}
            label="Nome Fantasia"
            rules={[
              {
                type: "string",
                max: 100,
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["cnpj"]}
            label="CNPJ"
            rules={[
              {
                required: true,
                type: "string",
                max: 18,
              },
            ]}
          >
            <Input
              onBlur={validarCNPJ}
              maxLength={18}
              onChange={(e) => {
                form.setFieldsValue({
                  cnpj: mascaraCnpj(e.target.value),
                });
              }}
            />
          </Form.Item>
        </>
      )}
      <Form.Item
        name={["phone"]}
        label="Celular ou telefone (com DDD)"
        rules={[
          {
            type: "string",
            required: true,
            max: 100,
          },
        ]}
      >
        <Input
          onChange={(e) => {
            form.setFieldsValue({
              phone: mascaraPhone(e),
            });
          }}
        />
      </Form.Item>
      <Form.Item
        name={["email"]}
        label="Email"
        rules={[
          {
            type: "email",
            required: true,
            max: 100,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={["cep"]}
        label="CEP"
        rules={[
          { required: true, max: 8 },
          {
            type: "string",
          },
        ]}
        onChange={(e) => {
          let cep_value = e.target.value.replace(/\D/g, "").substring(0, 8);
          form.setFieldsValue({
            cep: cep_value,
          });
        }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={["municipio"]}
        label="Município"
        rules={[
          {
            type: "string",
            max: 100,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={["uf"]}
        label="UF"
        rules={[
          {
            type: "string",
            max: 100,
          },
        ]}
      >
        <Select disabled>
          {states.map(function (state) {
            return (
              <Option value={state.sigla}>
                {state.sigla} - {state.nome}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        hidden
        name={["endereco"]}
        label="Endereço"
        rules={[
          {
            type: "string",
            max: 100,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={["bairro"]}
        label="Bairro"
        rules={[
          {
            type: "string",
            max: 100,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={["numero"]}
        label="Número"
        rules={[
          {
            type: "string",
            max: 100,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        hidden
        name={["complemento"]}
        label="Complemento"
        rules={[
          {
            type: "string",
            max: 100,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className={styles["login-form-button"]}
          loading={loading}
        >
          Solicitar acesso
        </Button>
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          className={styles["login-form-button"]}
          onClick={() => {
            parentScreenSetter("login");
          }}
        >
          Voltar
        </Button>
      </Form.Item>
    </Form>
  );
}
