import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Divider, Skeleton, Button, Checkbox } from "antd";
import { axiosClient } from "../../apiClient";
import { useSelector, useDispatch } from "react-redux";

const { Option } = Select;

export default function SellerOptions() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const sellerData = useSelector((state) => state.sellerData);

  const convertToString = (object) =>
    Object.fromEntries(
      Object.entries(object)
        .map(([k, v]) => [k, v + ''])
    )

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get("/config/default-values", { withCredentials: true })
      .then((response) => {
        setData({
          hide_out_of_stock: response.data.hide_out_of_stock,
          freight_weight_default: String(response.data.freight_weight_default),
          freight_size_default: String(response.data.freight_size_default),
        });
        setLoading(false);
      });
  }, []);

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    axiosClient
      .post("/config/default-values", values, { withCredentials: true })
      .then((response) => {
        setData(response.data);
        setLoading(false);
      });
  };

  function onlyDigits(e, field) {
    let val = parseInt(e.target.value.replace(/\D/g, ""));
    form.setFields([
      {
        name: field,
        value: val ? String(val) : "0",
      },
    ]);
  }

  return (
    <>
      {loading ? (
        <Skeleton active={true} />
      ) : (
        <Form
          form={form}
          scrollToFirstError={true}
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          initialValues={data}
        >
          <Divider orientation="left">Estoque</Divider>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item name="hide_out_of_stock" valuePropName="checked">
                <Checkbox>Esconder produtos sem estoque para os clientes</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Frete</Divider>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="freight_weight_default"
                label="Peso Padrão"
                onChange={(e) => {
                  let val = parseInt(e.target.value.replace(/\D/g, "")) / 100;
                  form.setFields([
                    {
                      name: "freight_weight_default",
                      value: isNaN(val) ? "0" : val,
                    },
                  ]);
                }}
              >
                <Input suffix="kg" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="freight_size_default"
                label="Tamanho Padrão"
                rules={[
                  {
                    type: "string",
                    max: 4,
                  },
                ]}
              >
                <Input onChange={(e) => onlyDigits(e, "freight_size_default")} suffix="cm" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                  >
                    Salvar
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
