function initialState() {
  return { accessRequests: 0 };
}

export function accessRequests(state = initialState(), action) {
  switch (action.type) {
    case "SET_ACCESS_REQUEST":
      return { ...state, accessRequests: action.accessRequests };
    default:
      return state;
  }
}
