function initialState() {
  return({value: []})
}

export function dropdown(state = initialState(), action) {
  switch (action.type) {
    case 'DROPDOWN_VALUE':
      return { ...state, value: action.value}
    default:
      return state
  }
}