import React, { useState, useEffect } from "react";
import styles from "./navbar.module.css";
import logout from "../../actions/logout";
import { axiosClient } from "../../apiClient";
import { useSelector, useDispatch } from "react-redux";
import { message, Typography, Dropdown, Menu, Space, Tag } from "antd";
import { DownOutlined, KeyOutlined, LogoutOutlined, SyncOutlined } from "@ant-design/icons";
import BuyerChangePass from "../BuyerChangePass";

const { Link } = Typography;

let splits = window.location.hostname.split(".");
let domainName = splits.slice(Math.max(splits.length - 3, 1)).join(".");

const Navbar = (props) => {
  const dispatch = useDispatch();
  const [failMessage, setFailMessage] = useState(false);
  const [checked, setChecked] = useState(false);
  const sellerData = useSelector((state) => state.sellerData);
  const minOrderValue = useSelector((state) => state.cart.minOrderValue);
  const cartProducts = useSelector((state) => state.cart.products);
  const logged = useSelector((state) => state.authentication.logged);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    if (cartProducts.length == 0) {
      setFailMessage("Adicione produtos no carrinho para finalizar o pedido");
    } else if (minOrderValue > total) {
      setFailMessage(
        `Adicione mais R$${parseFloat(minOrderValue - total).toFixed(
          2
        )} no carrinho para atingir o pedido mínimo de R$${parseFloat(
          minOrderValue
        ).toFixed(2)}`,
        5
      );
    } else {
      setFailMessage(false);
    }
  }, [cartProducts]);

  function amount(item) {
    return item.product_price_sale
      ? parseFloat(item.product_price_sale * item.quantity).toFixed(2)
      : parseFloat(item.product_price * item.quantity).toFixed(2);
  }

  function sum(prev, next) {
    return parseFloat(prev) + parseFloat(next);
  }

  let total = 0;
  if (cartProducts.length) {
    total = cartProducts.map(amount).reduce(sum);
  }

  const menu = (
    <Menu>
      <Menu.Item
        icon={<KeyOutlined />}
        onClick={() => {
          setDrawerVisible(true);
        }}
        key="2"
      >
        Trocar Senha
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<LogoutOutlined />}
        onClick={() => {
          logout(sellerData.domain ? "/" : sellerData.username);
        }}
      >
        Sair
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <header
        style={{ top: logged === "seller" || logged === "sellerUser" ? 50 : 0 }}
        className={styles["header"]}
      >
        <div className={styles["div-navbar"]}>
          {sellerData.logotipoUrl && (
            <div className={styles["img-container"]}>
              <img
                alt="Logotipo"
                src={sellerData.logotipoUrl}
                onClick={() => {
                  sellerData.domain
                    ? window.location.replace("/")
                    : window.location.replace(sellerData.username);
                }}
                className={styles["img-logo"]}
              ></img>
            </div>
          )}
        </div>
        <input
          className={styles["menu-btn"]}
          onClick={() => setChecked(!checked)}
          checked={checked}
          type="checkbox"
          id="menu-btn"
        />
        <label className={styles["menu-icon"]} htmlFor="menu-btn">
          <span className={styles["navicon"]}></span>
        </label>
        <ul className={styles["menu"]}>
          <li className={styles["li-class"]}>
            <Link
              onClick={() => {
                sellerData.domain &&
                  domainName === sellerData.domain &&
                  logged === "buyer"
                  ? window.location.replace("/")
                  : window.location.replace(sellerData.username);
              }}
            >
              Formulário de Pedido
            </Link>
          </li>
          <li
            className={styles["li-class"]}
            style={{
              display: sellerData.pathName == "checkout" ? "none" : "block",
            }}
          >
            <Link
              onClick={() => {
                props.handleToggle();
                setChecked(false);
              }}
            >
              Pedidos Realizados
            </Link>
          </li>
          <li
            className={styles["li-class"]}
            style={{
              display: sellerData.pathName == "checkout" ? "none" : "block",
            }}
          >
            <Link
              onClick={
                failMessage
                  ? () => message.warning(failMessage)
                  : () => dispatch({ type: "GO_CHECKOUT" })
              }
            >
              Finalizar Pedido
            </Link>
          </li>
          <li
            className={styles["li-class"]}
            style={{
              display: sellerData.pathName == "checkout" ? "none" : "block",
            }}
          >
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              className="ant-dropdown-link"
            >
              <Link onClick={(e) => e.preventDefault()}>
                <Space>
                  Opções
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
          </li>
        </ul>
      </header>
      {drawerVisible === true && (
        <BuyerChangePass
          drawer={drawerVisible}
          drawerSetter={setDrawerVisible}
        />
      )}
    </>
  );
};

export default Navbar;
