import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import styles from "./cadastro.module.css";
import {
  PlusOutlined,
  MinusCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  Space,
  Row,
  Collapse,
  DatePicker,
  Tooltip,
  Transfer,
  Dropdown,
  Menu,
  Skeleton,
  Col,
  Typography,
} from "antd";
import { axiosClient } from "../../apiClient";
import moment from "moment";
import SearchCustomerRules from "../../components/SearchCustomerRules";

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Paragraph, Text } = Typography;
const { TextArea } = Input;

export default function SellerCreateShippingRule() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [emQuemAplica, setEmQuemAplica] = useState({});
  const [buyerAvailableTags, setBuyerAvailableTags] = useState([]);
  const [action, setAction] = useState({});
  const [operador, setOperador] = useState("all");
  const [loading, setLoading] = useState(true);
  const [buyersFormated, setBuyersFormated] = useState([]);

  const editItems = useSelector((state) => state.editItems);

  useEffect(() => {
    axiosClient
      .get("/shipping/get", { withCredentials: true })
      .then((response) => {
        const values = response.data.shippings.map((v) => ({
          ...v,
          key: v.id,
        }));
        setShipping(values);
      });
  }, []);

  const onChange = (newTargetKeys, direction, moveKeys) => {
    setTargetKeys(newTargetKeys);
  };

  useEffect(() => {
    if (editItems.shippingRuleId) {
      axiosClient
        .post(
          "/rules/shipping/get",
          { ruleId: editItems.shippingRuleId },
          { withCredentials: true }
        )
        .then((response) => {
          let ceps_start = "";
          if (response.data.ceps_data.ceps_start.length > 0) {
            ceps_start = response.data.ceps_data.ceps_start.join("*\r\n") + "*";
          }
          let ceps_full = "";
          if (response.data.ceps_data.ceps_full.length > 0) {
            ceps_full = response.data.ceps_data.ceps_full.join("\r\n");
          }
          let ceps_range = "";
          if (response.data.ceps_data.ceps_range.length > 0) {
            ceps_range = response.data.ceps_data.ceps_range
              .map(function (item) {
                return item.join("...");
              })
              .join("\r\n");
          }

          setTargetKeys(response.data.shippings);

          let buyerConditions = [];

          if (ceps_full || ceps_start || ceps_range) {
            buyerConditions = [
              ...buyerConditions,
              {
                emQuemAplica: "buyersCep",
                ceps: [ceps_full, ceps_start, ceps_range]
                  .filter(Boolean)
                  .join("\r\n"),
              },
            ];
          }

          if (response.data.all_buyers) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "allBuyers" },
            ];
          }

          if (response.data.buyers.length > 0) {
            let buyers_formated = response.data.buyers.map((option) =>
              formatBuyer(option)
            );
            setBuyersFormated(buyers_formated);
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "specificBuyers", buyers: buyers_formated },
            ];
          }

          if (response.data.buyers_tags.length > 0) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "buyersTag", tags: response.data.buyers_tags },
            ];
          }

          form.setFieldsValue({
            emQuemAplica: buyerConditions,
          });

          let updateFields = {};
          buyerConditions.map((value, index) => {
            updateFields[index] = value.emQuemAplica;
          });
          setEmQuemAplica(updateFields);

          response.data.start_date &&
            response.data.end_date &&
            form.setFieldsValue({
              date: [
                moment(response.data.start_date),
                moment(response.data.end_date),
              ],
            });

          form.setFieldsValue(response.data);
          setAction(response.data.action);
          setOperador(response.data.buyer_operator);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    axiosClient
      .get("/customer/tag/list", { withCredentials: true })
      .then((out) => {
        setBuyerAvailableTags(out.data.tags);
      });
  }, []);

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = (values) => {
    let data = editItems.shippingRuleId
      ? { ...values, ruleId: editItems.shippingRuleId }
      : values;
    data = values.date
      ? {
          ...data,
          date: [
            values.date[0].format("YYYY-MM-DD"),
            values.date[1].format("YYYY-MM-DD"),
          ],
        }
      : data;
    data = { ...data, buyerConditionType: operador };
    axiosClient
      .post("/rules/shipping/create", data, { withCredentials: true })
      .then((response) => navigate("/admin/entrega/regras"));//dispatch({ type: "LIST_SHIPPING_RULE" }));
  };

  const formatBuyer = (option) => {
    let formated = "";
    if (option.tipo_pessoa === "pf") {
      formated =
        option.id + "-" + option.email + " " + option.nome + " " + option.cpf;
    } else {
      formated =
        option.id +
        " - " +
        option.email +
        " - " +
        option.razao_social +
        " " +
        option.nome_fantasia +
        " " +
        option.cnpj;
    }
    return formated;
  };

  const handleSelectChange = (values) => {
    setBuyersFormated(values);
    form.setFieldsValue({
      emQuemAplica: [{ emQuemAplica: "specificBuyers", buyers: values }],
    });
  };

  const onOperadorChange = (value) => {
    setOperador(value);
  };

  const onEmQuemAplicaChange = (value, Formkey) => {
    var obj = { ...emQuemAplica };
    obj[Formkey] = value;
    setEmQuemAplica(obj);
  };

  function deleteRule() {
    axiosClient
      .post(
        "/rules/shipping/delete",
        { ruleId: editItems.shippingRuleId },
        { withCredentials: true }
      )
      .then((response) => navigate("/admin/entrega/regras"));//dispatch({ type: "LIST_SHIPPING_RULE" }));
  }

  const menu = (
    <Menu onClick={() => deleteRule()}>
      <Menu.Item key="1">Excluir Regra</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: "index",
      breadcrumbName: "Início",
    },
    {
      path: "first",
      breadcrumbName: "Regras",
    },
    {
      path: "second",
      breadcrumbName: editItems.shippingRuleId
        ? "Editar Regra de Entrega"
        : "Nova Regra de Entrega",
    },
  ];

  return (
    <>
      <PageHeader
        title={editItems.shippingRuleId ? "Editar Regra" : "Nova Regra"}
        breadcrumb={{ routes }}
        extra={
          editItems.shippingRuleId && (
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="primary">
                Ações <DownOutlined />
              </Button>
            </Dropdown>
          )
        }
      ></PageHeader>
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          scrollToFirstError={true}
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
        >
          <Divider orientation="left">Dados gerais</Divider>
          <Form.Item
            name={["name"]}
            label="Nome da regra"
            rules={[
              {
                type: "string",
                required: true,
                max: 100,
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
          valuePropName="checked"
          initialValue={true}
          name={["is_active"]}
          label="Status"
        >
          <Switch />
        </Form.Item>

        <Tooltip
          placement="topLeft"
          title="Deixe em branco caso deseje que a regra seja permanente"
        >
          <Form.Item name={["date"]} label="Validade da regra">
            <RangePicker format={"DD/MM/YYYY"} />
          </Form.Item>
        </Tooltip> */}

          <Divider orientation="left">
            <Tooltip
              placement="topLeft"
              title="Escolha para quais clientes a regra será aplicada"
            >
              A quem se aplica
            </Tooltip>
          </Divider>

          {/* <Space>
          Aplicar regra para os clientes em que
          <Select
            value={operador}
            onChange={onOperadorChange}
            style={{ width: "120px" }}
          >
            <Option value="all">todas</Option>
            <Option value="any">qualquer</Option>
          </Select>
          {operador === "all"
            ? "condições forem verdadeiras"
            : "condição for verdadeira"}
        </Space>
        <br />
        <br /> */}

          <Form.List name="emQuemAplica">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Collapse key={key}>
                    <Panel header={"Condição " + parseInt(key + 1)} key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, "emQuemAplica"]}
                        fieldKey={[fieldKey, "emQuemAplica"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione a condição"
                          onChange={(value) => onEmQuemAplicaChange(value, key)}
                          allowClear
                        >
                          <Option value="allBuyers">Todos clientes</Option>
                          <Option value="specificBuyers">
                            Clientes específicos
                          </Option>
                          <Option value="buyersTag">
                            Clientes que contém tag
                          </Option>
                          <Option value="buyersCep">
                            Clientes com CEP específico
                          </Option>
                        </Select>
                      </Form.Item>
                      {emQuemAplica[key] === "buyersCep" && (
                        <>
                          <Text>
                            Defina um CEP por linha. CEPs que contenham curingas
                            (exemplo: 170*) ou intervalos numéricos (exemplo:
                            17000000...17099999) também são suportados.
                          </Text>
                          <Form.Item
                            {...restField}
                            name={[name, "ceps"]}
                            fieldKey={[fieldKey, "ceps"]}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <TextArea rows={4} />
                          </Form.Item>
                        </>
                      )}
                      {emQuemAplica[key] === "buyersTag" && (
                        <Form.Item
                          {...restField}
                          name={[name, "tags"]}
                          fieldKey={[fieldKey, "tags"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione as tags"
                            mode="multiple"
                            allowClear
                          >
                            {buyerAvailableTags.map(function (group, i) {
                              return (
                                <OptGroup
                                  key={"group_" + group.id}
                                  label={group.title}
                                >
                                  {group.tags.map(function (tag, i) {
                                    return (
                                      <Option
                                        key={"tag_" + tag.id}
                                        value={group.id + "_" + tag.id}
                                      >
                                        {tag.title}
                                      </Option>
                                    );
                                  })}
                                </OptGroup>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      )}
                      {emQuemAplica[key] === "specificBuyers" && (
                        <Form.Item
                          {...restField}
                          name={[name, "buyers"]}
                          fieldKey={[fieldKey, "buyers"]}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <SearchCustomerRules
                            handleSelectChange={handleSelectChange}
                            buyersFormated={buyersFormated}
                          />
                        </Form.Item>
                      )}
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Panel>
                  </Collapse>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Condição
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Divider orientation="left">Métodos de Entrega</Divider>
          <Form.Item name={["shippings"]}>
            <Transfer
              showSelectAll={false}
              listStyle={{ width: "45%" }}
              titles={["Não Pemitidos", "Permitidos"]}
              dataSource={shipping}
              targetKeys={targetKeys}
              onChange={onChange}
              render={(item) => item.nome}
              oneWay={true}
            />
          </Form.Item>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    onClick={() => {
                      navigate("/admin/entrega/regras")
                      // dispatch({ type: "LIST_SHIPPING_RULE" });
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col xs={18} sm={8}>
                  <Button block type="primary" htmlType="submit">
                    {editItems.shippingRuleId ? "Atualizar" : "Cadastrar"}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
