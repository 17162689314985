import axios from "axios";
import store from "./store";
import { openDB } from 'idb';

async function createDB() {
  const db = await openDB('nupedido', 1, {
    upgrade(db, oldVersion, newVersion, transaction) {

      switch (oldVersion) {
        case 0:
          if (!db.objectStoreNames.contains('config')) {
            const configObjectStore = db.createObjectStore('config', { keyPath: 'id' });
            configObjectStore.add({ id: 1, status: 'disabled', synced: false })
          }

          if (!db.objectStoreNames.contains('buyers')) {
            const buyersObjectStore = db.createObjectStore('buyers', { keyPath: 'id' });
          }

          if (!db.objectStoreNames.contains('products')) {
            const productsObjectStore = db.createObjectStore('products', { keyPath: 'id' });
          }

          if (!db.objectStoreNames.contains('carts')) {
            const cartsObjectStore = db.createObjectStore('carts', { keyPath: 'id' });
          }

          if (!db.objectStoreNames.contains('orders')) {
            const ordersObjectStore = db.createObjectStore('orders', { keyPath: 'id', autoIncrement: true });
          }
      }
    }
  });
  return db
}

let dataBase = await createDB();

let splits = window.location.hostname.split(".");

export const baseURL = window.location.hostname === "localhost" ? `http://localhost:8081` : "https://api." +
  splits[splits.length - 3] +
  "." +
  splits[splits.length - 2] +
  "." +
  splits[splits.length - 1]


export const axiosClient = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "application/json" },
});

axiosClient.interceptors.request.use(
  async function (config) {
    if (config.withCredentials) {
      const tokens = await dataBase.get("config", 6)
      if (tokens) {
        config.headers.Authorization = 'Bearer ' + tokens.nuApeT
        config.headers.jti = tokens.nuIpeT
      }
    }
    return config;
  },
);

axiosClient.interceptors.response.use(
  response => response,
  async error => {
    const handleLogout = async () => {
      const state = store.getState();
      await dataBase.delete('config', 6);
      if (state.authentication.logged) {
        store.dispatch({ type: "USER_LOGOUT" });
        alert("Sua sessão expirou, faça o login novamente.");
        window.location.reload();
      }
    };

    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        const tokens = await dataBase.get("config", 6);
        try {
          const tokenResponse = await axios.create({
            baseURL: baseURL,
            headers: { "Authorization": "Bearer " + tokens.nuRpeT },
          }).post("/auth/token-refresh");

          const newAccessToken = tokenResponse?.data?.access_token;
          if (newAccessToken) {
            await dataBase.put('config', { id: 6, nuApeT: newAccessToken, nuRpeT: tokens.nuRpeT, nuIpeT: tokens.nuIpeT });

            error.config.headers['Authorization'] = "Bearer " + newAccessToken;
            error.config.headers['Jti'] = tokens.nuIpeT;

            return axiosClient.request(error.config);
          } else {
            await handleLogout();
          }
        } catch {
          await handleLogout();
        }
      } else if (status === 403) {
        await handleLogout();
      }
    }

    return Promise.reject(error);
  }
);
