function initialState() {
  return { value: null, uf: null };
}

export function activeCEP(state = initialState(), action) {
  switch (action.type) {
    case "ACTIVE_CEP":
      return { ...state, value: action.value, uf: action.uf };
    default:
      return state;
  }
}
