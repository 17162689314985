function initialState() {
  return "home";
}

export function adminPage(state = initialState(), action) {
  switch (action.type) {
    case "HOME":
      return "home";
    case "SELLER_CONFIGURATION":
      return "sellerConfiguration";
    case "LIST_CUSTOMER":
      return "listCustomer";
    case "LIST_CUSTOMER_ACCESS":
      return "listCustomerAccess";
    case "CREATE_CUSTOMER":
      return "createCustomer";
    case "LIST_PRODUCT":
      return "listProduct";
    case "LIST_CATEGORY":
      return "listCategory";
    case "CREATE_CATEGORY":
      return "createCategory";
    case "CREATE_PRODUCT":
      return "createProduct";
    case "LIST_ORDER":
      return "listOrder";
    case "LIST_CART":
      return "listCart";
    case "LIST_PAYMENT":
      return "listPayment";
    case "CREATE_PAYMENT":
      return "createPayment";
    case "LIST_SHIPPING":
      return "listShipping";
    case "CREATE_SHIPPING":
      return "createShipping";
    case "CREATE_CART_RULE":
      return "createCartRule";
    case "LIST_PRODUCT_RULE":
      return "listProductRule";
    case "CREATE_PRODUCT_RULE":
      return "createProductRule";
    case "LIST_SHIPPING_RULE":
      return "listShippingRule";
    case "CREATE_SHIPPING_RULE":
      return "createShippingRule";
    case "LIST_PAYMENT_RULE":
      return "listPaymentRule";
    case "CREATE_PAYMENT_RULE":
      return "createPaymentRule";
    case "LIST_CUSTOMER_TAG":
      return "listCustomerTag";
    case "LIST_PRODUCT_TAG":
      return "listProductTag";
    case "LIST_ORDER_DETAILS":
      return "listOrderDetails";
    case "LIST_CART_DETAILS":
      return "listCartDetails";
    case "LIST_CART_RULE":
      return "listCartRule";
    case "SELLER_SUBSCRIPTION":
      return "sellerSubscription";
    case "SELLER_INTEGRATIONS":
      return "sellerIntegrations";

    default:
      return state;
  }
}
