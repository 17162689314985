import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Image, Skeleton, Empty, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  RollbackOutlined
} from "@ant-design/icons";
// import styles from "./listproduct.module.css";
import Search from "../../components/Search";
import { axiosClient } from "../../apiClient";

const { Link } = Typography;

export default function SellerListCartDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const editItems = useSelector((state) => state.editItems);
  const [loading, setLoading] = useState(true);
  const [prodData, setProdData] = useState([]);
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    axiosClient
      .post(
        "/cart/get_cart",
        { cartId: editItems.cartId },
        { withCredentials: true }
      )
      .then((response) => {
        response.data.buyer.tipo_pessoa == "pf"
          ? setCustomer(response.data.buyer.nome)
          : setCustomer(response.data.buyer.razao_social);
        let products = response.data.cart.products;
        let quantities = response.data.cart.quantities;
        let prices = response.data.cart.prices;

        let prodData = products.map((obj, index) => ({
          ...obj,
          key: index,
          sellPrice: prices[index],
          quantity: quantities[index],
        }));
        setProdData(prodData);
        setLoading(false);
        console.log(prodData)
      });
  }, []);

  const columns = [
    {
      title: "Descrição",
      dataIndex: "product_name",
      key: "name",
      render: (text, record) => (
        <>
          <Link> {text} </Link>
        </>
      ),
      ellipsis: true,
      responsive: ["sm"],
      width: "50%",
    },
    {
      title: "Código",
      dataIndex: "product_sku",
      key: "preco",
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Preço",
      dataIndex: "sellPrice",
      key: "sellPrice",
      render: (text) => <>{"R$ " + parseFloat(text).toFixed(2)}</>,
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Total",
      // dataIndex: "sellTotal",
      key: "sellTotal",
      render: (record) => <>{"R$ " + parseFloat(parseFloat(record.sellPrice) * parseInt(record.quantity)).toFixed(2)}</>,
      responsive: ["sm"],
      align: "center",
    },
    {
      title: "Produtos",
      render: (record) => (
        <>
          <Link> {record.product_name} </Link>
          <br />
          <>{"Preço: R$ " + parseFloat(record.sellPrice).toFixed(2)}</>
          <br />
          <>{"Quantidade: " + parseInt(record.quantity)}</>
          <br />
          <>{"Total: R$ " + parseFloat(parseFloat(record.sellPrice) * parseInt(record.quantity)).toFixed(2)}</>
        </>
      ),
      responsive: ["xs"],
    },
  ];

  return (
    <>
      <PageHeader
        title={"Carrinho de " + customer}
        onBack={() => window.history.back()}
      ></PageHeader>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      ></div>
      <Table
        columns={columns}
        expandIconColumnIndex={6}
        style={{ cursor: "pointer" }}
        // rowSelection={"checkbox"}
        expandable={{
          expandedRowRender: (record) =>
            <>
              {
                record.gtin ?
                  "GTIN: " + record.gtin :
                  "GTIN: Não informado"
              }
            </>,
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={loading ? [] : prodData}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
      />
      <Button onClick={() => { navigate("/admin/pedidos/em-aberto") }} >
        <RollbackOutlined /> Voltar
      </Button>
    </>
  );
}
