import React, { useEffect, useState } from "react";
import styles from "./catalogo.module.css";
// import Table from "../../components/Table";
import Table from "../../components/BuyerListProduct";
import Cart from "../../components/Cart";
import BuyerListOrder from "../../components/BuyerListOrder";
import CartButton from "../../components/CartButton";
import SearchProduct from "../../components/Search";
import OurDropdown from "../../components/Dropdown";
import Navbar from "../../components/Navbar";
import SellerBuyerView from "../../components/SellerBuyerView";
import { Drawer, Result, Button } from "antd";
import { Layout, Breadcrumb } from "antd";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

const { Content, Footer } = Layout;

export default function Catalogo() {
  const [visibleCart, setVisibleCart] = useState(false);
  const [visibleOrder, setVisibleOrder] = useState(false);
  const logged = useSelector((state) => state.authentication.logged);
  const syncData = useSelector((state) => state.sync);

  const showCartDrawer = () => {
    setVisibleCart(true);
  };

  const onCartClose = () => {
    setVisibleCart(false);
  };

  const showOrderDrawer = () => {
    setVisibleOrder(true);
  };

  const onOrderClose = () => {
    setVisibleOrder(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has("meus-pedidos") && setVisibleOrder(true);
  }, []);

  return (
    (<Layout style={{ position: "absolute", width: "100%", left: "0px", top: "0px", marginTop: "55px", padding: "0 5px" }}>
      {(logged === "seller" || logged === "sellerUser") && <SellerBuyerView />}
      <Navbar handleToggle={showOrderDrawer} />
      {!syncData.onlineStatus && syncData.syncStatus != "synced" &&
        <Result
          style={{marginTop:"100px"}}
          status="warning"
          title="Você está sem internet e seu aplicativo não está sincronizado"
          subTitle="Conecte seu aparelho na internet e sincronize os dados para usá-lo offline."
        />}
      {(syncData.onlineStatus || (syncData.syncStatus === "synced")) &&
      
        <Content >
          <Breadcrumb style={{ margin: "20px" }}>
            <Breadcrumb.Item href="">
              <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item href="">
              <UserOutlined />
              <span>Formulário de Pedidos</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div className={styles["site-layout-content"]}>
            <Drawer
              title="Seu Pedido"
              placement="right"
              width={window.innerWidth > 375 ? 375 : "100%"}
              onClose={onCartClose}
              open={visibleCart}
            >
              <Cart />
            </Drawer>
            <Drawer
              title="Pedidos Realizados"
              placement="right"
              width={window.innerWidth > 375 ? 375 : "100%"}
              onClose={onOrderClose}
              open={visibleOrder}
            >
              <BuyerListOrder />
            </Drawer>
            <div className={styles["filters"]}>
              <SearchProduct />
              <OurDropdown />
            </div>
            <Table />
            <CartButton handleToggle={showCartDrawer} />
          </div>
        </Content>
      }
      <Footer style={{ textAlign: "center" }}>
        Criado por{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://nupedido.com.br/?source=catalogo"
        >
          Nupedido
        </a>{" "}
        ©2022
      </Footer>
    </Layout>)
  );
}
