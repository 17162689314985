function initialState() {
  return { list: [], loading: true };
}

export function buyerList(state = initialState(), action) {
  switch (action.type) {
    case "SET_BUYER_LOADING":
      return { ...state, loading: action.loading };
    case "SET_BUYER_LIST":
      return { ...state, list: action.list };
    default:
      return state;
  }
}
