import React, { useState, useEffect } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import {
  Tooltip,
  Popconfirm,
  Space,
  Tree,
  Popover,
  Button,
  Alert,
  Input,
  Row,
  Col,
  Divider,
  Typography,
  Skeleton,
  Card,
  Drawer,
} from "antd";
import { axiosClient } from "../../apiClient";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  SmileOutlined,
  DownOutlined,
  PlusOutlined,
  HighlightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styles from "./index.module.css";

const { Title, Paragraph } = Typography;

export default function SellerListCategory() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [parentId, setParentId] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [renaming, setRenaming] = useState(false);

  const renameCategory = (item, newTitle) => {
    setRenaming(item.id);
    axiosClient
      .post(
        "/products/categories/rename",
        { id: item.id, newTitle: newTitle },
        { withCredentials: true }
      )
      .then((response) => {
        getTreeData();
      });
  };

  function getTreeData() {
    axiosClient
      .get("/products/categories/list", { withCredentials: true })
      .then((response) => {
        setTreeData(response.data);
        setLoading(false);
        setRenaming(false);
      });
  }

  function deleteNode(id) {
    axiosClient
      .post(
        "/products/categories/delete",
        { id: id },
        { withCredentials: true }
      )
      .then((response) => {
        getTreeData();
      });
  }

  useEffect(() => {
    getTreeData();
  }, []);

  function createCategory() {
    setLoading(true);
    axiosClient
      .post(
        "/products/categories/create",
        { categoryName: inputValue, parentId: parentId },
        { withCredentials: true }
      )
      .then((response) => {
        setInputValue("");
        getTreeData();
        setDrawerVisible(false);
      });
  }

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onExpand = (keys) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  return (<>
    <PageHeader
      title="Categoria de Produtos"
      onBack={() => window.history.back()}
      extra={
        <Button
          onClick={() => {
            setParentId(null);
            setDrawerVisible(true);
          }}
          type="primary"
        >
          Adicionar Nova Categoria Base
        </Button>
      }
    ></PageHeader>
    {loading ? (
      <Skeleton active />
    ) : (
      <Card>
        {treeData.length === 0 && (
          <Alert
            message="Você ainda não tem categorias cadastradas."
            type="info"
            showIcon
          />
        )}
        <Tree
          style={{ top: "10px" }}
          treeData={treeData}
          onExpand={onExpand}
          showLine={{ showLeafIcon: false }}
          blockNode
          // draggable
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          titleRender={(item) => (
            <Row type="flex" align="middle">
              <Col span={18}>
                {renaming === item.id ? (
                  <Skeleton.Input size="small" style={{ width: 150 }} active />
                ) : (
                  <Paragraph
                    editable={{
                      icon: <HighlightOutlined />,
                      tooltip: "Clique para renomear a categoria",
                      onChange: (newTitle) => renameCategory(item, newTitle),
                    }}
                  >
                    {item.title}
                  </Paragraph>
                )}
              </Col>
              <Col span={3}>
                <Tooltip title="Adicionar sub-categoria">
                  <PlusCircleOutlined
                    onClick={() => {
                      setInputValue("");
                      setParentId(item.key);
                      setDrawerVisible(true);
                    }}
                    style={{
                      display: "block",
                      fontSize: "16px",
                      color: "#08c",
                    }}
                  />
                </Tooltip>
              </Col>
              <Col span={3}>
                <Popconfirm
                  title="Excluir essa categoria?"
                  onConfirm={() => deleteNode(item.key)}
                  okText="Sim"
                  cancelText="Não"
                >
                  <DeleteOutlined
                    style={{
                      display: "block",
                      fontSize: "16px",
                      color: "#08c",
                    }}
                  />
                </Popconfirm>
              </Col>
            </Row>
          )}
        />
      </Card>
    )}
    <Drawer
      title="Título da categoria"
      placement="bottom"
      height={200}
      onClose={onClose}
      open={drawerVisible}
      extra={
        <Button
          type="primary"
          onClick={() => createCategory()}
          loading={loading}
        >
          Salvar
        </Button>
      }
    >
      <Input
        value={inputValue}
        onChange={(value) => setInputValue(value.target.value)}
        placeholder="Examplo: Maquiagem"
        loading={loading}
        onKeyDown={(e) => {
          (e.code === "Enter" || e.code === "NumpadEnter") &&
            createCategory();
        }}
      />
    </Drawer>
  </>);
}
