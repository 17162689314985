import React, { useState, useEffect } from "react";
import {
  Modal,
  List,
  Space,
  Button,
  Row,
  Col,
  Tooltip,
  Skeleton,
  Typography,
} from "antd";
import {
  FilePdfOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { axiosClient } from "../../apiClient";
import BuyerListOrderDetails from "../BuyerListOrderDetails";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../actions/cart";

const { Text, Link } = Typography;

export default function BuyerListOrder() {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [retrievedProducts, setRetrievedProducts] = useState([]);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [modalPDFVisible, setModalPDFVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [orderInfo, setOrderInfo] = useState([]);
  const cartProducts = useSelector((state) => state.cart.products);
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);

  const handleCancel = () => setVisible(false);

  useEffect(() => {
    if (visible) {
      setSelectedProducts([]);
      setSelectedQuantities([]);
      setSelectedKeys([]);
    }
  }, [visible]);

  useEffect(() => {
    if (buyerView || logged === "buyer") {
      axiosClient
        .post(
          "/cart/past_orders",
          { buyerId: buyerView ? buyerView.id : null },
          { withCredentials: true }
        )
        .then((response) => {
          const orders = response.data.orders.map((v) => ({
            ...v,
            key: v.id,
          }));
          orders.sort(function (a, b) {
            var keyA = a.number,
              keyB = b.number;
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          });
          setOrders(orders);
          setLoading(false);
        });
    }
  }, [buyerView, logged]);

  const openPDF = (data) => {
    // const a = document.createElement("a");
    // a.setAttribute('href', URL.createObjectURL(new Blob([data], { type: "application/pdf" })));
    // a.setAttribute('target', '_blank');
    // a.click();
    //window.open(URL.createObjectURL(new Blob([response.data], { type: "application/pdf" })))
    setPdfUrl(
      URL.createObjectURL(new Blob([data], { type: "application/pdf" }))
    );
    setModalPDFVisible(true);
  };

  const downloadPDF = () => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = "_self";
    link.download = "pedido.pdf";
    document.body.append(link);
    link.click();
    link.remove();
  };

  function getCartValues(id) {
    let filteredCart = cartProducts.filter((cartObj) => {
      return cartObj.id == id;
    });
    let qtyInCart = filteredCart[0] ? filteredCart[0].quantity : 0;

    return qtyInCart;
  }

  function filterProduct(record) {
    let filteredProduct = retrievedProducts.filter((retrievedObj) => {
      return record === retrievedObj.id;
    });

    return filteredProduct;
  }

  function sendToCart(allProducts) {
    let searchIds = [];
    if (allProducts) {
      searchIds = orderInfo.order_info.products.map((prod) => prod.id);
    } else {
      searchIds = selectedProducts;
    }
    searchIds.length > 0 &&
      axiosClient
        .post("/products/search", JSON.stringify({
          searchIds: searchIds
        }), { withCredentials: true }).then((response) => {
          setRetrievedProducts(response.data.products);
        });
  }

  useEffect(() => {
    if (retrievedProducts.length > 0) {
      for (var i = 0; i < selectedProducts.length; i++) {
        let qtyInCart = getCartValues(selectedProducts[i]);
        let filteredProduct = filterProduct(selectedProducts[i])[0];
        filteredProduct &&
          dispatch(
            addToCart(filteredProduct, 0, qtyInCart, selectedQuantities[i])
          );
      }
      dispatch({ type: "CART_UPDATED", updated: true });
      dispatch({ type: "CART_RETURNED", returned: false });
      setRetrievedProducts([]);
    }
  }, [retrievedProducts]);

  return (<>
    {loading ? (
      <Skeleton />
    ) : (
      <>
        <List
          itemLayout="vertical"
          dataSource={orders}
          renderItem={(item) => (
            <List.Item
              key={item.number}
              actions={[
                <Link
                  onClick={() => {
                    setOrderInfo(item);
                    setVisible(true);
                  }}
                >
                  Ver detalhes
                </Link>,
                <Tooltip title="Abrir pdf">
                  <Button
                    shape="round"
                    type="circle"
                    onClick={() => {
                      setLoadingPDF(item.id);
                      axiosClient
                        .post(
                          "/order/resumo/pdf",
                          { id: item.id },
                          {
                            withCredentials: true,
                            responseType: "arraybuffer",
                          }
                        )
                        .then((response) => {
                          openPDF(response.data);
                        })
                        .then(() => setLoadingPDF(false));
                    }}
                  >
                    {item.id == loadingPDF ? (
                      <LoadingOutlined />
                    ) : (
                      <FilePdfOutlined />
                    )}
                  </Button>
                </Tooltip>,
              ]}
              extra={
                <Space direction="vertical" align="end">
                  <>
                    {parseFloat(item.total).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </>
                  <>{item.data}</>
                </Space>
              }
            >
              {"Pedido nº " + item.number}
            </List.Item>
          )}
        />
        <Modal
          title="Resumo do Pedido"
          open={visible}
          width={"90%"}
          style={{ top: "5vh", height: "50vh" }}
          onOk={() => console.log("handleOk")}
          onCancel={handleCancel}
          footer={[
            <Row justify="center" gutter={[12, 12]}>
              <Col xs={24} sm={7}>
                <Button block key="back" onClick={handleCancel}>
                  Voltar
                </Button>
              </Col>
              {selectedProducts.length > 0 && (
                <Col xs={24} sm={9}>
                  <Button
                    block
                    onClick={() => {
                      sendToCart(false);
                      setVisible(false);
                    }}
                    type="primary"
                  >
                    Adicionar selecionados
                  </Button>
                </Col>
              )}
              <Col xs={24} sm={8}>
                <Button
                  block
                  onClick={() => {
                    setSelectedProducts(
                      orderInfo.order_info.products.map((prod) => prod.id)
                    );
                    setSelectedQuantities(orderInfo.order_info.quantities);
                    sendToCart(true);
                    setVisible(false);
                  }}
                  type="primary"
                >
                  Adicionar todos
                </Button>
              </Col>
            </Row>,
          ]}
        >
          <BuyerListOrderDetails
            orderInfo={orderInfo}
            selectedProducts={selectedProducts}
            selectedKeys={selectedKeys}
            setSelectedKeys={setSelectedKeys}
            setSelectedProducts={setSelectedProducts}
            setSelectedQuantities={setSelectedQuantities}
          />
        </Modal>
        <Modal
          centered
          closable={true}
          bodyStyle={{ height: "65vh" }}
          width={"90%"}
          title="Pedido"
          footer={
            <Button onClick={() => downloadPDF()} type="primary">
              Download <DownloadOutlined />
            </Button>
          }
          open={modalPDFVisible}
          onOk={() => setModalPDFVisible(false)}
          onCancel={() => setModalPDFVisible(false)}
          cancelText={"Fechar"}
          okButtonProps={{ style: { display: "none" } }}
        >
          <iframe
            style={{ width: "100%", height: "60vh" }}
            src={pdfUrl}
            type="application/pdf"
          />
        </Modal>
      </>
    )}
  </>);
}
