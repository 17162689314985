import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./search.module.css";
import { Input } from "antd";
import { axiosClient } from "../../apiClient";
import { debounce } from "lodash";
import { openDB } from 'idb';

export default function SearchProduct(props) {
  const dispatch = useDispatch();
  const page = useSelector((state) => state.pagination.page);
  const perPage = useSelector((state) => state.pagination.perPage);
  const column = useSelector((state) => state.pagination.column);
  const order = useSelector((state) => state.pagination.order);
  const pageUpdated = useSelector((state) => state.pagination.pageUpdated);
  const searchTermValue = useSelector((state) => state.searchState.searchTerm);
  const dropdownValue = useSelector((state) => state.dropdown.value);
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);
  const syncData = useSelector((state) => state.sync);


  const updateSearch = async (
    page,
    perPage,
    searchTerm,
    column,
    order,
    resetPage,
    buyerView,
    dropdownValue,
    syncStatus
  ) => {
    try {
      dispatch({ type: "SET_LOADING", loading: true });
      console.log("updateSearch", syncStatus)
      if ((logged == "buyer" || logged == "seller" || logged == "sellerUser") && (props.dontUseBuyerView || buyerView)) {
        

        const db = await openDB('nupedido', 1);
        const dbConfig = await db.get('config', 1);

        if (dbConfig.synced || syncStatus == "synced") {
          async function getAllItemsFromStoreWithCursor() {

            const tx = await db.transaction('products', 'readonly');

            // Open a cursor on the designated object store:
            let cursor = await tx.store.openCursor();

            // Iterar sobre os dados usando o cursor
            let results = [];

            let newSearchTerm = searchTerm.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

            // Loop through the cursor
            while (cursor) {
              const { key, value } = cursor;


              const normalizedGtin = value && value.gtin ? value.gtin.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '';
              const normalizedProductName = value && value.product_name ? value.product_name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '';
              const normalizedProductSku = value && value.product_sku ? value.product_sku.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '';


              // Check if the value exists and searchTerm is included in any of the fields
              if (
                value &&
                ((normalizedGtin.includes(newSearchTerm)) ||
                  (normalizedProductName.includes(newSearchTerm)) ||
                  (normalizedProductSku.includes(newSearchTerm)))
              ) {
                // Add the matched value to the results array
                results.push(value);
              }

              // Move to the next row
              cursor = await cursor.continue();
            }

            // Sort the results array alphabetically by product_name
            results.sort((a, b) => (a.product_name || '').localeCompare(b.product_name || ''));

            // Calculate total pages
            const totalPages = Math.ceil(results.length / perPage);

            // Pagination
            const startIndex = (page - 1) * perPage;
            const endIndex = startIndex + perPage;
            const paginatedResults = results.slice(startIndex, endIndex);

            return {
              products: paginatedResults,
              totalPages: totalPages,
              actualPage: page
            }

          }

          let queryResult = await getAllItemsFromStoreWithCursor();

          dispatch({ type: "SET_LIST", list: queryResult.products });
          dispatch({ type: "SET_LOADING", loading: false });
          dispatch({
            type: "SET_PAGE",
            totalPages: queryResult.totalPages,
            actualPage: queryResult.actualPage,
            pageUpdated: true,
          });
        }
        else {

          const results = await axiosClient.post("/products/search", {
            search: searchTerm,
            page: resetPage ? 1 : page,
            perPage: perPage,
            column: column,
            order: order,
            categories: dropdownValue,
            buyerViewId: props.dontUseBuyerView ? null : buyerView.id,
          }, { withCredentials: true });
          dispatch({ type: "SET_LIST", list: results.data.products });
          dispatch({ type: "SET_LOADING", loading: false });
          dispatch({
            type: "SET_PAGE",
            totalPages: results.data.total_pages,
            actualPage: results.data.actual_page,
            pageUpdated: true,
            column: results.data.column,
            order: results.data.order,
          });
        }

        window.scrollTo(0, 0);
      }


    } catch (error) { }
  };

  const updateSearchHandler = useCallback(debounce(updateSearch, 500), []);

  const handleSearchChange = async (e) => {
    dispatch({ type: "SEARCH_TERM", searchTerm: e.target.value });
  };

  useEffect(() => {
    updateSearchHandler(
      page,
      perPage,
      searchTermValue,
      column,
      order,
      true,
      buyerView,
      dropdownValue,
      props.dontUseOffline ? false : syncData.syncStatus
    );
  }, [searchTermValue, dropdownValue, buyerView, perPage, column, order]);

  useEffect(() => {
    !pageUpdated &&
      updateSearchHandler(page, perPage, searchTermValue, column, order, false, buyerView, dropdownValue, props.dontUseOffline ? false : syncData.syncStatus);
  }, [page]);

  return (
    <div className={styles["search-container"]}>
      <Input
        defaultValue={searchTermValue}
        allowClear
        size="large"
        type="text"
        placeholder="Nome, código ou código de barras..."
        onChange={handleSearchChange}
        onFocus={(e) => dispatch({ type: "SET_SEARCH_FOCUS", onFocus: true })}
        onBlur={(e) => dispatch({ type: "SET_SEARCH_FOCUS", onFocus: false })}
      />
    </div>
  );
}
