import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import styles from "./cadastro.module.css";
import {
  PlusOutlined,
  MinusCircleOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  Space,
  Collapse,
  DatePicker,
  Tooltip,
  Dropdown,
  Menu,
  Row,
  Skeleton,
  Col,
} from "antd";
import { axiosClient } from "../../apiClient";
import moment from "moment";
import SearchCustomerRules from "../../components/SearchCustomerRules";
import {
  mascaraCnpj,
  mascaraCpf,
} from "../../functions/validation";

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

export default function SellerCreateCartRule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productAvailableTags, setProductAvailableTags] = useState([]);
  const [buyerAvailableTags, setBuyerAvailableTags] = useState([]);
  const [emQuemAplica, setEmQuemAplica] = useState({});
  const [aondeAplica, setAondeAplica] = useState({});
  const [action, setAction] = useState({});
  const [operador, setOperador] = useState("all");
  const [loading, setLoading] = useState(true);
  const [buyersFormated, setBuyersFormated] = useState([]);

  const editItems = useSelector((state) => state.editItems);

  useEffect(() => {
    axiosClient
      .get("/products/categories/list", { withCredentials: true })
      .then((response) => {
        setProductCategories(response.data);
      })
  },
    []
  );

  useEffect(() => {
    axiosClient
      .get("/products/tag/list", { withCredentials: true })
      .then((out) => {
        setProductAvailableTags(out.data.tags);
      });
  }, []);

  useEffect(() => {
    if (editItems.cartRuleId) {
      axiosClient
        .post(
          "/rules/cart/get",
          { ruleId: editItems.cartRuleId },
          { withCredentials: true }
        )
        .then((response) => {
          let buyerConditions = [];

          if (response.data.all_buyers) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "allBuyers" },
            ];
          }

          if (response.data.buyers.length > 0) {
            let buyers_formated = response.data.buyers.map((option) =>
              formatBuyer(option)
            );
            setBuyersFormated(buyers_formated);
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "specificBuyers", buyers: buyers_formated },
            ];
          }

          if (response.data.buyers_tags.length > 0) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: "buyersTag", tags: response.data.buyers_tags },
            ];
          }

          form.setFieldsValue({
            emQuemAplica: buyerConditions,
          });

          let updateFields = {};
          buyerConditions.map((value, index) => {
            updateFields[index] = value.emQuemAplica;
          });
          setEmQuemAplica(updateFields);

          response.data.start_date &&
            response.data.end_date &&
            form.setFieldsValue({
              date: [
                moment(response.data.start_date),
                moment(response.data.end_date),
              ],
            });

          form.setFieldsValue(response.data);
          setAction(response.data.action);
          setOperador(response.data.buyer_operator);

          let val = response.data.value;
          form.setFields([
            {
              name: "value",
              value: isNaN(val)
                ? "R$ 0,00"
                : val.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }),
            },
          ]);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    axiosClient
      .get("/customer/tag/list", { withCredentials: true })
      .then((out) => {
        setBuyerAvailableTags(out.data.tags);
      });
  }, []);

  useEffect(() => {
    axiosClient
      .post(`/products/search`, {
        search: "",
        page: "",
        perPage: -1,
      }, { withCredentials: true }).then((out) => setProducts(out.data.products));
  }, []);

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const formatBuyer = (option) => {
    let formated = "";
    if (option.tipo_pessoa === "pf") {
      formated =
        option.id + "-" + option.email + " " + option.nome + " " + option.cpf;
    } else {
      formated =
        option.id +
        " - " +
        option.email +
        " - " +
        option.razao_social +
        " " +
        option.nome_fantasia +
        " " +
        option.cnpj;
    }
    return formated;
  };

  const handleSelectChange = (values) => {
    setBuyersFormated(values);
    form.setFieldsValue({
      emQuemAplica: [{ emQuemAplica: "specificBuyers", buyers: values }],
    });
  };

  const formatProduct = (option) => {
    let formated =
      option.id +
      " - " +
      option.product_sku +
      " " +
      option.product_name +
      " " +
      option.gtin;
    return formated;
  };

  const onFinish = (values) => {
    let data = editItems.cartRuleId
      ? { ...values, ruleId: editItems.cartRuleId }
      : values;
    data = values.date
      ? {
        ...data,
        date: [
          values.date[0].format("YYYY-MM-DD"),
          values.date[1].format("YYYY-MM-DD"),
        ],
      }
      : data;
    data = { ...data, buyerConditionType: operador };
    axiosClient
      .post("/rules/cart/create", data, { withCredentials: true })
      .then((response) => navigate("/admin/catalogo/regras-carrinho"));//dispatch({ type: "LIST_CART_RULE" }));
  };

  const onEmQuemAplicaChange = (value, Formkey) => {
    var obj = { ...emQuemAplica };
    obj[Formkey] = value;
    setEmQuemAplica(obj);
  };

  const onAondeAplicaChange = (value, Formkey) => {
    var obj = { ...aondeAplica };
    obj[Formkey] = value;
    setAondeAplica(obj);
  };

  const onOperadorChange = (value) => {
    setOperador(value);
  };

  function deleteRule() {
    axiosClient
      .post(
        "/rules/cart/delete",
        { ruleId: editItems.cartRuleId },
        { withCredentials: true }
      )
      .then((response) => navigate("/admin/catalogo/regras-carrinho")); //dispatch({ type: "LIST_CART_RULE" }));
  }

  const onActionChange = (value) => {
    setAction(value);
  };

  const menu = (
    <Menu onClick={() => deleteRule()}>
      <Menu.Item key="1">Excluir Regra</Menu.Item>
    </Menu>
  );

  return (<>
    <PageHeader
      title={editItems.cartRuleId ? "Editar Regra" : "Nova Regra"}
      onBack={() => window.history.back()}
      extra={
        editItems.cartRuleId && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="primary">
              Ações <DownOutlined />
            </Button>
          </Dropdown>
        )
      }
    ></PageHeader>
    {loading ? (
      <Skeleton active />
    ) : (
      <Form
        form={form}
        scrollToFirstError={true}
        {...layout}
        name="nest-messages"
        scrollToFirstError
        onFinish={onFinish}
      >
        <Divider orientation="left">Dados gerais</Divider>
        <Form.Item
          name={["name"]}
          label="Nome da regra"
          rules={[
            {
              type: "string",
              required: true,
              max: 100,
            },
          ]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item
        valuePropName="checked"
        initialValue={true}
        name={["is_active"]}
        label="Status"
      >
        <Switch />
      </Form.Item> */}

        {/* <Tooltip
        placement="topLeft"
        title="Deixe em branco caso deseje que a regra seja permanente"
      >
        <Form.Item name={["date"]} label="Validade da regra">
          <RangePicker format={"DD/MM/YYYY"} />
        </Form.Item>
      </Tooltip> */}

        <Divider orientation="left">
          <Tooltip
            placement="topLeft"
            title="Escolha para quais clientes a regra será aplicada"
          >
            A quem se aplica
          </Tooltip>
        </Divider>

        {/* <Space>
        Aplicar regra para os clientes em que
        <Select
          value={operador}
          onChange={onOperadorChange}
          style={{ width: "120px" }}
        >
          <Option value="all">todas</Option>
          <Option value="any">qualquer</Option>
        </Select>
        {operador === "all"
          ? "condições forem verdadeiras"
          : "condição for verdadeira"}
      </Space>
      <br />
      <br /> */}

        <Form.List name="emQuemAplica">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Collapse key={key}>
                  <Panel header={"Condição " + parseInt(key + 1)} key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, "emQuemAplica"]}
                      fieldKey={[fieldKey, "emQuemAplica"]}
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Selecione a condição"
                        onChange={(value) => onEmQuemAplicaChange(value, key)}
                        allowClear
                      >
                        <Option value="allBuyers">Todos clientes</Option>
                        <Option value="specificBuyers">
                          Clientes específicos
                        </Option>
                        <Option value="buyersTag">
                          Clientes que contém tag
                        </Option>
                        {/* <Option value="clientesEstados">
                      Clientes dos estados
                    </Option> */}
                      </Select>
                    </Form.Item>
                    {emQuemAplica[key] === "buyersTag" && (
                      <Form.Item
                        {...restField}
                        name={[name, "tags"]}
                        fieldKey={[fieldKey, "tags"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione as tags"
                          mode="multiple"
                          allowClear
                        >
                          {buyerAvailableTags.map(function (group, i) {
                            return (
                              <OptGroup
                                key={"group_" + group.id}
                                label={group.title}
                              >
                                {group.tags.map(function (tag, i) {
                                  return (
                                    <Option
                                      key={"tag_" + tag.id}
                                      value={group.id + "_" + tag.id}
                                    >
                                      {tag.title}
                                    </Option>
                                  );
                                })}
                              </OptGroup>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    )}
                    {emQuemAplica[key] === "specificBuyers" && (
                      <Form.Item
                        {...restField}
                        name={[name, "buyers"]}
                        fieldKey={[fieldKey, "buyers"]}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <SearchCustomerRules
                          handleSelectChange={handleSelectChange}
                          buyersFormated={buyersFormated}
                        />
                      </Form.Item>
                    )}
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Panel>
                </Collapse>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Adicionar Condição
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider orientation="left">Ação</Divider>
        <Form.Item
          name="action"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Selecione a ação"
            onChange={onActionChange}
            allowClear
          >
            <Option value="minimumOrder">Pedido Mínimo</Option>
            <Option value="freeShipping">Frete Grátis</Option>
          </Select>
        </Form.Item>
        {action === "minimumOrder" && (
          <Form.Item
            name="value"
            rules={[
              {
                required: true,
              },
            ]}
            onChange={(e) => {
              let val = parseInt(e.target.value.replace(/\D/g, "")) / 100;
              form.setFields([
                {
                  name: "value",
                  value: isNaN(val)
                    ? "R$ 0,00"
                    : val.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }),
                },
              ]);
            }}
          >
            <Input placeholder="Valor do pedido mínimo" />
          </Form.Item>
        )}
        {action === "freeShipping" && (
          <Form.Item
            name="value"
            rules={[
              {
                required: true,
              },
            ]}
            onChange={(e) => {
              let val = parseInt(e.target.value.replace(/\D/g, "")) / 100;
              form.setFields([
                {
                  name: "value",
                  value: isNaN(val)
                    ? "R$ 0,00"
                    : val.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }),
                },
              ]);
            }}
          >
            <Input placeholder="Valor do carrinho para ter frete grátis" />
          </Form.Item>
        )}
        <Form.Item>
          <div style={{ marginTop: 30 }}>
            <Row justify="center" gutter={[30, 12]}>
              <Col xs={18} sm={8}>
                <Button
                  block
                  onClick={() => {
                    navigate("/admin/catalogo/regras-carrinho");
                    // dispatch({ type: "LIST_CART_RULE" });
                  }}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={18} sm={8}>
                <Button block type="primary" htmlType="submit">
                  {editItems.cartRuleId ? "Atualizar" : "Cadastrar"}
                </Button>
              </Col>
            </Row>
          </div>
        </Form.Item>
      </Form>
    )}
  </>);
}
