import React, { useState, useEffect, useLayoutEffect } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Skeleton, Card, Alert, Typography } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../../apiClient";
import { mascaraCnpj, mascaraCpf } from "../../functions/validation";

const { Link } = Typography;

export default function SellerListUser(props) {
  const dispatch = useDispatch();
  const [listUsers, setListUsers] = useState([]);
  const [repOptions, setRepOptions] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosClient
      .get("/auth/list/seller-users-credit", { withCredentials: true })
      .then((response) => {
        console.log(response.data)
        setRepOptions(response.data.products)
        setListUsers(
          response.data.seller_users_list.map((obj, index) => ({ ...obj, key: index }))
        );
        setLoading(false);
      });
  }, []);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  const smallColumns = [
    {
      title: "Descrição",
      render: (record) => (
        <>
          <>
            <Link>{record.nome}</Link>
          </>
          {record.cpf && (
            <>
              <br /> {mascaraCpf(record.cpf)}
            </>
          )}
          {record.email && (
            <>
              <br /> {record.email}
            </>
          )}
        </>
      ),
      ellipsis: true,
    },
  ];

  const bigColumns = [
    {
      title: "Nome",
      dataIndex: "nome",
      width: "25%",
      render: (text, record) => (
        <>
          <Link>{record.nome}</Link>
        </>
      ),
      ellipsis: true,
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "documento",
      render: (text, record) => <>{record.cpf && mascaraCpf(record.cpf)}</>,
    },
    {
      title: "Email",
      render: (text, record) => <>{record.email && record.email}</>,
    },
  ];

  function checkColumns() {
    if (width < 1100) {
      return smallColumns;
    } else {
      return bigColumns;
    }
  }

  let columns = checkColumns();

  return (
    <>
      {loading && <Skeleton active={true} />}
      {!loading && (
        (!loading && Object.keys(repOptions).length === 0) ?
          <Card>
            <Alert
              message="Você não possui representantes no seu plano. Contate a administração caso queira contratar esse serviço."
              type="info"
              showIcon
            />
          </Card>
          :
          Object.keys(repOptions).map((opt, i) => {
            return (
              <>
                <PageHeader
                  key={'pg' + i}

                  title={`${repOptions[opt]['name']}`}
                  subTitle={`${listUsers.filter(i => i.type.includes(opt)).length} de ${repOptions[opt]['quantity']}`}
                  extra={
                    <>
                      <Button
                        disabled={listUsers.filter(i => i.type.includes(opt)).length >= repOptions[opt]['quantity'] ? true : false}
                        key={'bt' + opt + i}
                        onClick={() => {
                          props.setUserType(opt)
                          props.setUserPage("createUser");
                        }}
                        type="primary"
                      >
                        Adicionar
                      </Button>
                    </>
                  }
                ></PageHeader>
                <Table
                  key={'tab' + opt + i}
                  columns={columns}
                  dataSource={listUsers.filter(i => i.type.includes(opt))}
                  style={{ cursor: !loading ? "pointer" : "auto" }}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        props.setUserType(opt)
                        props.setUserPage("createUser");
                        dispatch({ type: "USER_EDIT", userId: record.id });
                      },
                    };
                  }}
                />
              </>
            )
          })
      )}
    </>
  );
}
