import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  APIProvider,
  Map,
  useMap,
  AdvancedMarker,
  Marker
} from '@vis.gl/react-google-maps';
import { GoogleMapsOverlay } from '@deck.gl/google-maps';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

function generateRandomTrees(numItems) {
  const trees = [];

  // Coordenadas aproximadas das principais cidades do interior de São Paulo
  const cities = [
    { name: "Campinas", lat: -22.9056, lng: -47.0608 },
    { name: "Ribeirão Preto", lat: -21.1704, lng: -47.8103 },
    { name: "Bauru", lat: -22.3149, lng: -49.0606 },
    { name: "Sorocaba", lat: -23.5505, lng: -47.4578 },
    { name: "São José do Rio Preto", lat: -20.8118, lng: -49.3759 },
    { name: "Araraquara", lat: -21.7845, lng: -48.1784 },
    { name: "Presidente Prudente", lat: -22.1254, lng: -51.3880 },
    { name: "Marília", lat: -22.2130, lng: -49.9454 },
    { name: "Piracicaba", lat: -22.7252, lng: -47.6475 },
    { name: "Limeira", lat: -22.5660, lng: -47.4016 },
    { name: "Jundiaí", lat: -23.1857, lng: -46.8978 },
    { name: "São Carlos", lat: -22.0020, lng: -47.8911 },
    { name: "Franca", lat: -20.5386, lng: -47.4007 },
    { name: "Itu", lat: -23.2541, lng: -47.2926 },
    { name: "Jaú", lat: -22.2936, lng: -48.5597 },
    { name: "Americana", lat: -22.7370, lng: -47.3331 },
    { name: "Pirassununga", lat: -21.9969, lng: -47.4262 },
    { name: "Lorena", lat: -22.7334, lng: -45.1197 },
    { name: "Rio Claro", lat: -22.4148, lng: -47.5616 },
    { name: "Avaré", lat: -23.1064, lng: -48.9258 },
    { name: "Barretos", lat: -20.5531, lng: -48.5691 },
    { name: "Botucatu", lat: -22.8852, lng: -48.4437 },
    { name: "Ourinhos", lat: -22.9782, lng: -49.8697 },
    { name: "Assis", lat: -22.6602, lng: -50.4183 },
    { name: "Catanduva", lat: -21.1372, lng: -48.9722 },
  ]

  for (let i = 1; i <= numItems; i++) {
    // Seleciona uma cidade aleatória
    const randomCity = cities[Math.floor(Math.random() * cities.length)];

    // Gera uma pequena variação nas coordenadas
    const latVariation = (Math.random()) * 0.01; // Variação de até 0.1 graus
    const lngVariation = (Math.random()) * 0.01; // Variação de até 0.1 graus

    const lat = randomCity.lat + latVariation;
    const lng = randomCity.lng + lngVariation;
    const name = `Tree ${i}`;

    trees.push({
      id: i,
      lat: lat,
      lng: lng,
      key: JSON.stringify({ name, lat, lng }),
    });
  }

  return trees;
}

const Markers = ({ points }) => {
  const map = useMap();
  const [markers, setMarkers] = useState({});
  const clusterer = useRef(null);

  // Initialize MarkerClusterer
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  // Update markers
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker, key) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  return (
    <>
      {points.map((point) => (
        // <AdvancedMarker
        //   position={point}
        //   key={point.key}
        //   ref={(marker) => setMarkerRef(marker, point.key)}
        // >
        //   <span className="tree">🌳</span>
        // </AdvancedMarker>
        <Marker position={point}
          key={point.key}
          ref={(marker) => setMarkerRef(marker, point.key)} />
      ))}
    </>
  );
};

const DATA = [
  { lng: -47.4131, lat: -23.6814, weight: 0.5 },
  { lng: -47.7636, lat: -22.9071, weight: 0.7 },
  { lng: -48.5675, lat: -22.6025, weight: 0.3 },
  // Adicione mais dados aqui
];

const layers = [
  new HeatmapLayer({
    id: 'heatmapLayer',
    data: DATA,
    getPosition: d => [d.lng, d.lat],
    getWeight: d => d.weight,
    aggregation: 'SUM'
  })
];

function MapContainer(props) {
  const [trees, setTrees] = useState([]);


  useEffect(() => {
    setTrees(generateRandomTrees(props.qtyOrders))
    console.log("props.qtyOrders", props.qtyOrders)
  }, [props.qtyOrders])


  const DeckGlOverlay = ({ layers }) => {
    const deck = useMemo(() => new GoogleMapsOverlay({ interleaved: true }), []);

    const map = useMap();
    useEffect(() => deck.setMap(map), [map]);
    useEffect(() => deck.setProps({ layers }), [layers]);

    // no dom rendered by this component
    return null;
  };



  return (
    <APIProvider apiKey={'AIzaSyBmJ18EIAVAQ84r7neVgzhflgt3GDtTac0'}>
      <Map
        style={{ width: "100%", height: "300px" }}
        mapId={'bf51a910020fa25a'}
        defaultCenter={{ lat: -20.8118, lng: -49.3759 }}
        defaultZoom={2}
        gestureHandling={'greedy'}
        disableDefaultUI>
        <Markers points={trees} />
        <DeckGlOverlay layers={layers} />
      </Map>
    </APIProvider>

  );
}

export default MapContainer;