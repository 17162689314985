import React, {
  useEffect,
  useState,
  useLayoutEffect,
} from "react";
import { PageHeader } from '@ant-design/pro-layout';
import {
  Select,
  Divider,
  TreeSelect,
  Form,
  Input,
  InputNumber,
  Button,
  Modal,
  Row,
  Menu,
  Dropdown,
  Skeleton,
  message,
  Col,
  Tabs,
  Upload,
  Badge,
} from "antd";
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./cadastro.module.css";
import { PlusOutlined, DownOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { axiosClient } from "../../apiClient";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import SellerCreateProductVariations from "../../components/SellerCreateProductVariations";

const DraggableUploadListItem = ({ originNode, file }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: file.uid,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      // prevent preview event when drag end
      className={isDragging ? styles["is-dragging"] : ''}
      {...attributes}
      {...listeners}
    >
      {/* hide error tooltip when dragging */}
      {file.status === 'error' && isDragging ? originNode.props.children : originNode}
    </div>
  );
};


const { Option, OptGroup } = Select;
const { TabPane } = Tabs;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function SellerCreateProduct() {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [width, height] = useWindowSize();
  const [treeData, setTreeData] = useState([]);
  const editItems = useSelector((state) => state.editItems);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [packageType, setPackageType] = useState("");
  const [loading, setLoading] = useState(false);
  const [productState, setProduct] = useState(null);
  const [productType, setProductType] = useState("simples");
  const [variationTabData, setVariationTabData] = useState(null);
  const [variationClasses, setVariationClasses] = useState(null);
  const [prodSKU, setProdSKU] = useState(null);
  const [prodName, setProdName] = useState(null);
  const [prodPrice, setProdPrice] = useState("R$ 0,00");
  const [prodPricePromo, setProdPricePromo] = useState("R$ 0,00");
  const [prodId, setProdId] = useState(null);
  const [fatherId, setFatherId] = useState(null);

  useEffect(() => {
    axiosClient
      .get("/products/tag/list", { withCredentials: true })
      .then((out) => {
        setAvailableTags(out.data.tags);
      });
  }, []);

  const [uploadData, setUploadData] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  });

  useEffect(() => {
    axiosClient
      .get("/products/categories/list", { withCredentials: true })
      .then((response) => {
        setTreeData(response.data);
      });
  }, []);

  useEffect(() => {
    if (editItems.productId) {
      setLoading(true);
      if (Array.isArray(editItems.productId)) {
        setProdId(editItems.productId[0]);
        setFatherId(editItems.productId[1]);
      } else {
        setProdId(editItems.productId);
      }
    } else {
      form.setFieldsValue({
        product_type: productType,
      });
      setProduct({});
    }
  }, []);

  useEffect(() => {
    if (prodId) {
      axiosClient
        .post("/products/get", { productId: prodId }, { withCredentials: true })
        .then((response) => {
          delete response.data["tags"];
          var fileList = [];
          for (var i in response.data.product_image) {
            response.data.product_image[i].name.includes(
              `products/${response.data.id}/`
            ) && fileList.push(response.data.product_image[i]);
          }
          setUploadData({
            ...uploadData,
            fileList: fileList,
          });
          response.data.package_type &&
            setPackageType(response.data.package_type);
          setSelectedTags(response.data.tags_ids);
          form.setFieldsValue({
            ...response.data,
            length: response.data.length ? String(response.data.length) : null,
            width: response.data.width ? String(response.data.width) : null,
            height: response.data.height ? String(response.data.height) : null,
            diameter: response.data.diameter
              ? String(response.data.diameter)
              : "",
          });
          updatePriceField("product_price", response.data.product_price);
          updatePriceField(
            "product_price_sale",
            response.data.product_price_sale
              ? response.data.product_price_sale
              : "R$ 0,00"
          );
          if (response.data.variation_type === "P") {
            form.setFieldsValue({
              product_type: "variations",
            });
            setProductType("variations");
          } else if (response.data.variation_type === "V") {
            setProductType("is_variation");
          } else {
            form.setFieldsValue({
              product_type: "simples",
            });
            setProductType("simples");
          }

          setProdSKU(response.data.product_sku);
          setProdName(response.data.product_name);
          setProduct(response.data);
          setLoading(false);
        });
    }
  }, [prodId]);

  useEffect(() => { form.setFieldsValue({ tags: selectedTags }) }, [availableTags, selectedTags]);

  function deleteProduct() {
    setDeleting(true);
    axiosClient
      .post(
        "/products/delete",
        { productId: prodId },
        { withCredentials: true }
      )
      .then((response) => {
        response.data.status == "Success"
          ? message.success("Produto deletado com sucesso.", 4)
          : message.error("Erro ao deletar produto", 4);
        navigate(`/admin/produtos/lista`)
        // dispatch({ type: "LIST_PRODUCT" });
        setDeleting(false);
      });
  }

  function onlyDigits(e, field) {
    let val = parseInt(e.target.value.replace(/\D/g, ""));
    form.setFields([
      {
        name: field,
        value: val ? String(val) : "0",
      },
    ]);
  }

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = async (values) => {
    setSending(true);
    var addProd = false;
    let apiUrl = prodId ? "/products/update" : "/products/create";
    let data = prodId ? { ...values, productId: prodId } : values;
    let fileList = uploadData.fileList;

    for (var i = 0; i < fileList.length; i++) {
      if (fileList[i]["url"] === undefined) {
        fileList[i]["base64"] = await getBase64(fileList[i].originFileObj);
      }
    }
    if (productType === "simples") {
      data = {
        ...data,
        variation_type: "N",
      };
      addProd = true;
    } else if (productType === "is_variation") {
      data = {
        ...data,
        variation_type: "V",
      };
      addProd = true;
    } else if (productType === "variations") {
      data = {
        ...data,
        variation_type: "P",
      };
      if (variationTabData) {
        addProd = true;
        var tabData = variationTabData.map(function (value, i) {
          var tData = {
            ...value.product,
            variations: value.vartions_with_class,
            variation_type: "V",
            product_name: data.product_name ? data.product_name : null,
            category_id: data.category_id ? data.category_id : null,
            diameter: data.diameter ? data.diameter : null,
            length: data.length ? data.length : null,
            widht: data.widht ? data.widht : null,
            height: data.height ? data.height : null,
            // weight: (data.weight) ? (data.weight) : null,
            diameter: data.diameter ? data.diameter : null,
            package_type: data.package_type ? data.package_type : null,
            tags: data.tags ? data.tags : [],
            fileList: fileList,
          };
          return tData;
        });
        var variationClassesFilter = Object.fromEntries(
          Object.entries(variationClasses).filter(([k, v]) => v.length > 0)
        );
      } else {
        addProd = false;
        message.error(
          "Crie as variações e preencha a tabela na aba de 'Variações'",
          5
        );
        setSending(false);
      }
    }
    data = {
      ...data,
      fileList: fileList,
      variations: { ...variationClassesFilter },
      variation_childs: tabData ? [...tabData] : [],
      // product_stock: 0,
    };
    if (addProd == true) {
      axiosClient
        .post(apiUrl, data, { withCredentials: true })
        .then((response) => {
          if (response.data.status === "Success") {
            prodId
              ? message.success("Dados salvos com sucesso", 4)
              : message.success("Produto adicionado com sucesso", 4);
            navigate(`/admin/produtos/lista`)
            // dispatch({ type: "LIST_PRODUCT" });
          } else {
            message.error(response.data.msg, 4);
          }
          setSending(false);
        });
      // setSending(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Adicionar</div>
    </div>
  );

  const handleCancel = () =>
    setUploadData({ ...uploadData, previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setUploadData({
      ...uploadData,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: "",
    });
  };

  const handlePictureChange = ({ fileList }) => {
    setUploadData({ ...uploadData, fileList });
  };

  const handlePackageChange = (value) => {
    setPackageType(value);
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function updatePriceField(field, val) {
    form.setFields([
      {
        name: field,
        value: isNaN(val)
          ? "R$ 0,00"
          : val.toLocaleString("pt-BR", { style: "currency", currency: "BRL" }),
      },
    ]);
  }

  function handleChange(values) {
    if (values.length > 1) {
      let [groupId, tagId] = values[values.length - 1].split("_");

      for (let i = 0; i < values.length - 1; i++) {
        if (values[i].startsWith(groupId)) {
          values.splice(i, 1);
        }
      }
    }
    form.setFieldsValue({ tags: values });
  }

  const menu = (
    <Menu>
      {
        productType &&
        productType !== "is_variation" &&
        <Menu.Item onClick={() => deleteProduct()} key="1">Excluir Produto</Menu.Item>
      }
    </Menu>
  );

  function handleProductTypeChange(value) {
    form.setFieldsValue({
      product_type: value,
    });
    setProductType(value);
  }

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const onDragEnd = ({ active, over }) => {
    console.log("active, over", active, over)
    if (active.id !== over?.id) {
      const activeIndex = uploadData.fileList.findIndex((i) => i.uid === active.id);
      const overIndex = uploadData.fileList.findIndex((i) => i.uid === over?.id);
      setUploadData({
        ...uploadData,
        fileList: arrayMove(uploadData.fileList, activeIndex, overIndex)
      });
    }
  };


  return (<>
    <PageHeader
      title={
        prodId
          ? productType === "is_variation"
            ? "Editar Variação"
            : "Editar Produto"
          : "Novo Produto"
      }
      onBack={() => window.history.back()}
      extra={
        prodId && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="primary">
              {deleting ? (
                <>
                  <LoadingOutlined /> Ações <DownOutlined />
                </>
              ) : (
                <>
                  Ações <DownOutlined />
                </>
              )}
            </Button>
          </Dropdown>
        )
      }
    ></PageHeader>
    {loading ? (
      <Skeleton active />
    ) : (
      <Form
        form={form}
        scrollToFirstError={true}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        initialValues={{
          ["product_price"]: "R$ 0,00",
          ["product_price_sale"]: "R$ 0,00",
        }}
      >
        <Tabs defaultActiveKey="1" onChange={() => { }}>
          <TabPane tab="Dados Gerais" key="1">
            <Row gutter={16}>
              <Col xs={24} sm={18}>
                <Form.Item
                  name={["product_name"]}
                  label="Nome"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      max: 100,
                    },
                  ]}
                >
                  {productType === "is_variation" ? (
                    <Input disabled />
                  ) : (
                    <Input
                      onChange={(e) => {
                        setProdName(e.target.value);
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item name={["product_type"]} label="Tipo">
                  {productType === "is_variation" ? (
                    <Select
                      block
                      defaultValue="simples"
                      onChange={handleProductTypeChange}
                    >
                      <Option value="simples">Simples</Option>
                    </Select>
                  ) : (
                    <Select
                      block
                      defaultValue="simples"
                      onChange={handleProductTypeChange}
                    >
                      <Option value="simples">Simples</Option>
                      <Option value="variations">Com Variações</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["product_sku"]}
                  label="Código SKU"
                  rules={[
                    {
                      type: "string",
                      required: true,
                    },
                  ]}
                >
                  <Input onChange={(e) => setProdSKU(e.target.value)} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["gtin"]}
                  label="Código de Barras"
                  onChange={(e) => onlyDigits(e, "gtin")}
                  rules={[
                    {
                      type: "string",
                      required: false,
                      max: 20,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={8}>
                <Form.Item
                  name={["product_price"]}
                  label="Preço"
                  onChange={(e) => {
                    let val =
                      parseInt(e.target.value.replace(/\D/g, "")) / 100;
                    updatePriceField("product_price", val);
                    setProdPrice(val);
                  }}
                  rules={[
                    {
                      type: "string",
                      required: true,
                      max: 20,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  name={["product_price_sale"]}
                  label="Preço Promocional"
                  onChange={(e) => {
                    let val =
                      parseInt(e.target.value.replace(/\D/g, "")) / 100;
                    updatePriceField("product_price_sale", val);
                    setProdPricePromo(val);
                  }}
                  rules={[
                    {
                      type: "string",
                      max: 20,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              {productState && productType != "variations" && (
                <Col xs={24} sm={8}>
                  <Form.Item
                    name={["product_stock"]}
                    label="Quantidade em Estoque"
                    onChange={(e) => {
                      let val = e.target.value.replace(/\D/g, "");
                      form.setFieldsValue({
                        stock: isNaN(val) ? 0 : parseInt(val),
                      });
                    }}
                    rules={[
                      {
                        type: "number",
                        required: true,
                        max: 10e6,
                      },
                    ]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Divider orientation="left">Categoria</Divider>
            <Form.Item name={["category_id"]}>
              <TreeSelect
                treeLine
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={treeData}
                placeholder="Selecione uma categoria"
                treeDefaultExpandAll
              />
            </Form.Item>
            <Divider orientation="left">Tags</Divider>
            <Form.Item name={["tags"]}>
              <Select
                placeholder="Selecione as tags"
                onChange={handleChange}
                mode="multiple"
                allowClear
              >
                {availableTags.map(function (group, i) {
                  return (
                    <OptGroup key={"group_" + group.id} label={group.title}>
                      {group.tags.map(function (tag, i) {
                        return (
                          <Option
                            key={"tag_" + tag.id}
                            value={group.id + "_" + tag.id}
                          >
                            {tag.title}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            </Form.Item>

            <Divider orientation="left">Dimensões e peso</Divider>
            <Form.Item name={["package_type"]} label="Tipo de embalagem">
              <Select onChange={handlePackageChange} value={packageType}>
                <Option value="cilindro">Rolo / Cilindro</Option>
                <Option value="pacote">Pacote / Caixa</Option>
                <Option value="envelope">Envelope</Option>
              </Select>
            </Form.Item>

            <Row gutter={12}>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={["weight"]}
                  onChange={(e) => {
                    let val =
                      parseInt(e.target.value.replace(/\D/g, "")) / 100;
                    form.setFields([
                      {
                        name: "weight",
                        value: isNaN(val) ? "0" : val,
                      },
                    ]);
                  }}
                  label="Peso Bruto"
                >
                  <Input suffix="kg" />
                </Form.Item>
              </Col>
              {(packageType === "pacote" ||
                packageType === "envelope" ||
                packageType === "cilindro") && (
                  <Col xs={24} sm={6}>
                    <Form.Item
                      name={["length"]}
                      rules={[
                        {
                          type: "string",
                          max: 4,
                        },
                      ]}
                      label="Comprimento"
                    >
                      <Input
                        onChange={(e) => onlyDigits(e, "length")}
                        suffix="cm"
                      />
                    </Form.Item>
                  </Col>
                )}
              {(packageType === "pacote" || packageType === "envelope") && (
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={["widht"]}
                    rules={[
                      {
                        type: "string",
                        max: 4,
                      },
                    ]}
                    label="Largura"
                  >
                    <Input
                      onChange={(e) => onlyDigits(e, "widht")}
                      suffix="cm"
                    />
                  </Form.Item>
                </Col>
              )}
              {packageType === "pacote" && (
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={["height"]}
                    rules={[
                      {
                        type: "string",
                        max: 4,
                      },
                    ]}
                    label="Altura"
                  >
                    <Input
                      onChange={(e) => onlyDigits(e, "height")}
                      suffix="cm"
                    />
                  </Form.Item>
                </Col>
              )}
              {packageType === "cilindro" && (
                <Col xs={24} sm={6}>
                  <Form.Item
                    name={["diameter"]}
                    rules={[
                      {
                        type: "string",
                        max: 4,
                      },
                    ]}
                    label="Diâmetro"
                  >
                    <Input
                      onChange={(e) => onlyDigits(e, "diameter")}
                      suffix="cm"
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Divider orientation="left">Imagens</Divider>
            {productType === "variations" && (
              <Badge
                status="processing"
                text="Será aplicado para todas variações"
              />
            )}
            <Form.Item
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                <SortableContext items={uploadData.fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
                  <Upload
                    listType="picture-card"
                    fileList={uploadData.fileList}
                    onPreview={handlePreview}
                    onChange={handlePictureChange}
                    beforeUpload={() => false}
                    itemRender={(originNode, file) => (
                      <DraggableUploadListItem originNode={originNode} file={file} />
                    )}
                  >
                    {uploadData.fileList && uploadData.fileList.length >= 8
                      ? null
                      : uploadButton}
                  </Upload>
                </SortableContext>
              </DndContext>
              <Modal
                open={uploadData.previewVisible}
                title={uploadData.previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={uploadData.previewImage}
                />
              </Modal>
            </Form.Item>
          </TabPane>
          {productState && productType == "variations" && (
            <TabPane tab="Variações" key="2" forceRender>
              <SellerCreateProductVariations
                value={productState}
                sku={prodSKU}
                name={prodName}
                prodPrices={[prodPrice, prodPricePromo]}
                passVariationTabData={setVariationTabData}
                passVariationClasses={setVariationClasses}
              />
            </TabPane>
          )}
        </Tabs>
        <Form.Item>
          <div style={{ marginTop: 30 }}>
            <Row justify="center" gutter={[30, 12]}>
              <Col xs={18} sm={8}>
                <Button
                  block
                  onClick={() => {
                    navigate(`/admin/produtos/lista`)
                  }}
                >
                  Cancelar
                </Button>
              </Col>
              <Col xs={18} sm={8}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  loading={sending}
                >
                  {prodId ? "Atualizar" : "Cadastrar"}
                </Button>
              </Col>
            </Row>
          </div>
        </Form.Item>
      </Form>
    )}
  </>);
}
