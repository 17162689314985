import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./search.module.css";
import { Select, Spin } from "antd";
import { axiosClient } from "../../apiClient";
import { debounce } from "lodash";
import {
  mascaraCnpj,
  mascaraCpf,
} from "../../functions/validation";

export default function SearchCustomer(props) {
  const dispatch = useDispatch();
  const { Option, OptGroup } = Select;
  const buyerPagination = useSelector((state) => state.buyerPagination);
  const searchTermValue = useSelector(
    (state) => state.buyerSearchState.searchTerm
  );
  const buyerList = useSelector((state) => state.buyerList);

  useEffect(() => { dispatch({ type: "SET_BUYER_LOADING", loading: true }) }, []);

  const formatBuyer = (option) => {
    let formated = "";
    if (option.tipo_pessoa === "pf") {
      formated =
        option.id + "-" + option.email + " " + option.nome + " " + option.cpf;
    } else {
      formated =
        option.id +
        " - " +
        option.email +
        " - " +
        option.razao_social +
        " " +
        option.nome_fantasia +
        " " +
        option.cnpj;
    }
    return formated;
  };

  const updateSearch = async (page, perPage, searchTerm, resetPage) => {
    try {
      const results = await axiosClient.post("/auth/search/buyer", {
        search: searchTerm,
        page: resetPage ? 1 : page,
        perPage: perPage,
      }, { withCredentials: true });
      dispatch({ type: "SET_BUYER_LIST", list: results.data.buyers });
      dispatch({ type: "SET_BUYER_LOADING", loading: false });
      dispatch({
        type: "SET_BUYER_PAGE",
        totalPages: results.data.total_pages,
        actualPage: results.data.actual_page,
        pageUpdated: true,
      });
    } catch (error) { }
  };

  const updateSearchHandler = useCallback(debounce(updateSearch, 500), []);

  const handleSearchChange = async (e) => {
    dispatch({ type: "SET_BUYER_SEARCH_TERM", searchTerm: e.target.value });
  };

  const handleSearchChangeSelect = async (value) => {
    dispatch({ type: "SET_BUYER_SEARCH_TERM", searchTerm: value });
  };

  useEffect(() => {
    dispatch({ type: "SET_BUYER_LOADING", loading: true });
    updateSearchHandler(
      buyerPagination.page,
      buyerPagination.perPage,
      searchTermValue,
      true
    );
  }, [searchTermValue, buyerPagination.perPage]);

  useEffect(() => {
    !buyerPagination.pageUpdated &&
      updateSearchHandler(
        buyerPagination.page,
        buyerPagination.perPage,
        searchTermValue,
        false
      );
  }, [buyerPagination.page]);

  return (
    <Select
      showSearch
      mode="multiple"
      value={props.buyersFormated}
      onChange={props.handleSelectChange}
      autoClearSearchValue={false}
      style={{ width: "100%" }}
      placeholder="Selecione os clientes"
      allowClear={true}
      onSearch={handleSearchChangeSelect}
      loading={buyerList.loading}
      filterOption={false}
      notFoundContent={buyerList.loading ? <Spin size="small" /> : null}
    >
      {buyerList.loading
        ? null
        : buyerList.list.map((option) =>
          option.tipo_pessoa === "pf" ? (
            <Option key={option.id} value={formatBuyer(option)}>
              {mascaraCpf(option.cpf) + " - " + option.nome}
            </Option>
          ) : (
            <Option key={option.id} value={formatBuyer(option)}>
              {mascaraCnpj(option.cnpj) + " - " + option.razao_social}
            </Option>
          )
        )}
    </Select>
  );
}
