import styles from "./index.module.css";
import { PageHeader } from '@ant-design/pro-layout';
import { Skeleton, Col, Row } from "antd";
// import {
//   KeyOutlined,
//   EllipsisOutlined
// } from "@ant-design/icons";
import React, { useEffect, useState, useRef } from "react";
// import { axiosClient } from "../../apiClient";

import SellerIntegrationsTiny from "../../components/SellerIntegrationsTiny";
import SellerIntegrationsBling from "../../components/SellerIntegrationsBling";
import SellerIntegrationsPagseguro from "../../components/SellerIntegrationsPagseguro";
import SellerIntegrationsMercadoPago from "../../components/SellerIntegrationsMercadoPago";
import SellerIntegrationsMelhorEnvio from "../../components/SellerIntegrationsMelhorEnvio";

export default function IntegrationsPage() {
  const [loading, setLoading] = useState(false);

  const routes = [
    {
      path: "index",
      breadcrumbName: "Início",
    },
    {
      path: "first",
      breadcrumbName: "Configurações",
    },
    {
      path: "second",
      breadcrumbName: "Integrações",
    },
  ];

  return (
    <>
      {/* <PageHeader title={"Integrações"} breadcrumb={{ routes }} /> */}
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col>
              <SellerIntegrationsTiny />
            </Col>
            <Col>
              <SellerIntegrationsBling />
            </Col>
            <Col>
              <SellerIntegrationsPagseguro />
            </Col>
            <Col>
              <SellerIntegrationsMercadoPago />
            </Col>
            <Col>
              <SellerIntegrationsMelhorEnvio />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
