import React, { useLayoutEffect, useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Dropdown,
  Row,
  Col,
  Button,
  Space,
  Alert,
  Badge,
  Result,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import styles from "./admin.module.css";

import { Link, useNavigate } from "react-router-dom";

import {
  OrderedListOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  SkinOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  FundOutlined,
  LogoutOutlined,
  TeamOutlined,
  ControlOutlined,
  DollarCircleOutlined,
  CarOutlined,
  BranchesOutlined,
  TagsOutlined,
  MenuOutlined,
  ReadOutlined,
  UserAddOutlined,
  KeyOutlined,
  ToolOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { axiosClient } from "../../apiClient";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Marquee from "react-fast-marquee";

import SellerStats from "../../components/SellerStats";
import SellerConfiguration from "../../components/SellerConfiguration";
import SellerListProduct from "../../components/SellerListProduct";
import SellerListCategory from "../../components/SellerListCategory";
import SellerCreateProduct from "../../components/SellerCreateProduct";
import SellerListCustomer from "../../components/SellerListCustomer";
import SellerCreateCustomer from "../../components/SellerCreateCustomer";
import SellerListOrder from "../../components/SellerListOrder";
import SellerListCart from "../../components/SellerListCart";
import SellerListPayment from "../../components/SellerListPayment";
import SellerCreatePayment from "../../components/SellerCreatePayment";
import SellerListShipping from "../../components/SellerListShipping";
import SellerCreateShipping from "../../components/SellerCreateShipping";
import SellerListProductRule from "../../components/SellerListProductRule";
import SellerListShippingRule from "../../components/SellerListShippingRule";
import SellerCreateProductRule from "../../components/SellerCreateProductRule";
import SellerCreateShippingRule from "../../components/SellerCreateShippingRule";
import SellerListPaymentRule from "../../components/SellerListPaymentRule";
import SellerCreatePaymentRule from "../../components/SellerCreatePaymentRule";
import SellerListCustomerTag from "../../components/SellerListCustomerTag";
import SellerListProductTag from "../../components/SellerListProductTag";
import SellerListOrderDetails from "../../components/SellerListOrderDetails";
import SellerListCartDetails from "../../components/SellerListCartDetails";
import SellerListCartRule from "../../components/SellerListCartRule";
import SellerCreateCartRule from "../../components/SellerCreateCartRule";
import SellerListCustomerAccess from "../../components/SellerListCustomerAccess";
import SellerSubscription from "../../components/SellerSubscription";
import SellerChangePass from "../../components/SellerChangePass";
import Onboarding from "../../components/Onboarding";
import logout from "../../actions/logout";

import AppRoutes from "../../routes/index"

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const rootSubmenuKeys = [
  "home",
  "customerMenu",
  "productMenu",
  "orderMenu",
  "configurations",
];

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function Admin() {
  let titlePrefix = "Nupedido - ";
  const dispatch = useDispatch();
  const actualPage = useSelector((state) => state.adminPage);
  const nRequests = useSelector((state) => state.accessRequests);
  const sellerData = useSelector((state) => state.sellerData);

  const [openKeys, setOpenKeys] = useState(["1"]);
  const [collapsedSide, setCollapsedSide] = useState(true);
  const [width, height] = useWindowSize();

  const [customer, setCustomer] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loadingCatalog, setLoadingCatalog] = useState(false);
  const [goToCatalog, setGoToCatalog] = useState(false);
  const [numberOfRequests, setNumberOfRequests] = useState(0);
  const [mobile, setMobile] = useState(true);
  const [changePassDrawer, setChangePassDrawer] = useState(false);
  const logged = useSelector((state) => state.authentication.logged);
  const syncData = useSelector((state) => state.sync);

  useEffect(() => {
    if (sellerData.username && goToCatalog) {
      window.location = `/${sellerData.username}`
    }
  }, [sellerData, goToCatalog]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has("code") && dispatch({ type: "SELLER_CONFIGURATION" });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has("listusers") && dispatch({ type: "SELLER_CONFIGURATION" });
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET_PAGE",
      actualPage: 1,
    });
    dispatch({ type: "DROPDOWN_VALUE", value: [] });
  }, [actualPage]);

  useEffect(() => {
    if (document.getElementById("darken-background")) {
      document.getElementById("darken-background").style.display =
        mobile && !collapsedSide ? "block" : "none";
    }
  }, [collapsedSide, mobile]);

  useEffect(() => {
    width < 991 ? setMobile(true) : setMobile(false);
  }, [width]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    axiosClient
      .get("/user-update-info", { withCredentials: true })
      .then((response) => {
        // console.log(response.data)
        setCustomer(response.data.customer);
        dispatch({
          type: "SET_ACCESS_REQUEST",
          accessRequests: response.data.customer.request_access,
        });
        let is_customer = response.data.customer.is_customer;
        // if (
        //   (is_customer == false || is_customer == "past_due") &&
        //   actualPage != "sellerSubscription"
        // ) {
        //   dispatch({ type: "SELLER_SUBSCRIPTION" });
        // }
      });
  }, [actualPage]);

  useEffect(() => {
    setNumberOfRequests(nRequests.accessRequests);
  }, [nRequests]);

  useEffect(() => {
    defineAlert();
  }, [customer]);

  function handleMenuClick(e) {
    mobile && setCollapsedSide(!collapsedSide);
    dispatch({ type: "SEARCH_TERM", searchTerm: "" });
    // e.key === "home" && dispatch({ type: "HOME" });
    // e.key === "listCustomer" && dispatch({ type: "LIST_CUSTOMER" });
    // e.key === "listCustomerAccess" &&
    //   dispatch({ type: "LIST_CUSTOMER_ACCESS" });
    // e.key === "listProduct" && dispatch({ type: "LIST_PRODUCT" });
    // e.key === "listCategory" && dispatch({ type: "LIST_CATEGORY" });
    // e.key === "listOrder" && dispatch({ type: "LIST_ORDER" });
    // e.key === "listCart" && dispatch({ type: "LIST_CART" });
    // e.key === "listPayment" && dispatch({ type: "LIST_PAYMENT" });
    // e.key === "listShipping" && dispatch({ type: "LIST_SHIPPING" });
    // e.key === "listProductRule" && dispatch({ type: "LIST_PRODUCT_RULE" });
    // e.key === "listShippingRule" && dispatch({ type: "LIST_SHIPPING_RULE" });
    // e.key === "listPaymentRule" && dispatch({ type: "LIST_PAYMENT_RULE" });
    // e.key === "listCustomerTag" && dispatch({ type: "LIST_CUSTOMER_TAG" });
    // e.key === "listProductTag" && dispatch({ type: "LIST_PRODUCT_TAG" });
    // e.key === "listCartRule" && dispatch({ type: "LIST_CART_RULE" });
  }

  function handleSmallMenuClick(e) {
    mobile && setCollapsedSide(true);
    dispatch({ type: "SEARCH_TERM", searchTerm: "" });
    // e.key === "sellerConfiguration" &&
    //   dispatch({ type: "SELLER_CONFIGURATION" });
    e.key === "sellerChangePass" && setChangePassDrawer(true);
  }


  const items = [
    ...(width <= 990 ?
      [{
        label: 'Meu Catálogo',
        key: 'sellerCatalog',
        icon: <ReadOutlined />,
        onClick: () => {
          setGoToCatalog(true);
          setLoadingCatalog(true);
        }
      }] : []),
    ...(logged === "seller" ?
      [{
        label: <Link to="/admin/configuracoes">Configurações</Link>,
        key: 'sellerConfiguration',
        icon: <ToolOutlined />,
        onClick: () => { dispatch({ type: "SELLER_CONFIGURATION" }) }
      }] : []),
    {
      label: 'Trocar Senha',
      key: 'sellerChangePass',
      icon: <KeyOutlined />,
    },
    {
      label: 'Logout',
      key: "logout",
      icon: <LogoutOutlined />,
      onClick: () => {
        logout(sellerData.domain ? "/" : sellerData.username);
      },
    },
  ];
  const menuProps = {
    items,
    onClick: handleSmallMenuClick,
  };

  const defineAlert = () => {
    if (customer.cancel_at_period_end == true) {
      setAlertMessage({
        alert_message: `Sua renovação automática foi desativada. Ao final do período, dia ${customer.current_period_end.split(" - ")[0]
          }, você não será mais cobrado, mas você e seus clientes perderão acesso ao catálogo.`,
        alert_type: "warning",
        button_text: "Reativar Assinatura",
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == "trialing" &&
      customer.payment_method == null
    ) {
      if (customer.trial_left > 0) {
        var mensagem = `Restam ${customer.trial_left + 1
          } dias de teste. Ao final do período você e seus clientes perderão acesso ao catálogo. Cadastre um cartão de crédito para evitar que isso aconteça.`;
        var tipo = "info";
      } else {
        var mensagem = `Hoje é o último dia de teste. Amanhã você e seus clientes não terão mais acesso ao catálogo. Cadastre um cartão de crédito para evitar que isso aconteça.`;
        var tipo = "warning";
      }
      setAlertMessage({
        alert_message: mensagem,
        alert_type: tipo,
        button_text: "Cadastrar",
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == false &&
      customer.payment_method == null
    ) {
      setAlertMessage({
        alert_message: `Identificamos que você não está cadastrado em nenhum de nossos planos. Contate a administração para adquirir esse serviço e não parder acesso ao sistema | `,
        alert_type: "warning",
        // button_text: "Cadastrar",
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == "past_due" &&
      customer.payment_method == null
    ) {
      setAlertMessage({
        alert_message: `Seu plano expirou. Cadastre um cartão de crédito válido para voltar a ter acesso ao seu catálogo.`,
        alert_type: "warning",
        button_text: "Cadastrar",
      });
      setAlertVisible(true);
    } else if (customer.is_customer == "past_due" && customer.payment_method) {
      setAlertMessage({
        alert_message: `Tivemos algum problema com a cobrança em seu cartão. Regularize a situação e volte a ter acesso ao seu catálogo.`,
        alert_type: "warning",
        button_text: "Verificar",
      });
      setAlertVisible(true);
    } else {
      setAlertVisible(false);
    }
  };

  useEffect(() => console.log("syncData.onlineStatus", syncData.onlineStatus), [syncData.onlineStatus])

  return sellerData.username === null ? (
    <Onboarding />
  ) : (
    <>
      <div
        id="darken-background"
        onClick={() => setCollapsedSide(!collapsedSide)}
        style={{
          position: "fixed",
          top: "0px",
          width: "100%",
          height: "100%",
          zIndex: 8,
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      />
      <Layout>
        <Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            top: 0,
            padding: 0,
            margin: 0,
            backgroundColor: "white",
            boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
            // zIndex: 9,
          }}
        >
          <img
            src="logo.webp"
            alt="Logo Nupedido"
            className={styles["img-logo"]}
            onClick={() => window.location.replace("/admin")}
          ></img>
          <div>
            <Dropdown menu={menuProps} trigger="click">
              <Button style={{
                float: "right",
                marginTop: "13px",
                marginBottom: "13px",
                marginLeft: "4px",
                marginRight: "13px",
              }} icon={<UserOutlined />} />
            </Dropdown>
            {width > 990 && (
              <Button
                style={{
                  float: "right",
                  marginTop: "13px",
                  marginBottom: "13px",
                  marginLeft: "4px",
                  marginRight: "4px",
                }}
                icon={<ReadOutlined />}
                loading={loadingCatalog}
                onClick={() => {
                  setGoToCatalog(true);
                  setLoadingCatalog(true);
                }}
              >
                Meu Catálogo
              </Button>
            )}
          </div>
        </Header>
        <Layout>
          {!syncData.onlineStatus && syncData.syncStatus != "synced" &&
            <Result
              status="warning"
              title="Você está sem internet e seu aplicativo não está sincronizado"
              subTitle="Conecte seu aparelho na internet e sincronize os dados para usá-lo offline."
            />}
          {!syncData.onlineStatus && syncData.syncStatus == "synced" &&
            <Result
              icon={<SmileOutlined />}
              title="Você está offline e sincronizado, pode gerar pedidos."
              subTitle="O painel do gestor não está disponível no modo offline. Conecte seu aparelho na internet para utilizá-lo."
              extra={[
                <Button onClick={() => {
                  setGoToCatalog(true);
                  setLoadingCatalog(true);
                }} type="primary" key="console">
                  Criar Pedido Agora
                </Button>,
              ]}
            />}
          {syncData.onlineStatus &&
            <>
              <Sider
                className={styles["sider"]}
                breakpoint="lg"
                collapsedWidth="0"
                collapsed={collapsedSide}
                theme="light"
                width="256"
                height="100%"
                style={{
                  position: width < 991 ? "fixed" : "relative",
                  top: width < 991 ? 64 : 0,
                  zIndex: 10,
                }}
                onCollapse={(collapsed, type) => {
                  setCollapsedSide(collapsed);
                }}
              >
                <Menu
                  mode="inline"
                  openKeys={openKeys}
                  onClick={handleMenuClick}
                  onOpenChange={onOpenChange}
                  style={{ width: 256 }}
                >
                  <Menu.Item icon={<FundOutlined />} key="home">
                    <Link to="/admin">Dashboard</Link>
                  </Menu.Item>
                  {(logged === "seller" ||
                    (sellerData.permissions &&
                      sellerData.permissions.customers.length > 0)) && (
                      <SubMenu
                        key="customerMenu"
                        icon={<TeamOutlined />}
                        title="Clientes"
                      >
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.customers.includes(
                              "Cadastros"
                            ))) && (
                            <Menu.Item
                              icon={<OrderedListOutlined />}
                              key="listCustomer"
                            >
                              <Link to="/admin/clientes/lista">Cadastros</Link>
                            </Menu.Item>
                          )}
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.customers.includes(
                              "Solicitações"
                            ))) && (
                            <Menu.Item
                              icon={<UserAddOutlined />}
                              key="listCustomerAccess"
                            >
                              <Link to="/admin/clientes/solicitacoes">Solicitações{" "}</Link>
                              <Badge
                                count={numberOfRequests}
                                overflowCount={99}
                                offset={[0, -10]}
                              />
                            </Menu.Item>
                          )}
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.customers.includes("Tags"))) && (
                            <Menu.Item icon={<TagsOutlined />} key="listCustomerTag">
                              <Link to="/admin/clientes/tags">Tags</Link>
                            </Menu.Item>
                          )}
                      </SubMenu>
                    )}
                  {(logged === "seller" ||
                    (sellerData.permissions &&
                      sellerData.permissions.products.length > 0)) && (
                      <SubMenu
                        key="productMenu"
                        icon={<SkinOutlined />}
                        title="Produtos"
                      >
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.products.includes(
                              "Cadastros"
                            ))) && (
                            <Menu.Item icon={<OrderedListOutlined />} key="listProduct">
                              <Link to="/admin/produtos/lista">Cadastros</Link>
                            </Menu.Item>
                          )}
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.products.includes(
                              "Categorias"
                            ))) && (
                            <Menu.Item icon={<MenuOutlined />} key="listCategory">
                              <Link to="/admin/produtos/categorias">Categorias</Link>
                            </Menu.Item>
                          )}
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.products.includes("Tags"))) && (
                            <Menu.Item icon={<TagsOutlined />} key="listProductTag">
                              <Link to="/admin/produtos/tags">Tags</Link>
                            </Menu.Item>
                          )}
                      </SubMenu>
                    )}
                  {(logged === "seller" ||
                    (sellerData.permissions &&
                      sellerData.permissions.orders.length > 0)) && (
                      <SubMenu
                        key="orderMenu"
                        icon={<ShoppingCartOutlined />}
                        title="Pedidos"
                      >
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.orders.includes(
                              "Finalizados"
                            ))) && (
                            <Menu.Item icon={<CheckCircleOutlined />} key="listOrder">
                              <Link to="/admin/pedidos/finalizados">Finalizados</Link>
                            </Menu.Item>
                          )}
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.orders.includes("Em Aberto"))) && (
                            <Menu.Item icon={<QuestionCircleOutlined />} key="listCart">
                              <Link to="/admin/pedidos/em-aberto">Em Aberto</Link>
                            </Menu.Item>
                          )}
                      </SubMenu>
                    )}
                  {(logged === "seller" ||
                    (sellerData.permissions &&
                      sellerData.permissions.rules.length > 0)) && (
                      <SubMenu
                        key="configurations"
                        icon={<ControlOutlined />}
                        title="Regras"
                      >
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.rules.includes("Pagamento"))) && (
                            <SubMenu
                              key="paymentMenu"
                              icon={<ControlOutlined />}
                              title="Pagamento"
                            >
                              <Menu.Item
                                icon={<DollarCircleOutlined />}
                                key="listPayment"
                              >
                                <Link to="/admin/pagamento/lista">Formas de Pagamento</Link>
                              </Menu.Item>
                              <Menu.Item
                                icon={<BranchesOutlined />}
                                key="listPaymentRule"
                              >
                                <Link to="/admin/pagamento/regras">Regras de Pagamento</Link>
                              </Menu.Item>
                            </SubMenu>
                          )}
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.rules.includes("Entrega"))) && (
                            <SubMenu
                              key="shippingMenu"
                              icon={<ControlOutlined />}
                              title="Entrega"
                            >
                              <Menu.Item icon={<CarOutlined />} key="listShipping">
                                <Link to="/admin/entrega/lista">Formas de Entrega</Link>
                              </Menu.Item>
                              <Menu.Item
                                icon={<BranchesOutlined />}
                                key="listShippingRule"
                              >
                                <Link to="/admin/entrega/regras">Regras de Entrega</Link>
                              </Menu.Item>
                            </SubMenu>
                          )}
                        {(logged === "seller" ||
                          (sellerData.permissions &&
                            sellerData.permissions.rules.includes("Catálogo"))) && (
                            <SubMenu
                              key="ruleMenu"
                              icon={<ControlOutlined />}
                              title="Catálogo"
                            >
                              <Menu.Item
                                icon={<DollarCircleOutlined />}
                                key="listProductRule"
                              >
                                <Link to="/admin/catalogo/regras-produto">Regras de Produto</Link>
                              </Menu.Item>
                              <Menu.Item
                                icon={<ShoppingCartOutlined />}
                                key="listCartRule"
                              >
                                <Link to="/admin/catalogo/regras-carrinho">Regras de Carrinho</Link>
                              </Menu.Item>
                            </SubMenu>
                          )}
                      </SubMenu>
                    )}
                </Menu>
              </Sider>
              <Content style={{ margin: "6px 6px 0" }}>
                <div
                  className={styles["site-layout-background"]}
                  style={{ padding: 12 }}
                >
                  {alertVisible == true && (
                    <Alert
                      message={
                        width < 1200 ? (
                          <Marquee pauseOnHover gradient={false}>
                            {alertMessage.alert_message}&nbsp;
                          </Marquee>
                        ) : (
                          alertMessage.alert_message
                        )
                      }
                      type={alertMessage.alert_type}
                      banner
                      action={
                        <Space>
                          <Button
                            ghost
                            onClick={() =>
                              dispatch({ type: "SELLER_SUBSCRIPTION" })
                            }
                            size="small"
                            type="primary"
                            key="sellerSubscription"
                          >
                            {alertMessage.button_text}
                          </Button>
                        </Space>
                      }
                    />
                  )}
                  {logged === "seller" ||
                    (sellerData.permissions &&
                      (sellerData.permissions.customers.length > 0 ||
                        sellerData.permissions.orders.length > 0 ||
                        sellerData.permissions.products.length > 0 ||
                        sellerData.permissions.rules.length > 0)) ? (
                    <HelmetProvider>
                      <Row justify="center">
                        {actualPage === "home" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Painel de Controle"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerStats />
                            </Col>
                          </>
                        )}
                        {actualPage === "sellerSubscription" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Pagamento"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerSubscription />
                            </Col>
                          </>
                        )}
                        {actualPage === "sellerConfiguration" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Minha Conta"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerConfiguration />
                            </Col>
                          </>
                        )}
                        {actualPage === "listCustomer" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Clientes"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListCustomer />
                            </Col>
                          </>
                        )}
                        {actualPage === "listCustomerAccess" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Solicitações de acesso"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListCustomerAccess />
                            </Col>
                          </>
                        )}
                        {actualPage === "createCustomer" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Cadastro de Cliente"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerCreateCustomer />
                            </Col>
                          </>
                        )}
                        {actualPage === "listCustomerTag" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Tag de Clientes"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListCustomerTag />
                            </Col>
                          </>
                        )}
                        {actualPage === "listProduct" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Produtos"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListProduct />
                            </Col>
                          </>
                        )}
                        {actualPage === "listProductTag" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Tag de Produtos"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListProductTag />
                            </Col>
                          </>
                        )}
                        {actualPage === "listCategory" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Categorias de Produtos"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListCategory />
                            </Col>
                          </>
                        )}
                        {actualPage === "createProduct" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Cadastro de Produto"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerCreateProduct />
                            </Col>
                          </>
                        )}
                        {actualPage === "listOrder" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Pedidos Realizados"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListOrder />
                            </Col>
                          </>
                        )}
                        {actualPage === "listCart" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Carrinhos em Aberto"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListCart />
                            </Col>
                          </>
                        )}
                        {actualPage === "listPayment" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Métodos de Pagamento"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListPayment />
                            </Col>
                          </>
                        )}
                        {actualPage === "createPayment" && (
                          <>
                            <Helmet>
                              <title>
                                {titlePrefix + "Cadastro de Método de Pagamento"}
                              </title>
                            </Helmet>
                            <Col span={24}>
                              <SellerCreatePayment />
                            </Col>
                          </>
                        )}
                        {actualPage === "listShipping" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Métodos de Entrega"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListShipping />
                            </Col>
                          </>
                        )}
                        {actualPage === "createShipping" && (
                          <>
                            <Helmet>
                              <title>
                                {titlePrefix + "Cadastro de Método de Entrega"}
                              </title>
                            </Helmet>
                            <Col span={24}>
                              <SellerCreateShipping />
                            </Col>
                          </>
                        )}
                        {actualPage === "listProductRule" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Regras de Preço"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListProductRule />
                            </Col>
                          </>
                        )}
                        {actualPage === "createProductRule" && (
                          <>
                            <Helmet>
                              <title>
                                {titlePrefix + "Cadastro de Regras de Preço"}
                              </title>
                            </Helmet>
                            <Col span={24}>
                              <SellerCreateProductRule />
                            </Col>
                          </>
                        )}
                        {actualPage === "listShippingRule" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Regras de Entrega"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListShippingRule />
                            </Col>
                          </>
                        )}
                        {actualPage === "createShippingRule" && (
                          <>
                            <Helmet>
                              <title>
                                {titlePrefix + "Cadastro de Regras de Entrega"}
                              </title>
                            </Helmet>
                            <Col span={24}>
                              <SellerCreateShippingRule />
                            </Col>
                          </>
                        )}
                        {actualPage === "listPaymentRule" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Regras de Pagamento"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListPaymentRule />
                            </Col>
                          </>
                        )}
                        {actualPage === "createPaymentRule" && (
                          <>
                            <Helmet>
                              <title>
                                {titlePrefix + "Cadastro de Regras de Pagamento"}
                              </title>
                            </Helmet>
                            <Col span={24}>
                              <SellerCreatePaymentRule />
                            </Col>
                          </>
                        )}
                        {actualPage === "listOrderDetails" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Pedido Realizado"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListOrderDetails />
                            </Col>
                          </>
                        )}
                        {actualPage === "listCartDetails" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Carrinho em Aberto"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListCartDetails />
                            </Col>
                          </>
                        )}
                        {actualPage === "listCartRule" && (
                          <>
                            <Helmet>
                              <title>{titlePrefix + "Regras de Carrinho"}</title>
                            </Helmet>
                            <Col span={24}>
                              <SellerListCartRule />
                            </Col>
                          </>
                        )}
                        {actualPage === "createCartRule" && (
                          <>
                            <Helmet>
                              <title>
                                {titlePrefix + "Cadastro de Regras de Carrinho"}
                              </title>
                            </Helmet>
                            <Col span={24}>
                              <SellerCreateCartRule />
                            </Col>
                          </>
                        )}
                      </Row>
                    </HelmetProvider>
                  ) : (
                    sellerData.permissions &&
                    (window.location = `/${sellerData.username}`)
                  )}
                  {changePassDrawer === true && (
                    <>
                      <SellerChangePass
                        drawer={changePassDrawer}
                        drawerSetter={setChangePassDrawer}
                      />
                    </>
                  )}
                </div>
                <AppRoutes />
              </Content>
            </>}
        </Layout>
        <Footer style={{ textAlign: "center" }}>
          Criado por{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://nupedido.com.br/?source=admin"
          >
            Nupedido
          </a>{" "}
          ©2024
        </Footer>
      </Layout>
    </>
  );
}
