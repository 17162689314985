import React, { useEffect, useState } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  Divider,
  Tooltip,
  Dropdown,
  Menu,
  Space,
  Switch,
  message,
  Skeleton,
  Alert,
  Modal,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  mascaraPhone,
  mascaraCnpj,
  mascaraCpf,
  validaCpfCnpj,
} from "../../functions/validation";
// import styles from "./cadastro.module.css";
import { axiosClient } from "../../apiClient";
import {
  DownOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  KeyOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

const { Option, OptGroup } = Select;
const { Search } = Input;

export default function SellerCreateCustomer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  form.setFieldsValue({
    send_email: true,
  });
  const [tipoPessoa, setTipoPessoa] = useState("pj");
  const [changePass, setChangePass] = useState(true);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [sending, setSending] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [updateList, setUpdateList] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedSellerUser, setSelectedSellerUser] = useState({
    value: null,
  });
  const [oldSellerUser, setOldSellerUser] = useState({
    value: null,
  });
  const [showSellerModal, setShowSellerModal] = useState(false);
  const [sellerUsers, setSellerUsers] = useState(null);

  const editItems = useSelector((state) => state.editItems);
  const logged = useSelector((state) => state.authentication.logged);
  const buyerView = useSelector((state) => state.buyerView.buyerView);

  let states = [
    { nome: "Acre", sigla: "AC" },
    { nome: "Alagoas", sigla: "AL" },
    { nome: "Amapá", sigla: "AP" },
    { nome: "Amazonas", sigla: "AM" },
    { nome: "Bahia", sigla: "BA" },
    { nome: "Ceará", sigla: "CE" },
    { nome: "Distrito Federal", sigla: "DF" },
    { nome: "Espírito Santo", sigla: "ES" },
    { nome: "Goiás", sigla: "GO" },
    { nome: "Maranhão", sigla: "MA" },
    { nome: "Mato Grosso", sigla: "MT" },
    { nome: "Mato Grosso do Sul", sigla: "MS" },
    { nome: "Minas Gerais", sigla: "MG" },
    { nome: "Pará", sigla: "PA" },
    { nome: "Paraíba", sigla: "PB" },
    { nome: "Paraná", sigla: "PR" },
    { nome: "Pernambuco", sigla: "PE" },
    { nome: "Piauí", sigla: "PI" },
    { nome: "Rio de Janeiro", sigla: "RJ" },
    { nome: "Rio Grande do Norte", sigla: "RN" },
    { nome: "Rio Grande do Sul", sigla: "RS" },
    { nome: "Rondônia", sigla: "RO" },
    { nome: "Roraima", sigla: "RR" },
    { nome: "Santa Catarina", sigla: "SC" },
    { nome: "São Paulo", sigla: "SP" },
    { nome: "Sergipe", sigla: "SE" },
    { nome: "Tocantins", sigla: "TO" },
  ];

  useEffect(() => {
    axiosClient
      .get("/customer/tag/list", { withCredentials: true })
      .then((out) => {
        setAvailableTags(out.data.tags);
      });
  }, []);

  useEffect(() => {
    (logged === "seller" || logged === "sellerUser") &&
      axiosClient
        .get("/auth/list/seller-users", { withCredentials: true })
        .then((response) => {
          setSellerUsers(
            response.data.map((value) => ({
              value: value.id,
              label: value.nome,
            }))
          );
        });
  }, []);

  useEffect(() => {
    if (editItems.customerId != false && editItems.customerId != "request") {
      setLoading(true);
      setChangePass(false);
      axiosClient
        .post(
          "/auth/get/buyer",
          { customerId: editItems.customerId },
          { withCredentials: true }
        )
        .then((response) => {
          delete response.data["tags"];
          let cleanData = Object.fromEntries(
            Object.entries(response.data).filter(([_, v]) => v != null)
          );
          console.log(cleanData)
          console.log(response.data.selleruser)
          form.setFieldsValue(cleanData);
          cleanData.tipo_pessoa === "pf" &&
            form.setFieldsValue({ cpf: mascaraCpf(cleanData.cpf) });
          cleanData.tipo_pessoa === "pj" &&
            form.setFieldsValue({ cnpj: mascaraCnpj(cleanData.cnpj) });
          setSelectedTags(cleanData.tags_ids);
          setTipoPessoa(cleanData.tipo_pessoa);
          setLoading(false);
          setOldSellerUser({
            value: response.data.selleruser ? cleanData.selleruser.id : false,
            label: response.data.selleruser ? cleanData.selleruser.nome : false,
          });
          setSelectedSellerUser({
            value: response.data.selleruser ? cleanData.selleruser.id : false,
            label: response.data.selleruser ? cleanData.selleruser.nome : false,
          });
        });
    } else if (editItems.customerId == "request") {
      setLoading(true);
      if (editItems.customerData) {
        let cleanData = Object.fromEntries(
          Object.entries(editItems.customerData).filter(([_, v]) => v != null)
        );
        form.setFieldsValue(cleanData);
        cleanData.tipo_pessoa === "pf" &&
          form.setFieldsValue({ cpf: mascaraCpf(cleanData.cpf) });
        cleanData.tipo_pessoa === "pj" &&
          form.setFieldsValue({ cnpj: mascaraCnpj(cleanData.cnpj) });
        // setSelectedTags(cleanData.tags_ids);
        setTipoPessoa(cleanData.tipo_pessoa);
        if (cleanData.selleruser) {
          setOldSellerUser({
            value: cleanData.selleruser.id,
            label: cleanData.selleruser.nome,
          });
          setSelectedSellerUser({
            value: cleanData.selleruser.id,
            label: cleanData.selleruser.nome,
          });
        }

      }
      setLoading(false);
    }
  }, []);

  useEffect(() => { form.setFieldsValue({ tags: selectedTags }) }, [availableTags, selectedTags]);

  function getAddress() {
    setSearching(true);
    axiosClient
      .post(
        "/shipping/address-from-cep",
        { func: "consultarCep", data: form.getFieldValue("cep") },
        { withCredentials: true }
      )
      .then((response) => {
        let data = response.data.response;
        data["municipio"] = data["localidade"];
        data["endereco"] = data["logradouro"];
        data["cep"] = data.cep.replace(/\D/g, "");
        delete data.complemento;
        form.setFieldsValue(data);
        dispatch({ type: "ACTIVE_CEP", value: data.cep, uf: data.uf });
        setSearching(false);
      })
      .catch((error) => {
        let data = {};
        data["municipio"] = "";
        data["endereco"] = "";
        data["cep"] = "";
        delete data.complemento;
        form.setFieldsValue(data);
        dispatch({ type: "ACTIVE_CEP", value: "", uf: "" });
        message.warning("CEP Inválido");
        setSearching(false);
      });
  }

  function deleteCustomer() {
    setDeleting(true);
    axiosClient
      .post(
        "/auth/delete/buyer",
        { customerId: editItems.customerId },
        { withCredentials: true }
      )
      .then((response) => {
        response.data.status === "Success"
          ? message.success("Cliente deletado com sucesso", 4)
          : message.error("Erro ao deletar cliente", 4);
        navigate(`/admin/clientes/lista`)
        // dispatch({ type: "LIST_CUSTOMER" });
        setDeleting(false);
      });
  }

  const layout = {
    layout: "vertical",
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validarCPF = (e) => {
    const cpf = mascaraCpf(e.target.value);
    if (validaCpfCnpj(cpf) == false) {
      form.setFields([
        {
          name: "cpf",
          errors: ["CPF Inválido"],
        },
      ]);
    }
  };

  const validarCNPJ = (e) => {
    const cnpj = mascaraCnpj(e.target.value);
    if (validaCpfCnpj(cnpj) == false) {
      form.setFields([
        {
          name: "cnpj",
          errors: ["CNPJ Inválido"],
        },
      ]);
    }
  };

  const onFinish = (values) => {
    setSending(true);
    if (editItems.customerId == false) {
      var apiUrl = "/auth/register/buyer";
      var disp = "/admin/clientes/lista"
      // var disp = "LIST_CUSTOMER";
      var msg = "Cliente adicionado com sucesso";
    } else if (editItems.customerId == "request") {
      var apiUrl = "/auth/register/buyer";
      var disp = "/admin/clientes/solicitacoes"
      // var disp = "LIST_CUSTOMER_ACCESS";
      var msg = "Solicitação aceita. Cliente adicionado com sucesso";
    } else {
      var apiUrl = "/auth/update/buyer";
      var disp = "/admin/clientes/lista"
      // var disp = "LIST_CUSTOMER";
      var msg = "Dados salvos com sucesso";
    }
    let data = editItems.customerId
      ? {
        ...values,
        customerId: editItems.customerId,
        selectedSellerUser: selectedSellerUser.value,
      }
      : {
        ...values,
        selectedSellerUser: selectedSellerUser.value,
      };
    axiosClient
      .post(apiUrl, data, { withCredentials: true })
      .then((response) => {
        if (response.data.status === "Success") {
          message.success(msg, 4);
          if (editItems.customerId == "request") {
            editItems.customerData.send_email = true;
            axiosClient
              .post(
                "/auth/buyer/request/access/delete",
                editItems.customerData,
                { withCredentials: true }
              )
              .then(() => navigate(disp)); //dispatch({ type: disp }));
          }
        } else {
          response.data.msg === "User already exists"
            ? message.error("Usuário já cadastrado", 4)
            : message.error("Erro ao salvar dados", 4);
        }
        setSending(false);
        editItems.customerId != "request" && navigate(disp); //dispatch({ type: disp });
      });
  };

  function handleChange(values) {
    if (values.length > 1) {
      let [groupId, tagId] = values[values.length - 1].split("_");

      for (let i = 0; i < values.length - 1; i++) {
        if (values[i].startsWith(groupId)) {
          values.splice(i, 1);
        }
      }
    }
    form.setFieldsValue({ tags: values });
  }

  const menu = (
    <Menu onClick={() => deleteCustomer()}>
      <Menu.Item key="1">Excluir Cliente</Menu.Item>
    </Menu>
  );

  return (<>
    <PageHeader
      title={
        editItems.customerId != false && editItems.customerId != "request"
          ? "Editar Cliente"
          : "Cadastrar Cliente"
      }
      onBack={() => window.history.back()}
      extra={
        editItems.customerId != false &&
        editItems.customerId != "request" && (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button type="primary">
              {deleting ? (
                <>
                  <LoadingOutlined /> Ações <DownOutlined />
                </>
              ) : (
                <>
                  Ações <DownOutlined />
                </>
              )}
            </Button>
          </Dropdown>
        )
      }
    ></PageHeader>
    {loading ? (
      <Skeleton active />
    ) : (
      <Form
        form={form}
        scrollToFirstError={true}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        initialValues={{
          ["tipo_pessoa"]: "pj",
        }}
      >
        <Divider orientation="left">Dados gerais</Divider>
        <Form.Item
          name="tipo_pessoa"
          label="Tipo de Pessoa"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Selecione o tipo de pessoa"
            onChange={(value) => setTipoPessoa(value)}
            allowClear
          >
            <Option value="pj">Pessoa Jurídica</Option>
            <Option value="pf">Pessoa Física</Option>
          </Select>
        </Form.Item>
        {tipoPessoa === "pf" && (
          <>
            <Form.Item
              name={["nome"]}
              label="Nome"
              rules={[
                {
                  type: "string",
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["cpf"]}
                  label="CPF"
                  rules={[
                    {
                      type: "string",
                      max: 100,
                      required: true,
                    },
                  ]}
                >
                  <Input
                    onBlur={validarCPF}
                    maxLength={14}
                    onChange={(e) => {
                      form.setFieldsValue({
                        cpf: mascaraCpf(e.target.value),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["rg"]}
                  label="RG"
                  rules={[
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {tipoPessoa === "pj" && (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["nome_fantasia"]}
                  label="Nome Fantasia"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["cnpj"]}
                  label="CNPJ"
                  rules={[
                    {
                      required: true,
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Input
                    onBlur={validarCNPJ}
                    maxLength={18}
                    onChange={(e) => {
                      form.setFieldsValue({
                        cnpj: mascaraCnpj(e.target.value),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["razao_social"]}
                  label="Razão Social"
                  rules={[
                    {
                      type: "string",
                      required: true,
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["ie"]}
                  label="Inscrição Estadual"
                  // onChange={() => }

                  rules={[
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={["email"]}
              label="Email"
              rules={[
                {
                  type: "email",
                  // required: true,
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={["phone"]}
              label="Celular ou telefone (com DDD)"
              rules={[
                {
                  type: "string",
                  // required: true,
                  max: 100,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({
                    phone: mascaraPhone(e),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Tags</Divider>
        <Form.Item name={["tags"]}>
          <Select
            placeholder="Selecione as tags"
            onChange={handleChange}
            mode="multiple"
            allowClear
          >
            {availableTags.map(function (group, i) {
              return (
                <OptGroup key={"group_" + group.id} label={group.title}>
                  {group.tags.map(function (tag, i) {
                    return (
                      <Option
                        key={"tag_" + tag.id}
                        value={group.id + "_" + tag.id}
                      >
                        {tag.title}
                      </Option>
                    );
                  })}
                </OptGroup>
              );
            })}
          </Select>
        </Form.Item>

        <Divider orientation="left">Endereço</Divider>
        {searching == false ? (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={["cep"]}
                  label="CEP"
                  rules={[
                    {
                      // required: true,
                      max: 8,
                    },
                    {
                      type: "string",
                    },
                  ]}
                  onChange={(e) => {
                    let cep_value = e.target.value
                      .replace(/\D/g, "")
                      .substring(0, 8);
                    form.setFieldsValue({
                      cep: cep_value,
                    });
                    if (cep_value.length === 8) {
                      getAddress();
                    }
                  }}
                  onBlur={(e) => {
                    let cep_value = e.target.value
                      .replace(/\D/g, "")
                      .substring(0, 8);
                    form.setFieldsValue({
                      cep: cep_value,
                    });
                    getAddress();
                  }}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={["municipio"]}
                  label="Município"
                  rules={[
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={["uf"]}
                  label="UF"
                  rules={[
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Select disabled>
                    {states.map(function (state) {
                      return (
                        <Option value={state.sigla}>
                          {state.sigla} - {state.nome}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name={["endereco"]}
                  label="Endereço"
                  rules={[
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={8}>
                <Form.Item
                  name={["bairro"]}
                  label="Bairro"
                  rules={[
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={6}>
                <Form.Item
                  name={["numero"]}
                  label="Número"
                  rules={[
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={10}>
                <Form.Item
                  name={["complemento"]}
                  label="Complemento"
                  rules={[
                    {
                      type: "string",
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : (
          <Skeleton></Skeleton>
        )}
        <Divider orientation="left">Vendedor</Divider>
        <Space direction="vertical" style={{ width: "100%" }}>
          {oldSellerUser.value &&
            selectedSellerUser.value &&
            oldSellerUser.value != selectedSellerUser.value && (
              <Alert
                message={
                  <>
                    O vendedor padrão deste cliente será modificado de{" "}
                    <b>{oldSellerUser.label}</b> para{" "}
                    <b>{selectedSellerUser.label}</b>
                  </>
                }
                type="warning"
                showIcon
                closable
              />
            )}
          {selectedSellerUser.value == null && (
            <Alert message="Selecione um vendedor." type="error" showIcon />
          )}
          <Select
            value={selectedSellerUser}
            style={{ width: "100%" }}
            onChange={(value, data) => {
              setSelectedSellerUser(data);
              oldSellerUser.value &&
                oldSellerUser.value != value &&
                setShowSellerModal(true);
            }}
            options={sellerUsers}
          />
        </Space>
        <Divider orientation="left">Senha</Divider>
        <Button
          type="link"
          style={{ display: changePass ? "none" : "block" }}
          onClick={() => setChangePass(true)}
        >
          Mudar senha
        </Button>
        {changePass && (
          <>
            <Space align="baseline">
              <Form.Item
                name={["password"]}
                rules={[
                  {
                    type: "string",
                    max: 100,
                  },
                ]}
              >
                <Input.Password
                  style={{ width: "200px" }}
                  placeholder="Senha"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Tooltip title="Gerar senha aleatória">
                  <Button
                    type="primary"
                    onClick={() => {
                      form.setFieldsValue({
                        password: Math.floor(1000 + Math.random() * 9000), //Math.random().toString(36).slice(-6),
                      });
                    }}
                  >
                    <KeyOutlined />
                  </Button>
                </Tooltip>
              </Form.Item>
            </Space>
            <Form.Item name={["send_email"]}>
              Enviar senha por email?{" "}
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                onChange={(checked) => {
                  form.setFieldsValue({
                    send_email: checked,
                  });
                }}
              />
            </Form.Item>
          </>
        )}
        <>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              {editItems.customerId == "request" ? (
                <Row justify="center" gutter={[30, 12]}>
                  <Col xs={18} sm={8}>
                    <Button
                      block
                      onClick={() => {
                        navigate("/admin/clientes/solicitacoes");
                        // dispatch({ type: "LIST_CUSTOMER_ACCESS" });
                      }}
                    >
                      Voltar
                    </Button>
                  </Col>
                  <Col xs={18} sm={8}>
                    <Button
                      block
                      danger
                      type="primary"
                      onClick={() => {
                        axiosClient
                          .post(
                            "/auth/buyer/request/access/delete",
                            editItems.customerData,
                            { withCredentials: true }
                          )
                          .then((response) => {
                            response.data.status == "Success" &&
                              message.success(
                                "Solicitação de acesso deletada com sucesso.",
                                4
                              );
                            navigate("/admin/clientes/solicitacoes");
                            // dispatch({ type: "LIST_CUSTOMER_ACCESS" });
                          });
                      }}
                    >
                      Deletar solicitação
                    </Button>
                  </Col>
                  <Col xs={18} sm={8}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={sending}
                    >
                      Liberar acesso
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" gutter={[30, 12]}>
                  <Col xs={18} sm={6}>
                    <Button
                      block
                      onClick={() => {
                        navigate("/admin/clientes/lista");
                        // dispatch({ type: "LIST_CUSTOMER" });
                      }}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col xs={18} sm={6}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={sending}
                    >
                      {editItems.customerId ? "Salvar" : "Cadastrar"}
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Form.Item>
        </>
      </Form>
    )}
    {showSellerModal && (
      <Modal
        title="Selecionar vendedor"
        open={showSellerModal}
        onOk={() => {
          setShowSellerModal(false);
        }}
        onCancel={() => {
          setShowSellerModal(false);
          setSelectedSellerUser({
            label: oldSellerUser.label,
            value: oldSellerUser.value,
          });
        }}
        okText="Trocar Vendedor"
      // cancelText="Cancelar"
      >
        <p>
          <b>{oldSellerUser.label}</b> é o atual vendedor associado a este
          cliente, deseja trocar para <b>{selectedSellerUser.label}</b>?
        </p>
      </Modal>
    )}
  </>);
}
