import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Skeleton, Empty, Space, Typography, notification } from "antd";
// import styles from "./cadastro.module.css";
import { useDispatch } from "react-redux";
import { axiosClient } from "../../apiClient";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Link, Title } = Typography;

export default function SellerListCartRule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);

  var postData = {
    search: "",
    page: "",
    perPage: -1,
  };

  useEffect(() => {
    axiosClient
      .post("/rules/cart/list", postData, { withCredentials: true })
      .then((out) => {
        setRules(out.data.rules);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: "Nome",
      ellipsis: true,
      responsive: ["sm"],
      render: (record) => <Link> {record.name} </Link>,
    },
    {
      title: "A quem se aplica",
      ellipsis: true,
      responsive: ["sm"],
      render: (record) => (
        <Space direction="vertical">
          {record.all_buyers && "Todos Clientes"}
          {record.buyers_tags.length > 0 && !record.all_buyers && "Por Tags"}
          {record.buyers.length > 0 &&
            !record.all_buyers &&
            "Clientes Específicos"}
          {(record.ceps_data && (record.ceps_data.ceps_full.length > 0 ||
            record.ceps_data.ceps_range.length > 0 ||
            record.ceps_data.ceps_start.length > 0) &&
            !record.all_buyers) &&
            "Por CEP"}
        </Space>
      ),
    },
    {
      title: "Ação",
      responsive: ["sm"],
      render: (record) => {
        if (record.action === "minimumOrder") return "Pedido Mínimo";
        else if (record.action === "freeShipping") return "Frete Grátis";
      },
    },
    {
      title: "Valor",
      responsive: ["sm"],
      render: (record) =>
        parseFloat(record.value).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
    },
    {
      title: "Regras",
      render: (record) => (
        <>
          <Link> {record.name} </Link>
          <br />
          <b>Clientes:</b>{" "}
          <Space split="e">
            {record.all_buyers && "Todos Clientes"}
            {record.buyers_tags.length > 0 && !record.all_buyers && "Por Tags"}
            {record.buyers.length > 0 &&
              !record.all_buyers &&
              "Clientes Específicos"}
          </Space>
          <br />
          <b>Ação:</b> {record.action === "minimumOrder" && "Pedido Mínimo"}
          {record.action === "freeShipping" && "Frete Grátis"}
          <br />
          <b>Valor:</b>{" "}
          <>
            {parseFloat(record.value).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })}
          </>
          <br />
        </>
      ),
      responsive: ["xs"],
      ellipsis: true,
    },
  ];

  return (
    <>
      <PageHeader
        onBack={() => window.history.back()}
        title={
          <>
            Regras de Carrinho{" "}
            <Link
              onClick={() =>
                notification.open({
                  duration: 20,
                  placement: "bottomRight",
                  message: <Title level={4}>Ajuda</Title>,
                  description:
                    "Com as regras de carrinho você pode definir pedido mínimo e frete grátis com base no valor total do carrinho do cliente.",
                })
              }
            >
              <QuestionCircleOutlined />
            </Link>
          </>
        }
        extra={
          <Button
            onClick={() => {
              navigate("/admin/catalogo/cadastro/regras-carrinho");
              // dispatch({ type: "CART_RULE_EDIT", cartRuleId: false });
              // dispatch({ type: "CREATE_CART_RULE" });
            }}
            type="primary"
          >
            Criar Regra de Carrinho
          </Button>
        }
      />
      <Table
        style={{ cursor: !loading ? "pointer" : "auto" }}
        columns={columns}
        dataSource={loading ? [] : rules}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/admin/catalogo/editar/regras-carrinho/${record.id}`);
              // dispatch({ type: "CART_RULE_EDIT", cartRuleId: record.id });
              // dispatch({ type: "CREATE_CART_RULE" });
            },
          };
        }}
      />
    </>
  );
}
