function initialState() {
  return { messageText: null, messageType: null };
}

export function message(state = initialState(), action) {
  switch (action.type) {
    case "SET_MESSAGE":
      return {
        ...state,
        messageText: action.messageText,
        messageType: action.messageType,
      };
    default:
      return state;
  }
}
