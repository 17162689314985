import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Skeleton, Empty, Space, Typography, notification } from "antd";
// import styles from "./cadastro.module.css";
import { useDispatch } from "react-redux";
import { axiosClient } from "../../apiClient";
import { QuestionCircleOutlined } from "@ant-design/icons";

const { Link, Title } = Typography;

export default function SellerListPaymentRule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);

  var postData = {
    search: "",
    page: "",
    perPage: -1,
  };

  useEffect(() => {
    axiosClient
      .post("/rules/payment/list", postData, { withCredentials: true })
      .then((out) => {
        setRules(out.data.rules);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: "Nome",
      ellipsis: true,
      responsive: ["sm"],
      render: (record) => <Link> {record.name} </Link>,
    },
    {
      title: "A quem se aplica",
      ellipsis: true,
      responsive: ["sm"],
      render: (record) => (
        <Space direction="vertical">
          {record.all_buyers && "Todos Clientes"}
          {record.buyers_tags.length > 0 && !record.all_buyers && "Por Tags"}
          {record.buyers.length > 0 &&
            !record.all_buyers &&
            "Clientes Específicos"}
          {(record.ceps_data && (record.ceps_data.ceps_full.length > 0 ||
            record.ceps_data.ceps_range.length > 0 ||
            record.ceps_data.ceps_start.length > 0) &&
            !record.all_buyers) &&
            "Por CEP"}
        </Space>
      ),
    },
    {
      title: "Métodos Permitidos",
      responsive: ["sm"],
      render: (record) =>
        record.payments.length > 0 ? record.payments.length : "Nenhum",
    },
    {
      title: "Regras",
      render: (record) => (
        <>
          <Link> {record.name} </Link>
          <br />
          <b>Clientes:</b>{" "}
          <Space split="e">
            {record.all_buyers && "Todos Clientes"}
            {record.buyers_tags.length > 0 && !record.all_buyers && "Por Tags"}
            {record.buyers.length > 0 &&
              !record.all_buyers &&
              "Clientes Específicos"}
          </Space>
          <br />
          <b>Métodos Permitidos:</b>{" "}
          {record.payments.length > 0 ? record.payments.length : "Nenhum"}
        </>
      ),
      responsive: ["xs"],
      ellipsis: true,
    },
  ];

  return (
    <>
      <PageHeader
        title={
          <>
            Regras de Pagamento{" "}
            <Link
              onClick={() =>
                notification.open({
                  duration: 20,
                  placement: "bottomRight",
                  message: <Title level={4}>Ajuda</Title>,
                  description:
                    "Com as regras de pagamento você pode configurar quais métodos de pagamento cada cliente terá disponível durante a compra.",
                })
              }
            >
              <QuestionCircleOutlined />
            </Link>
          </>
        }
        onBack={() => window.history.back()}
        extra={
          <Button
            onClick={() => {
              navigate("/admin/pagamento/cadastro/regra")
              // dispatch({ type: "PAYMENT_RULE_EDIT", paymentRuleId: false });
              // dispatch({ type: "CREATE_PAYMENT_RULE" });
            }}
            type="primary"
          >
            Criar Regra de Pagamento
          </Button>
        }
      />
      <Table
        style={{ cursor: !loading ? "pointer" : "auto" }}
        columns={columns}
        dataSource={loading ? [] : rules}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/admin/pagamento/editar/regra/${record.id}`)
              // dispatch({ type: "CREATE_PAYMENT_RULE" });
              // dispatch({ type: "PAYMENT_RULE_EDIT", paymentRuleId: record.id });
            }, // click row
          };
        }}
      />
    </>
  );
}
