import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageHeader } from '@ant-design/pro-layout';
import { Tabs, Form } from "antd";

import SellerIntegrations from "../../components/SellerIntegrations";
import SellerSubscription from "../../components/SellerSubscription";
import SellerProfile from "../../components/SellerProfile";
import SellerListUser from "../../components/SellerListUser";
import SellerCreateUser from "../../components/SellerCreateUser";
import SellerOptions from "../../components/SellerOptions";

const { TabPane } = Tabs;

const routes = [
  {
    path: "index",
    breadcrumbName: "Início",
  },
  {
    path: "first",
    breadcrumbName: "Configurações",
  },
];

export default function SellerConfiguration() {
  const dispatch = useDispatch();
  const [tab, setTab] = useState("1");
  const [userPage, setUserPage] = useState("listUser");
  const [userType, setUserType] = useState("representante_simples")
  const [userForm] = Form.useForm();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has("code") && setTab("4");
    console.log("params.has(code)", params.has("code"));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has("listusers") && setTab("2");
  }, []);

  return (
    <>
      {/* <PageHeader title="Configurações" breadcrumb={{ routes }} /> */}
      <Tabs
        onChange={(tabValue) => {
          setTab(tabValue);
          dispatch({ type: "USER_EDIT", userId: null });
          setUserPage("listUser");
          userForm.resetFields();
        }}
        activeKey={tab}
        size="large"
        centered
      >
        <TabPane tab="Dados Gerais" key="1">
          <SellerProfile />
        </TabPane>
        <TabPane tab="Representantes" key="2">
          {userPage === "listUser" && (
            <SellerListUser setUserPage={setUserPage} setUserType={setUserType} />
          )}
          {userPage === "createUser" && (
            <SellerCreateUser setUserPage={setUserPage} form={userForm} userType={userType} />
          )}
        </TabPane>
        <TabPane tab="Assinatura" key="3">
          <SellerSubscription />
        </TabPane>
        <TabPane tab="Integrações" key="4">
          <SellerIntegrations />
        </TabPane>
        <TabPane tab="Mais Opções" key="5">
          <SellerOptions />
        </TabPane>
      </Tabs>
    </>
  );
}
