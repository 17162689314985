import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from '@ant-design/pro-layout';
// import styles from "./cadastro.module.css";
import {
  Table,
  Skeleton,
  Empty,
  Button,
  Space,
  Tooltip,
  message,
  Col,
  Row,
  Typography,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { axiosClient } from "../../apiClient";
import { mascaraPhone, mascaraCnpj, mascaraCpf } from "../../functions/validation";

const { Link } = Typography;

export default function SellerListCustomer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [updateList, setUpdateList] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosClient
      .get("/auth/buyer/request/access/list", { withCredentials: true })
      .then((response) => {
        dispatch({ type: "SET_ACCESS_REQUEST", accessRequests: response.data.length });
        const values = response.data.map(v => ({ ...v, key: v.id }))
        setData(values);
        setLoading(false);
      });
  }, [updateList]);

  const columns = [
    {
      title: "Data",
      width: "10%",
      dataIndex: "data",
      responsive: ["sm"],
      render: (text, record) => (
        record.registration_date.split(" ")[0]
      )
    },
    {
      title: "Cliente",
      render: (text, record) => (
        <>
          {record.tipo_pessoa == "pf" ? (
            <Link>{record.nome}</Link>
          ) : (
            <Link>{record.razao_social}</Link>
          )}
        </>
      ),
      responsive: ["sm"],
    },
    {
      title: "CPF/CNPJ",
      width: "15%",
      render: (text, record) => (
        <>{record.cnpj ? mascaraCnpj(record.cnpj) : mascaraCpf(record.cpf)}</>
      ),
      responsive: ["sm"],
    },
    {
      title: "E-mail",
      width: "23%",
      responsive: ["sm"],
      render: (text, record) => (
        record.email
      )
    },
    {
      title: "Celular",
      width: "12%",
      responsive: ["sm"],
      render: (text, record) => (
        record.phone
      )
    },
    {
      title: "",
      align: "center",
      width: "10%",
      render: (record) => (
        <Space>
          <Tooltip title="Aprovar">
            <Button
              shape="circle"
              style={{ marginRight: "5px" }}
              icon={<CheckOutlined />}
              onClick={() => {
                setLoading(true)
                record.send_email = true
                axiosClient
                  .post("/auth/register/buyer", { ...record, selectedSellerUser: record.selleruser ? record.selleruser.id : null }, { withCredentials: true })
                  .then((response) => {
                    (response.data.status == "Success") &&
                      message.success('Solicitação de acesso aprovada com sucesso.', 4)
                    axiosClient
                      .post("/auth/buyer/request/access/delete", record, { withCredentials: true })
                      .then(() => setUpdateList(updateList + 1))
                  })
              }
              }>
            </Button>
          </Tooltip>
          <Tooltip title="Rejeitar">
            <Button
              style={{ marginLeft: "5px" }}
              danger
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => {
                setLoading(true)
                axiosClient
                  .post("/auth/buyer/request/access/delete", record, { withCredentials: true })
                  .then((response) => {
                    (response.data.status == "Success") &&
                      message.success('Solicitação de acesso deletada com sucesso.', 4)
                    setUpdateList(updateList + 1)
                  })
              }}>
            </Button>
          </Tooltip>
        </Space>
      ),
      responsive: ["sm"],
    },
    {
      align: "left",
      title: "Solicitações",
      render: (record) => (
        <>

          <>{record.registration_date.split(" ")[0]}</>
          <br />
          <>
            {record.tipo_pessoa == "pf" ? (
              <Link>{record.nome}</Link>
            ) : (
              <Link>{record.razao_social}</Link>
            )}
          </>
          <br />
          <>{record.phone}</>
          <br />
          {record.cnpj ? mascaraCnpj(record.cnpj) : mascaraCpf(record.cpf)}
          <br />
        </>
      ),
      responsive: ["xs"],
    },
    {
      align: "center",
      title: "",
      render: (record) => (
        <Space direction="vertical">
          <Tooltip title="Aprovar">
            <Button
              shape="round"
              icon={<CheckOutlined />}
              block
              onClick={() => {
                setLoading(true)
                record.send_email = true
                axiosClient
                  .post("/auth/register/buyer", record, { withCredentials: true })
                  .then((response) => {
                    (response.data.status == "Success") &&
                      message.success('Solicitação de acesso aprovada com sucesso.', 4)
                    axiosClient
                      .post("/auth/buyer/request/access/delete", record, { withCredentials: true })
                      .then(() => setUpdateList(updateList + 1))
                  })
              }}>
              Aprovar
            </Button>
          </Tooltip>
          <Tooltip title="Rejeitar">
            <Button
              danger
              shape="round"
              icon={<CloseOutlined />}
              block
              onClick={() => {
                setLoading(true)
                axiosClient
                  .post("/auth/buyer/request/access/delete", record, { withCredentials: true })
                  .then((response) => {
                    (response.data.status == "Success") &&
                      message.success('Solicitação de acesso deletada com sucesso.', 4)
                    setUpdateList(updateList + 1)
                  })
              }}>
              Negar
            </Button>
          </Tooltip>
        </Space>
      ),
      responsive: ["xs"],
    }
  ];

  return (
    <>
      <PageHeader title="Solicitações de Acesso" onBack={() => window.history.back()} />
      <Table
        columns={columns}
        // size="small"
        dataSource={loading ? [] : data}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
        style={{ cursor: !loading ? "pointer" : "auto" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (
                event.target.type != "button" &&
                event.target.tagName != "path" &&
                event.target.tagName != "svg" &&
                event.target.tagName != "SPAN" &&
                event.target.hasAttribute("data-icon") == false
              ) {
                navigate(`/admin/clientes/cadastro/solicitacao`, { state: record })
                // dispatch({ type: "CUSTOMER_ACCESS_REQUEST_EDIT", customerId: 'request', customerData: record });
                // dispatch({ type: "CREATE_CUSTOMER" });
              }
            },
          };
        }}
      />
    </>
  );
}
