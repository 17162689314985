function initialState() {
  return { customerId: false, productId: false, ruleId: false };
}

export function editItems(state = initialState(), action) {
  switch (action.type) {
    case "CUSTOMER_EDIT":
      return { ...state, customerId: action.customerId };
    case "CUSTOMER_ACCESS_REQUEST_EDIT":
      return {
        ...state,
        customerId: action.customerId,
        customerData: action.customerData,
      };
    case "PRODUCT_EDIT":
      return { ...state, productId: action.productId };
    case "PRODUCT_RULE_EDIT":
      return { ...state, productRuleId: action.productRuleId };
    case "CART_RULE_EDIT":
      return { ...state, cartRuleId: action.cartRuleId };
    case "SHIPPING_RULE_EDIT":
      return { ...state, shippingRuleId: action.shippingRuleId };
    case "PAYMENT_RULE_EDIT":
      return { ...state, paymentRuleId: action.paymentRuleId };
    case "PAYMENT_EDIT":
      return { ...state, paymentId: action.paymentId };
    case "SHIPPING_EDIT":
      return { ...state, shippingId: action.shippingId };
    case "ORDER_EDIT":
      return { ...state, orderId: action.orderId };
    case "CART_EDIT":
      return { ...state, cartId: action.cartId };
    case "USER_EDIT":
      return { ...state, userId: action.userId };

    default:
      return state;
  }
}
