import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./qtychanger.module.css";
import { Input } from "antd";

export default function QtyChangerRow(props) {
  const dispatch = useDispatch();
  const searchOnFocus = useSelector((state) => state.searchState.onFocus);

  function updateValue(e) {
    let value = e.target.value.replace(/\D/g, "");
    dispatch({
      type: "SET_QUANTITY",
      product: props.product,
      quantity: value ? parseInt(value) : 0,
    });
  }

  function detectMob() {
    return window.innerWidth <= 800;
  }

  useEffect(() => {
    if (props.rowOnFocus == props.myIndex) {
      if (
        props.product.product_stock == 0 ||
        props.product.product_stock - props.qtyInCart == 0
      ) {
        props.setRowOnFocus(props.rowOnFocus + 1);
      } else {
        if (!detectMob() && !searchOnFocus) {
          props.handleFocus();
        }
      }
    }
  }, [props.rowOnFocus]);

  return (
    <div className={styles["pqt-geral"]}>
      <span
        onClick={() => {
          dispatch(
            props.func(props.product, props.qtyInPage, props.qtyInCart, -1)
          );
        }}
        className={[styles["pqt-minus"], styles["noselect"]].join(" ")}
      >
        -
      </span>
      <Input
        ref={props.rowOnFocus == props.myIndex ? props.inputRef : null}
        onFocus={(e) => {
          e.target.focus();
        }}
        onPressEnter={() => {
          props.setRowOnFocus(props.rowOnFocus + 1);
          props.qtyInPage &&
            props.updateCart(props.product, props.qtyInPage, props.qtyInCart);
        }}
        pattern="\d*"
        onChange={(e) => updateValue(e)}
        style={{ minWidth: 50, width: 50, textAlign: "center" }}
        value={props.qtyInPage}
        disabled={
          props.product.product_stock == 0 ||
          props.product.product_stock - props.qtyInCart == 0
            ? true
            : false
        }
      />
      <span
        onClick={() => {
          dispatch(
            props.func(props.product, props.qtyInPage, props.qtyInCart, 1)
          );
        }}
        className={[styles["pqt-plus"], styles["noselect"]].join(" ")}
      >
        +
      </span>
    </div>
  );
}
