import styles from "./index.module.css";
import {
  Skeleton,
  Card,
  Drawer,
  Button,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { axiosClient } from "../../apiClient";
// import { message } from "../../reducers/message";

const { Meta } = Card;

export default function IntegrationPagseguro() {
  const [loading, setLoading] = useState(true);
  const [waitingConf, setWaitingConf] = useState(false);
  const [pagseguroUrl, setPagseguroUrl] = useState("#");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [userClicked, setUserClicked] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState(false);

  const refreshToken = () => {
    setLoading(true);
    axiosClient
      .post(
        "/integrations/pagseguro/refresh-token",
        { sellerUrl: window.location.origin },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response);
        setLoading(false);
        response.data.status === "Success"
          ? setIntegrationStatus(true)
          : setIntegrationStatus(false);
        response.data.status === "Error" &&
          setPagseguroUrl(response.data.pagseguro_url);
      });
  };

  const revokeIntegration = () => {
    setLoading(true);
    axiosClient
      .get("/integrations/pagseguro/revoke", { withCredentials: true })
      .then((response) => {
        refreshToken();
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("code") && !userClicked) {
      console.log("has code");
      setDrawerVisible(true);
      setWaitingConf(true);
      let code = params.get("code");
      axiosClient
        .post(
          "/integrations/pagseguro/access-token",
          { code: code },
          { withCredentials: true }
        )
        .then((response) => {
          console.log(response);
          setWaitingConf(false);
          window.history.replaceState({}, document.title, "/admin");
          if (response.data.status === "Success"){
            refreshToken();
          } else {
            setDrawerVisible(false)
            // message.error("Erro realizar integração", 4);
          }
        });
    } else if (userClicked) {
      refreshToken();
    }
  }, [userClicked]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
    setUserClicked(false);
  };

  return (<>
    <Card
      style={{ width: 300 }}
      cover={
        <img
          alt="pagseguro"
          style={{
            width: "82%",
            margin: "auto",
            marginTop: "10px",
          }}
          src="https://storage.googleapis.com/nupedido-public-bucket/static/images/integrations/pagseguro.png"
        />
      }
      actions={[
        <Button
          onClick={() => {
            setUserClicked(true);
            showDrawer();
          }}
        >
          Gerenciar
        </Button>,
        // "Em breve"
      ]}
    >
      <Meta
        title="Pagseguro"
        description="Disponibilizar pagamentos digitais para seus clientes através do Pagseguro."
      />
    </Card>
    <Drawer
      title="Gerenciamento de integração - Pagseguro"
      placement={"bottom"}
      height={window.innerWidth > 575 ? "360px" : "480px"}
      onClose={onClose}
      open={drawerVisible}
    >
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {!integrationStatus ? (
            <>
              Status: Desativado
              <br />
              <Button
                href={pagseguroUrl}
                type="primary"
                loading={waitingConf}
              >
                Vincular Conta
              </Button>
            </>
          ) : (
            <>
              Status: Ativo
              <br />
              <Button
                onClick={() => revokeIntegration()}
                type="primary"
                loading={waitingConf}
              >
                Remover Integração
              </Button>
            </>
          )}
        </>
      )}

      {/* <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter user name' }]}
            >
              <Input placeholder="Please enter user name" />
            </Form.Item>
          </Col>
        </Row>
      </Form> */}
    </Drawer>
  </>);
}
