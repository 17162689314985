import React, { useState, useEffect } from "react";
import {
  Radio,
  Divider,
  Space,
  Alert,
  Row,
  Col,
  Typography,
  Card,
  Form,
  Input,
  Skeleton,
  Select,
  Button,
} from "antd";
// import styles from "./cadastro.module.css";
import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../../apiClient";
import Cards from "react-credit-cards-2";
// import "react-credit-cards-2/es/styles-compiled.css";
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import { openDB } from 'idb';

const { Paragraph } = Typography;
const { Option } = Select;

export default function BuyerListPayment(props) {
  const dispatch = useDispatch();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [radioSelected, setRadioSelected] = useState(false);

  const [paymentSelected, setPaymentSelected] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [option, setOption] = useState(1);
  const [valorTotal, setValorTotal] = useState(0);

  const [mercadopagoPublicKey, setMercadoPagoPublicKey] = useState(null);
  const [mercadopagoInit, setMercadopagoInit] = useState(false)
  const [mercadopago, setMercadopago] = useState(false);

  const [buyerData, setBuyerData] = useState({});
  const [updatePayments, setUpdatePayments] = useState(0);

  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);
  const [loading, setLoading] = useState(true);
  const [cardData, setCardData] = useState({
    cvc: "",
    expiry: "",
    focused: "name",
    name: "",
    number: "",
  });

  const shippingMethod = useSelector((state) => state.order.shippingMethod);
  const paymentMethod = useSelector((state) => state.order.paymentMethod);
  const cartProducts = useSelector((state) => state.cart.products);
  const cartReturned = useSelector((state) => state.cart.returned);
  const syncData = useSelector((state) => state.sync);

  async function getSingleData(dBStoreName, idx) {
    const db = await openDB('nupedido', 1);
    const value = await db.get(dBStoreName, idx);
    return value
  }

  useEffect(() => {
    if (
      logged === "buyer" ||
      ((logged === "seller" || logged === "sellerUser") && buyerView)
    )
      axiosClient
        .post(
          `/buyer-info`,
          { buyerView: buyerView },
          { withCredentials: true }
        )
        .then((response) => {
          setBuyerData(response.data);
        });
  }, []);

  useEffect(() => {
    let values = props.form.getFieldsValue();
    // console.log(props)
  }, [props]);

  useEffect(() => {
    setLoading(true);
    setPaymentSelected(false);
    setMercadopagoInit(false);
    setMercadopago(false)
    dispatch({
      type: "ADD_PAYMENT",
      shippingMethod: null,
    });
  }, [cartProducts]);

  useEffect(() => {
    setLoading(true);
    setPaymentSelected(false);
    setMercadopagoInit(false);
    setMercadopago(false)
    dispatch({
      type: "ADD_PAYMENT",
      shippingMethod: null,
    });
    setUpdatePayments(updatePayments + 1)
  }, [shippingMethod]);

  // MERCADOPAGO SETUP
  useEffect(() => {
    axiosClient
      .get("/integrations/mercadopago/get-public-key", { withCredentials: true })
      .then((response) => {
        response.data.status == "Success" &&
          setMercadoPagoPublicKey(response.data.public_key);
      });
  }, []);

  useEffect(() => {
    if (paymentSelected) {
      if (mercadopagoPublicKey && paymentSelected.metodo === "mercadopago_credit_card" && mercadopagoInit === false) {
        initMercadoPago(mercadopagoPublicKey)
        setMercadopagoInit(0)
      }
    }
  }, [mercadopagoPublicKey, paymentSelected]);

  useEffect(() => {
    if (mercadopagoInit === 0) {
      setMercadopagoInit(mercadopagoInit + 1)
      // console.log(parseFloat(valorTotal).toFixed(2))
      var customer
      buyerView ? customer = buyerView : customer = buyerData
      const initialization = {
        amount: parseFloat(valorTotal).toFixed(2),
        payer: {
          email: customer.email,
          identification: {
            type: customer.tipo_pessoa === "pf" ? "CPF" : "CNPJ",
            number: customer.tipo_pessoa === "pf" ? customer.cpf : customer.cnpj
          }
        },
      }
      // console.log(paymentSelected)
      const customization = {
        paymentMethods: {
          minInstallments: 1,
          maxInstallments: paymentSelected.aditional_features.max_installments,
          types: {
            excluded: ['debit_card']
          },
        },
        cardholderName: {
          label: "",
          placeholder: "Nome impresso no cartão"
        },
        visual: {
          style: {
            theme: 'light',
          },
          texts: {
            formSubmit: "Finalizar pedido",
            formTitle: "Cartão de crédito",
          },
          // hidePaymentButton: true,
        },
      };
      setMercadopago(<CardPayment
        initialization={initialization}
        customization={customization}
        onReady={() => { }}
        onSubmit={async (param) => {
          props.sendOrderFunc(param)
        }}
      />)
    }
  }, [mercadopagoInit])

  useEffect(() => {
    if (paymentSelected.metodo === "pagseguro_credit_card" || paymentSelected.metodo === "mercadopago_credit_card") {
      renderInstallmentsOptions();
    }
  }, [valorTotal]);

  useEffect(() => {
    setOption(1);
  }, [installments]);

  useEffect(() => {
    var total = 0;
    if (cartProducts.length) {
      total = cartProducts.map(amount).reduce(sum);
    }
    if (shippingMethod) {
      total = parseFloat(total) + parseFloat(shippingMethod.valor);
    }
    if (paymentSelected.metodo === "pagseguro_credit_card" || paymentSelected.metodo === "mercadopago_credit_card") {
      total =
        parseFloat(total) +
        parseFloat(paymentSelected.aditional_features.fix_tax);
      renderInstallmentsOptions();
    }
    setValorTotal(total);
  }, [paymentSelected, shippingMethod, cartProducts]);

  useEffect(() => {
    if (paymentSelected) {
      if (paymentSelected.metodo === "mercadopago_credit_card") {
        props.setUseFinalizarPedidoButton(false)
      }
      else {
        props.setUseFinalizarPedidoButton(true)
      }
    }
  }, [paymentSelected])

  useEffect(() => {
    if (buyerView || logged == "buyer") {
      setRadioSelected(false);
      dispatch({
        type: "ADD_PAYMENT",
        shippingMethod: null,
      });
      if (syncData.onlineStatus) {
        axiosClient
          .post(
            "/payment/buyer/get",
            { buyerId: buyerView ? buyerView.id : null },
            { withCredentials: true }
          )
          .then((response) => {
            // console.log(response)
            setPaymentOptions(response.data.payments);
            setLoading(false);
          });
      } else if (syncData.syncStatus === "synced") {
        getSingleData("config", 4).then((data) => {
          setPaymentOptions(data.payments);
          setLoading(false);
        })
      }
    }
  }, [buyerView, cartReturned, updatePayments]);

  function amount(item) {
    return item.product_price_sale
      ? parseFloat(item.product_price_sale * item.quantity).toFixed(2)
      : parseFloat(item.product_price * item.quantity).toFixed(2);
  }

  function sum(prev, next) {
    return parseFloat(prev) + parseFloat(next);
  }

  function renderInstallmentsOptions() {
    var options = [];
    for (
      var i = 1;
      i <= parseInt(paymentSelected.aditional_features.max_installments);
      i++
    ) {
      let installment = getInstallmentValue(i);
      options.push(
        <Option value={i} key={i}>
          {installment[1]}
        </Option>
      );
    }
    setInstallments(options);
    installmentsSelected(1);
  }

  function installmentsSelected(e) {
    let installment = getInstallmentValue(parseInt(e));
    dispatch({
      type: "ADD_PAYMENT",
      paymentMethod: {
        ...paymentMethod,
        installments_selected: parseInt(e),
        total_value: parseFloat(
          parseFloat(installment[0]) * parseInt(e)
        ).toFixed(2),
        total_value_installment: parseFloat(installment[0]).toFixed(2),
      },
      paymentCost: 0,
    });
  }

  function getInstallmentValue(i) {
    let free_installments = parseInt(
      paymentSelected.aditional_features.tax_free_installments
    );
    let tax =
      parseFloat(paymentSelected.aditional_features.tax_per_installments) / 100;
    if (i <= free_installments) {
      var installment = parseFloat(valorTotal / i);
      var text = `${i}x R$ ${installment.toFixed(2)} (sem juros)`;
    } else {
      var installment = parseFloat(
        (valorTotal * Math.pow(1 + tax, i - free_installments)) / i
      );
      var text = `${i}x R$ ${installment.toFixed(2)}`;
    }
    return [installment, text];
  }

  const onChange = (e) => {
    setRadioSelected(e.target.value);
    setPaymentSelected(paymentOptions[e.target.value]);
    dispatch({
      type: "ADD_PAYMENT",
      paymentMethod: paymentOptions[e.target.value],
      paymentCost: 0,
    });
  };

  function cc_format(value) {
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  }

  function correctName(name) {
    let prefix = "Por favor, insira o ";
    switch (name) {
      case "cardName":
        return prefix + "Nome no cartão";
      case "cardNumber":
        return prefix + "Número do cartão";
      case "securityCode":
        return prefix + "Código de segurança";

      default:
        return "Campo Obrigatório";
    }
  }

  return (
    <>
      <Divider orientation="left">Métodos de Pagamento</Divider>
      {loading ? (
        <Skeleton.Input style={{ width: 300 }} active />
      ) : (
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          <Row justify="start">
            <Col span={24}>
              {!loading && (
                <Card size="small">
                  <Radio.Group onChange={onChange} value={radioSelected}>
                    <Space direction="vertical">
                      {!loading &&
                        paymentOptions &&
                        paymentOptions.map(function (item, i) {
                          return (
                            <Radio key={item.id} value={i}>
                              {item.descricao}
                            </Radio>
                          );
                        })}
                      {!loading && paymentOptions.length === 0 && (
                        <Alert
                          banner
                          message="Nenhum método de pagamento disponível. "
                          type="warning"
                        />
                      )}
                    </Space>
                  </Radio.Group>
                </Card>
              )}
            </Col>
          </Row>
          {(paymentSelected.metodo === "pagseguro_credit_card") && (
            <>
              <Row>
                <Col span={24}>
                  <Card title="Cartão de Crédito" size="small">
                    <Cards
                      cvc={cardData.cvc}
                      expiry={cardData.expiry}
                      focused={cardData.focused}
                      name={cardData.name}
                      number={cardData.number}
                      placeholders={{ name: "Nome no Cartão" }}
                    />
                    <br />
                    <Form
                      form={props.form}
                      scrollToFirstError={true}
                      name="nest-messages"
                      validateMessages={{
                        required: (name) => correctName(name),
                      }}
                    >
                      <Form.Item
                        name={["cardNumber"]}
                        rules={[
                          {
                            type: "string",
                            required: true,
                            max: 100,
                          },
                        ]}
                        onChange={(e) => {
                          let cardNumber = cc_format(
                            e.target.value.replace(/\D/g, "")
                          );

                          props.form.setFieldsValue({
                            cardNumber: cardNumber,
                          });
                          setCardData({
                            ...cardData,
                            number: cardNumber,
                            focused: "number",
                          });
                        }}
                      >
                        <Input
                          onFocus={() => {
                            setCardData({
                              ...cardData,
                              focused: "number",
                            });
                          }}
                          placeholder="Número do Cartão"
                        />
                      </Form.Item>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={["cardName"]}
                            rules={[
                              {
                                type: "string",
                                required: true,
                                max: 22,
                              },
                            ]}
                            onChange={(e) => {
                              let cardName = e.target.value
                                .replace(/[^A-Za-z ]+/g, "")
                                .substring(0, 22);
                              props.form.setFieldsValue({
                                cardName: cardName,
                              });
                              setCardData({
                                ...cardData,
                                name: cardName,
                                focused: "name",
                              });
                            }}
                          >
                            <Input
                              onFocus={() => {
                                setCardData({
                                  ...cardData,
                                  focused: "name",
                                });
                              }}
                              placeholder="Nome no Cartão"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={["numberinstallments"]}
                            initialValue={option}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              onChange={installmentsSelected}
                              placeholder="Parcelas"
                              defaultValue={option}
                            >
                              {installments}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            name={["cardExpiry"]}
                            initialValue=""
                            // rules={[
                            //   {
                            //     type: "string",
                            //     required: true,
                            //     max: 5,
                            //   },
                            // ]}
                            onChange={(e) => {
                              let expiry = e.target.value
                                .replace(/\D/g, "")
                                .substring(0, 4);
                              let month = expiry.substring(0, 2);
                              let year = expiry.substring(2, 4);
                              var today = new Date();

                              props.form.setFieldsValue({
                                cardExpiry: year ? month + "/" + year : month,
                              });

                              if (month.length === 2 && parseInt(month) > 12) {
                                props.form.setFields([
                                  {
                                    name: "cardExpiry",
                                    value: year ? month + "/" + year : month,
                                    errors: ["Mês incorreto"],
                                  },
                                ]);
                              } else {
                                props.form.setFields([
                                  {
                                    name: "cardExpiry",
                                    value: year ? month + "/" + year : month,
                                    errors: [],
                                  },
                                ]);

                                if (year.length === 2) {
                                  let expireDate = new Date(
                                    "20" + year + "-" + month
                                  );
                                  expireDate.setMonth(
                                    expireDate.getMonth() + 1
                                  );
                                  !(today < expireDate) &&
                                    props.form.setFields([
                                      {
                                        name: "cardExpiry",
                                        value: year
                                          ? month + "/" + year
                                          : month,
                                        errors: ["Cartão vencido"],
                                      },
                                    ]);
                                }
                              }

                              setCardData({
                                ...cardData,
                                expiry: expiry,
                                focused: "expiry",
                              });
                            }}
                          >
                            <Input
                              onFocus={() => {
                                setCardData({
                                  ...cardData,
                                  focused: "expiry",
                                });
                              }}
                              placeholder="Validade"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={["securityCode"]}
                            rules={[
                              {
                                type: "string",
                                required: true,
                                max: 4,
                              },
                            ]}
                            onChange={(e) => {
                              let cvc = e.target.value
                                .replace(/\D/g, "")
                                .substring(0, 4);

                              props.form.setFieldsValue({
                                securityCode: cvc,
                              });
                              setCardData({
                                ...cardData,
                                cvc: cvc,
                                focused: "cvc",
                              });
                            }}
                          >
                            <Input
                              onFocus={() => {
                                setCardData({
                                  ...cardData,
                                  focused: "cvc",
                                });
                              }}
                              placeholder="Código de Segurança"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </>
          )}
          {(paymentSelected.metodo === "mercadopago_credit_card") && (
            (mercadopago === false) ?
              <Skeleton></Skeleton> :
              mercadopago
          )}
          {paymentSelected.instructions && (
            <Row>
              <Col span={24}>
                <Card title="Instruções" size="small">
                  <Paragraph style={{ whiteSpace: "pre-wrap" }}>
                    {paymentSelected.instructions}
                  </Paragraph>
                </Card>
              </Col>
            </Row>
          )}
        </Space>
      )}
    </>
  );
}
