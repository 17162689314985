function initialState() {
  return ({ dataBase: null })
}

export function indexedDB(state = initialState(), action) {
  switch (action.type) {
    case 'SET_DATABASE':
      return { ...state, dataBase: action.dataBase }
    default:
      return state
  }
}