import React, { useEffect, useState } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Tag, Input, Button, Typography, Card, Alert, Skeleton, Drawer } from "antd";
import { TweenOneGroup } from "rc-tween-one";
import {
  PlusOutlined,
  HighlightOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { axiosClient } from "../../apiClient";

const { Title } = Typography;

export default function SellerListProductTag() {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [inputField, setInputField] = useState(false);

  const renameTagGroup = (item, newTitle) => {
    setRenaming(true);
    axiosClient
      .post(
        "/products/tag_group/rename",
        { groupId: item.id, newTitle: newTitle },
        { withCredentials: true }
      )
      .then((response) => {
        setState(response.data.tags);
        setRenaming(false);
      });
  };

  useEffect(() => {
    axiosClient
      .get("/products/tag/list", { withCredentials: true })
      .then((response) => {
        setState(response.data.tags);
        setLoading(false);
      });
  }, []);

  useEffect(() => { inputField && inputField.focus() }, [inputField]);

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag.title}
      </Tag>
    );
    return (
      <span key={tag.id} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  const handleClose = (removedTag) => {
    axiosClient
      .post(
        "/products/tag/delete",
        { tagId: removedTag.id },
        { withCredentials: true }
      )
      .then((response) => {
        setState(response.data.tags);
      });
  };

  const showInput = (item) => {
    let newItem = item;
    newItem.inputVisible = true;
    let filteredGroups = state.filter(function (group) {
      return group.id !== item.id;
    });
    filteredGroups.push(newItem);
    setState(filteredGroups);
  };

  const handleInputChange = (item, e) => {
    let newItem = item;
    newItem.inputValue = e.target.value;
    let filteredGroups = state.filter(function (group) {
      return group.id !== item.id;
    });
    filteredGroups.push(newItem);
    setState(filteredGroups);
  };

  const handleInputConfirm = (item) => {
    if (item.inputValue) {
      axiosClient
        .post(
          "/products/tag/create",
          { groupId: item.id, title: item.inputValue },
          { withCredentials: true }
        )
        .then((response) => {
          setState(response.data.tags);
        });
    } else {
      let newItem = item;
      newItem.inputVisible = false;
      let filteredGroups = state.filter(function (group) {
        return group.id !== item.id;
      });
      filteredGroups.push(newItem);
      setState(filteredGroups);
    }
  };

  const createTagGroup = () => {
    setLoading(true);
    let value = inputValue;
    axiosClient
      .post(
        "/products/tag_group/create",
        { title: value },
        { withCredentials: true }
      )
      .then((response) => {
        setState(response.data.tags);
        setInputValue("");
        setDrawerVisible(false);
        setLoading(false);
      });
  };

  const deleteTagGroup = (item) => {
    setDeleting(true);
    axiosClient
      .post(
        "/products/tag_group/delete",
        { groupId: item.id },
        { withCredentials: true }
      )
      .then((response) => {
        setState(response.data.tags);
        setDeleting(false);
      });
  };

  const saveInputRef = (input) => {
    setInputField(input);
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (<>
    <PageHeader
      title={"Tags de Produtos"}
      onBack={() => window.history.back()}
      extra={
        <Button onClick={() => setDrawerVisible(true)} type="primary">
          Adicionar Grupo de Tags
        </Button>
      }
    />
    {loading ? (
      <Skeleton active />
    ) : (
      <>
        {state.length === 0 && (
          <Card>
            <Alert
              message="Você ainda não tem tags cadastradas."
              type="info"
              showIcon
            />
          </Card>
        )}
        {state.map(function (item, i) {
          const tagChild = item.tags.map(forMap);
          return (
            <div key={item.id}>
              <Card
                size="small"
                title={
                  renaming ? (
                    <Skeleton.Input style={{ width: 150 }} active />
                  ) : (
                    <Title
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: "Clique para renomear tag",
                        onChange: (newTitle) =>
                          renameTagGroup(item, newTitle),
                      }}
                      level={5}
                    >
                      {item.title}
                    </Title>
                  )
                }
                extra={
                  deleting ? (
                    <LoadingOutlined
                      style={{
                        display: "block",
                        fontSize: "16px",
                        color: "#08c",
                      }}
                    />
                  ) : (
                    <DeleteOutlined
                      item={item}
                      onClick={(e) => deleteTagGroup(item)}
                      style={{
                        display: "block",
                        fontSize: "16px",
                        color: "#08c",
                      }}
                    />
                  )
                }
              >
                <TweenOneGroup
                  enter={{
                    scale: 0.8,
                    opacity: 0,
                    type: "from",
                    duration: 100,
                  }}
                  onEnd={(e) => {
                    if (e.type === "appear" || e.type === "enter") {
                      e.target.style = "display: inline-block";
                    }
                  }}
                  leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                  appear={false}
                >
                  {tagChild}
                </TweenOneGroup>
                <br />
                {item.inputVisible && (
                  <Input
                    ref={saveInputRef}
                    type="text"
                    size="small"
                    style={{ width: 78 }}
                    value={item.inputValue}
                    onChange={(e) => handleInputChange(item, e)}
                    onBlur={() => handleInputConfirm(item)}
                    onPressEnter={() => handleInputConfirm(item)}
                  />
                )}
                {!item.inputVisible && (
                  <Tag
                    onClick={() => showInput(item)}
                    style={{
                      background: "#fff",
                      borderStyle: "dashed",
                    }}
                  >
                    <PlusOutlined /> Nova Tag
                  </Tag>
                )}
              </Card>
              <br />
            </div>
          );
        })}
      </>
    )}
    <Drawer
      title="Título do Grupo de Tags"
      placement="bottom"
      height={200}
      onClose={onClose}
      open={drawerVisible}
      extra={
        <Button
          type="primary"
          loading={loading}
          onClick={() => createTagGroup()}
        >
          Salvar
        </Button>
      }
    >
      <Input
        value={inputValue}
        loading={loading}
        onChange={(value) => setInputValue(value.target.value)}
        onKeyDown={(e) => {
          (e.code === "Enter" || e.code === "NumpadEnter") &&
            createTagGroup();
        }}
        placeholder="Examplo: Fornecedores"
      />
    </Drawer>
  </>);
}
