import React, { useState, useEffect } from "react";
import {
  Radio,
  Divider,
  Space,
  Alert,
  Row,
  Col,
  Typography,
  Card,
  Skeleton,
} from "antd";
// import styles from "./cadastro.module.css";
import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../../apiClient";
import { openDB } from 'idb';

const { Paragraph } = Typography;

export default function BuyerListShipping(props) {
  const dispatch = useDispatch();
  const [shippingOptions, setShippingOptions] = useState([]);
  const [radioSelected, setRadioSelected] = useState(false);
  const activeCEP = useSelector((state) => state.activeCEP);
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);
  const [loading, setLoading] = useState(true);
  const cartProducts = useSelector((state) => state.cart.products);
  const cartReturned = useSelector((state) => state.cart.returned);
  const syncData = useSelector((state) => state.sync);

  async function getSingleData(dBStoreName, idx) {
    const db = await openDB('nupedido', 1);
    const value = await db.get(dBStoreName, idx);
    return value
  }

  useEffect(() => {
    if ((buyerView || logged == "buyer") && (activeCEP.value || (!syncData.onlineStatus && syncData.syncStatus === "synced")) && cartProducts.length) {
      setRadioSelected(false);
      dispatch({
        type: "ADD_SHIPPING",
        shippingMethod: null,
      });
      setLoading(true);
      if (cartReturned) {
        if (syncData.onlineStatus) {
          axiosClient
            .post(
              "/shipping/buyer/get",
              {
                activeCEP: activeCEP.value,
                buyerId: buyerView ? buyerView.id : null,
                uf: activeCEP.uf,
              },
              { withCredentials: true }
            )
            .then((response) => {
              setShippingOptions(response.data.shippings);
              setLoading(false);
            });
        } else if (syncData.syncStatus === "synced") {
          getSingleData("config", 3).then((data) => {
            setShippingOptions(data.shippings);
            setLoading(false);
          })
        }
      }
    }
  }, [syncData.onlineStatus, syncData.syncStatus, activeCEP, buyerView, cartReturned, cartProducts]);

  const onChange = (e) => {
    setRadioSelected(e.target.value);
    props.setShippingSelected(shippingOptions[e.target.value]);
    dispatch({
      type: "ADD_SHIPPING",
      shippingMethod: shippingOptions[e.target.value],
    });
  };

  return (
    <>
      <Divider orientation="left">Métodos de Entrega</Divider>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {loading && activeCEP.value && (
          <Skeleton.Input style={{ width: 300 }} active />
        )}
        {!loading && (activeCEP.value || (!syncData.onlineStatus && syncData.syncStatus === "synced")) && (
          <Row justify="start">
            <Col span={24}>
              <Card size="small">
                <Radio.Group onChange={onChange} value={radioSelected}>
                  <Space direction="vertical">
                    {shippingOptions &&
                      shippingOptions.map(function (item, i) {
                        let diaOrDias = false;
                        let prazo = false;
                        if (item.prazo) {
                          prazo =
                            parseInt(item.prazo) + parseInt(item.days_add);
                          diaOrDias = prazo > 1 ? "dias" : "dia";
                        }

                        return (
                          <Radio key={item.id} value={i}>
                            {item.nome}{" "}
                            {item.valor > 0 &&
                              ` - ${parseFloat(item.valor).toLocaleString(
                                "pt-BR",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )}`}{" "}
                            {prazo &&
                              " - Entrega em " + prazo + " " + diaOrDias}
                          </Radio>
                        );
                      })}
                    {shippingOptions.length === 0 && (
                      <Alert
                        banner
                        message="Nenhum método de entrega disponível. "
                        type="warning"
                      />
                    )}
                  </Space>
                </Radio.Group>
              </Card>
            </Col>
          </Row>
        )}
        {!(activeCEP.value || (!syncData.onlineStatus && syncData.syncStatus === "synced")) && (
          <Alert
            banner
            message="Preencha seu endereço para ver os métodos de entrega disponíveis."
            type="warning"
          />
        )}
        {props.shippingSelected.instructions && (
          <Row>
            <Col span={24}>
              <Card title="Instruções" size="small">
                <Paragraph style={{ whiteSpace: "pre-wrap" }}>
                  {props.shippingSelected.instructions}
                </Paragraph>
              </Card>
            </Col>
          </Row>
        )}
      </Space>
    </>
  );
}
