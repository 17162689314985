function initialState() {

  let emptyDiscrepancies = {
    idsNotInFrontend: null,
    idsOutdated: null,
    idsNeedSubmit: null
  }

  return ({
    onlineStatus: false,
    syncStatus: "disabled",
    syncDrawer: false,
    syncStore: null,
    syncStep: null,
    syncIterationStep: 0,
    syncIterationTotal: null,

    productLastMod: null,
    buyerLastMod: null,
    cartLastMod: null,

    productDiscrepancies: emptyDiscrepancies,
    buyerDiscrepancies: emptyDiscrepancies,
    cartDiscrepancies: emptyDiscrepancies,
    orderDiscrepancies: emptyDiscrepancies,

    productSynced: false,
    buyerSynced: false,
    cartSynced: false,
  })
}

export function sync(state = initialState(), action) {
  switch (action.type) {
    case "SET_SYNC":
      return {
        ...state,
        ...('onlineStatus' in action && { onlineStatus: action.onlineStatus }),
        ...('syncStatus' in action && { syncStatus: action.syncStatus }),
        ...('syncDrawer' in action && { syncDrawer: action.syncDrawer }),
        ...('syncStore' in action && { syncStore: action.syncStore }),
        ...('syncStep' in action && { syncStep: action.syncStep }),
        ...('syncIterationStep' in action && { syncIterationStep: action.syncIterationStep }),
        ...('syncIterationTotal' in action && { syncIterationTotal: action.syncIterationTotal }),

        ...('productLastMod' in action && { productLastMod: action.productLastMod }),
        ...('buyerLastMod' in action && { buyerLastMod: action.buyerLastMod }),
        ...('cartLastMod' in action && { cartLastMod: action.cartLastMod }),

        ...('productDiscrepancies' in action && { productDiscrepancies: action.productDiscrepancies }),
        ...('buyerDiscrepancies' in action && { buyerDiscrepancies: action.buyerDiscrepancies }),
        ...('cartDiscrepancies' in action && { cartDiscrepancies: action.cartDiscrepancies }),
        ...('orderDiscrepancies' in action && { orderDiscrepancies: action.orderDiscrepancies }),

        ...('productSynced' in action && { productSynced: action.productSynced }),
        ...('buyerSynced' in action && { buyerSynced: action.buyerSynced }),
        ...('cartSynced' in action && { cartSynced: action.cartSynced }),
      };

    case 'SET_SYNC_RESET':
      return { ...state, ...initialState(), onlineStatus: state.onlineStatus }

    default:
      return state
  }
}