import React, { useState, useEffect, useRef } from "react";
import { IoTrashBinOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { axiosClient } from "../../apiClient";
import styles from "./summary.module.css";
import QtyChangerCart from "../QtyChangerCart";
import { addToCart, removeFromCart } from "../../actions/cart";
import { Divider, Button, List, Typography, Input } from "antd";
import { CheckOutlined } from "@ant-design/icons";

const { Text, Link } = Typography;



export default function Cart(props) {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.cart.products);
  const productsTotal = useSelector((state) => state.cart.productsTotal);
  const cartTotal = useSelector((state) => state.cart.cartTotal);
  const shippingValue = useSelector((state) => state.cart.shippingValue);
  const discountValue = useSelector((state) => state.cart.discountValue);
  const paymentMethod = useSelector((state) => state.order.paymentMethod);
  const logged = useSelector((state) => state.authentication.logged);
  const [editPrice, setEditPrice] = useState(null);
  const [newPrice, setNewPrice] = useState(null);
  const myRef = useRef();


  useEffect(() => {
    if (myRef && myRef.current) {
      const { input } = myRef.current
      input.focus()
    }
  })

  function getProductPrice(product) {
    return product.price_modified ? product.price_modified : product.product_price_sale ? product.product_price_sale : product.product_price
  }

  function dealWithSubmit(product) {
    setEditPrice(null);
    let actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price;
    newPrice == actualPrice ? dispatch({ type: "REMOVE_MODIFY_PRICE", product: product }) : dispatch({ type: "MODIFY_PRICE", product: product, price_modified: newPrice })
  }


  return (
    <>
      <Divider orientation="left">Resumo do Pedido</Divider>
      <div className={styles["summary-card"]}>
        <div className={styles["product-list"]}>
          <List
            itemLayout="vertical"
            dataSource={cartProducts}
            renderItem={(product) => (
              <List.Item>
                <div
                  key={"card" + product.product_sku}
                  className={styles["card-item"]}
                >
                  <div className={styles["img-container"]}>
                    <img
                      className={styles["product-img"]}
                      src={
                        product.product_image && product.product_image[0]
                          ? product.product_image[0].thumbnail
                          : "fallback.png"
                      }
                      alt=""
                    />
                  </div>
                  <div className={styles["product_info"]}>
                    <div className={styles["product_name"]}>
                      {product.product_name}
                    </div>
                    <div className={styles["product-calc"]}>
                      <div className={styles["product-total"]}>
                        <span className={styles["product-total-text"]}>
                          {((logged === "seller" || logged === "sellerUser") && editPrice == product.id) ?
                            <Input.Group compact>
                              <Input onBlur={(e) => { setEditPrice(null) }} size="small" prefix="R$" value={newPrice}
                                style={{ width: '60%' }}
                                onChange={(e) => {
                                  let val =
                                    parseInt(e.target.value.replace(/\D/g, "")) / 100;
                                  setNewPrice(val.toFixed(2));
                                }} ref={myRef} onKeyPress={(e) => {
                                  if (e.key === 'Enter' || e.keyCode === 13) {
                                    dealWithSubmit(product)
                                  }
                                }} />
                              <Button onMouseDown={() => dealWithSubmit(product)} size="small" type="primary"><CheckOutlined /></Button>
                            </Input.Group> :
                            (<div style={{ lineHeight: "110%" }}>
                              {"price_modified" in product ?
                                <>
                                  <Text delete>R${product.product_price_sale ? product.product_price_sale : product.product_price}</Text><br />
                                  <Link onClick={() => { setNewPrice(product.price_modified); setEditPrice(product.id) }}>{"R$" + product.price_modified + " "}</Link>
                                </>
                                : (logged === "seller" || logged === "sellerUser") ?
                                  <Link onClick={() => { let actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price; setNewPrice(actualPrice.toFixed(2)); setEditPrice(product.id) }}>
                                    R$ {parseFloat(getProductPrice(product)).toFixed(2)}{" "}</Link> :
                                  <Text> R$ {parseFloat(getProductPrice(product)).toFixed(2)}{" "}</Text>} x {product.quantity} = R$ {parseFloat(product.quantity * getProductPrice(product)).toFixed(2)}
                            </div>)}
                        </span>
                      </div>
                      <QtyChangerCart
                        func={addToCart}
                        product={product}
                        quantity={product.quantity}
                      />
                    </div>
                  </div>
                  <IoTrashBinOutline
                    onClick={() => {
                      dispatch(removeFromCart(product));
                      dispatch({ type: "CART_UPDATED", updated: true });
                      dispatch({ type: "CART_RETURNED", returned: false });
                    }}
                    className={styles["trash-icon"]}
                  />
                </div>
              </List.Item>
            )}
          />
        </div>
        <Divider />
        <div style={{ paddingLeft: "5%", paddingRight: "5%" }}>
          {productsTotal && (
            <div className={styles["order_total"]}>
              <p>Total dos Produtos</p>
              <h4>R$ {parseFloat(productsTotal).toFixed(2)}</h4>
            </div>
          )}
          {discountValue > 0 && (
            <div className={styles["order_total"]}>
              <p>Desconto</p>
              <h4>- R$ {discountValue.toFixed(2)}</h4>
            </div>
          )}
          {discountValue < 0 && (
            <div className={styles["order_total"]}>
              <p>Tarifa fixa (cartão)</p>
              <h4>R$ {(discountValue * (-1.)).toFixed(2)}</h4>
            </div>
          )}
          <div className={styles["order_total"]}>
            <p>Frete</p>
            <h4>R${parseFloat(shippingValue).toFixed(2)}</h4>
          </div>

          {paymentMethod &&
            paymentMethod.metodo === "credit_card" &&
            (parseFloat(paymentMethod.aditional_features.fix_tax) > 0) &&
            <div className={styles["order_total"]}>
              <p>Tarifa fixa (cartão)</p>
              <h4>{`R$ ${parseFloat(paymentMethod.aditional_features.fix_tax).toFixed(2)}`}</h4>
            </div>
          }

          <div className={styles["order_total"]}>
            <h2>Total</h2>
            <h2>R$ {parseFloat(cartTotal).toFixed(2)}</h2>
          </div>
        </div>
      </div>
    </>
  );
}
