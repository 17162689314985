import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Skeleton, Empty, Typography } from "antd";
// import styles from "./cadastro.module.css";
import { useDispatch } from "react-redux";
import { axiosClient } from "../../apiClient";

const { Link } = Typography;

export default function SellerListPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosClient
      .post("/payment/get", { paymentId: false }, { withCredentials: true })
      .then((response) => {
        console.log(response.data.payments)
        setData(response.data.payments);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: "Descrição",
      dataIndex: "descricao",
      render: (text) => <Link> {text} </Link>,
      ellipsis: true,
      responsive: ["sm"],
    },
    {
      title: "Métodos",
      render: (record) => (
        <>
          <Link> {record.descricao} </Link>
        </>
      ),
      responsive: ["xs"],
      ellipsis: true,
    },
  ];

  return (
    <>
      <PageHeader
        title="Métodos de Pagamento"
        onBack={() => window.history.back()}
        extra={
          <Button
            onClick={() => {
              navigate("/admin/pagamento/cadastro/metodo")
              // dispatch({ type: "PAYMENT_EDIT", paymentId: false });
              // dispatch({ type: "CREATE_PAYMENT" });
            }}
            type="primary"
          >
            Adicionar Método de Pagamento
          </Button>
        }
      />

      <Table
        columns={columns}
        dataSource={loading ? [] : data}
        locale={{
          emptyText: loading ? <Skeleton active={true} /> : <Empty />,
        }}
        style={{ cursor: !loading ? "pointer" : "auto" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate(`/admin/pagamento/editar/metodo/${record.id}`)
              // dispatch({ type: "PAYMENT_EDIT", paymentId: record.id });
              // dispatch({ type: "CREATE_PAYMENT" });
            },
          };
        }}
      />
    </>
  );
}
