import { combineReducers } from "redux";
// import { authentication } from './authentication/';
import { authentication } from "./authentication";
import { cart } from "./cart";
import { pageQty } from "./page_quantity";
import { pagination } from "./pagination";
import { buyerPagination } from "./buyerPagination";
import { searchState } from "./search";
import { buyerSearchState } from "./buyerSearch";
import { productList } from "./product_list";
import { buyerList } from "./buyer_list";
import { categoryList } from "./category_list";
import { adminPage } from "./admin_page";
import { editItems } from "./edit_items";
import { order } from "./order";
import { dropdown } from "./dropdown";
import { activeCEP } from "./shipping";
import { sellerData } from "./sellerData";
import { accessRequests } from "./access_requests";
import { message } from "./message";
import { buyerView } from "./buyerView";
import { buyers } from "./buyers";
import { sync } from "./sync";
import { indexedDB } from "./indexedDB";

const rootReducer = combineReducers({
  authentication,
  cart,
  pageQty,
  pagination,
  buyerPagination,
  searchState,
  buyerSearchState,
  productList,
  buyerList,
  categoryList,
  adminPage,
  order,
  editItems,
  dropdown,
  activeCEP,
  sellerData,
  accessRequests,
  message,
  buyerView,
  buyers,
  sync,
  indexedDB,
});

export default rootReducer;
